import { Text } from "../typography/Text";

export type CollapseHeaderProps = {
  title: string;
  subTitle?: string;
};

export const CollapseHeader: React.FC<CollapseHeaderProps> = ({
  title,
  subTitle,
}) => {
  return (
    <>
      <Text>{title}</Text>
      {subTitle && (
        <div>
          <Text type="secondary">{subTitle}</Text>
        </div>
      )}
    </>
  );
};
