import { useTranslation } from "react-i18next";
import {
  FlowBoxButton,
  Paragraph,
  FlowBox,
  FlowBoxTitle,
  Space,
} from "melco-ui";
import { Form, useFormikContext } from "formik";
import { InputField, DisplayFormErrors } from "melco-shared-logic";
import { DisplayFormStatusMessages } from "../form/DisplayFormStatusMessages";
import { LinkToServiceTerms } from "./LinkToServiceTerms";

export const DisplaySetPasswordForm: React.FC = () => {
  const { t } = useTranslation();

  const { isSubmitting } = useFormikContext();

  return (
    <>
      <DisplayFormStatusMessages />

      <DisplayFormErrors />

      <FlowBox>
        <FlowBoxTitle>{t("setpasswordpage.box_title")}</FlowBoxTitle>

        <Form>
          <InputField
            name="new_password"
            placeholder={t("global.form.label.profile.password")}
            type="password"
            autoComplete="current-password"
          />

          <InputField
            name="confirm_password"
            placeholder={t("global.form.label.profile.confirm_password")}
            type="password"
            autoComplete="confirm"
          />

          <Paragraph size="small" type="secondary">
            {t("global.hint.password_requirements")}
          </Paragraph>

          <Space direction="vertical" size="middle">
            <FlowBoxButton
              loading={isSubmitting}
              disabled={isSubmitting}
              htmlType="submit"
            >
              {t("setpasswordpage.form.submit")}
            </FlowBoxButton>

            <LinkToServiceTerms translationPrefix="setpasswordpage" />
          </Space>
        </Form>
      </FlowBox>
    </>
  );
};
