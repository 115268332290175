import { Button } from "melco-ui";
import { useTranslation } from "react-i18next";

type TrialActiveModalCancelButtonProps = {
  onClick: () => void;
};

const TrialActiveModalCancelButton: React.FC<
  TrialActiveModalCancelButtonProps
> = ({ onClick }) => {
  const { t } = useTranslation();

  return <Button onClick={onClick}>{t("trial.active.cancel")}</Button>;
};

export default TrialActiveModalCancelButton;
