import dayjs from "dayjs";
import { redirectToAccount } from "../redirect/redirectHelper";

export const isTrialExpired = ({
  expires_on,
}: {
  expires_on?: Date | null;
}) => {
  if (!expires_on) {
    return false;
  }

  return dayjs(expires_on).isBefore(Date.now());
};

export const isTrialProduct = ({
  expires_on,
}: {
  expires_on?: Date | null;
}) => {
  return !!expires_on;
};

export const isTrialError = (apiErrors: any[] | undefined) => {
  if (!apiErrors) {
    return false;
  }

  const expiredError = apiErrors.find(
    ({ key, source }) => key === "expired" && source === "trial"
  );

  return !!expiredError;
};

export const redirectAfterTrialError = () => {
  redirectToAccount(`dashboard/trial/${process.env.REACT_APP_PRODUCT_CODE}`);
};
