import { Button, DeleteIcon, MailIcon, Menu, MoreIcon } from "melco-ui";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import { MelcoCoreModelsEfsFormat, useConfirm } from "melco-shared-logic";
import { useDeleteUsers } from "../../hooks/administration/useDeleteUsers";
import { useResendInvite } from "../../hooks/administration/useResendInvite";

type UserMoreActionButtonProps = {
  user: MelcoCoreModelsEfsFormat;
};

export const UserMoreActionButton: React.FC<UserMoreActionButtonProps> = ({
  user,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteUsers = useDeleteUsers();

  const confirmDelete = useConfirm(
    async () => {
      if (user.id) {
        const successfullyDeletedIds = await deleteUsers([user.id]);

        // redirect to list view in case the entity was deleted successfully
        if (!isEmpty(successfullyDeletedIds)) {
          navigate(`/administration/users`);
        }
      }
    },
    {
      translationPrefix: "users.actions.delete",
      translationOptions: {
        count: 1,
      },
    }
  );

  const [isResendAvailable, resendInvite] = useResendInvite();

  const MoreActions = (
    <Menu>
      {isResendAvailable(user) && (
        <Menu.Item
          key="more-actions-invite"
          onClick={() => resendInvite(user.id!)}
          icon={<MailIcon />}
        >
          {t("users.actions.invite.button")}
        </Menu.Item>
      )}
      <Menu.Item
        key="more-actions-delete"
        danger
        onClick={confirmDelete}
        icon={<DeleteIcon />}
      >
        {t("users.actions.delete.button")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={MoreActions}
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      <Button
        key="user-more-actions"
        icon={<MoreIcon />}
        data-cy-id="user-more-actions"
      />
    </Dropdown>
  );
};
