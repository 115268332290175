import { useState } from "react";
import { MainContentArea, ContentHeader } from "melco-ui";
import { useTranslation } from "react-i18next";
import { APIKeysList } from "../../components/settings/APIKeysList";
import { useBreadcrumb } from "../../hooks/navigation/useBreadcrumb";
import { CreateAPIKeyButton } from "../../components/settings/CreateAPIKeyButton";
import { CreateAPIKeyDrawer } from "../../components/settings/CreateAPIKeyDrawer";

export const APIKeys: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("settings");

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("settings.api.title")}
          breadcrumb={breadcrumb}
          extra={<CreateAPIKeyButton setDrawerOpen={setDrawerOpen} />}
        />
      }
    >
      <APIKeysList />

      <CreateAPIKeyDrawer
        isDrawerOpen={isDrawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </MainContentArea>
  );
};
