import React from "react";
import styled from "styled-components";
import AntdTypography from "antd/es/typography";
import { TitleProps as AntdTitleProps } from "antd/es/typography/Title";
import { DesignTokens } from "../../styles/design-tokens";

const { fonts } = DesignTokens;

const StyledTitle = styled(AntdTypography.Title)<TitleProps>`
  font-family: ${fonts.sansSerifMd};
`;

export type TitleProps = AntdTitleProps;

const Title: React.FC<TitleProps> = (props) => <StyledTitle {...props} />;

export { Title };
