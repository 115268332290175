import { useTranslation } from "react-i18next";
import { Button, FormRow, FormSection, SaveIcon } from "melco-ui";
import { FormikProps } from "formik";
import {
  InputField,
  VerticalForm,
  DisplayFormErrors,
  useIsFormChanged,
} from "melco-shared-logic";
import { ChangeEMailFormValueType } from "../register/ChangeEMailForm";
import { EmailFieldWithAvailabilityCheck } from "../register/EmailFieldWithAvailabilityCheck";

export const DisplayChangeEMailForm: React.FC<
  FormikProps<ChangeEMailFormValueType>
> = ({ isSubmitting }) => {
  const { t } = useTranslation();
  const isFormChanged = useIsFormChanged();

  return (
    <VerticalForm>
      <FormSection title={t("accountloginpage.tab_1.name")}>
        <DisplayFormErrors />

        <InputField
          name="user_name"
          label={t("global.form.label.profile.email_current")}
          disabled
          size="large"
        />

        <EmailFieldWithAvailabilityCheck
          name="new_user_name"
          label={t("global.form.label.profile.email_new")}
          size="large"
        />

        <InputField
          name="current_password"
          label={t("global.form.label.profile.current_password")}
          type="password"
          autoComplete="current-password"
          size="large"
        />

        <FormRow justify="end">
          <Button
            type="primary"
            htmlType="submit"
            icon={<SaveIcon />}
            loading={isSubmitting}
            disabled={isSubmitting || !isFormChanged}
          >
            {t("accountloginpage.tab_1.submit")}
          </Button>
        </FormRow>
      </FormSection>
    </VerticalForm>
  );
};
