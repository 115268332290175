import { useLocation } from "react-router";

// ?type=invite&code=047628&username=bibahat402@mkurg.com
export const useRegistrationInvite = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const type = searchParams.get("type");
  const code = searchParams.get("code");
  const username = searchParams.get("username");

  if (type === "invite" && code && username) {
    return {
      code,
      username,
    };
  }
};
