import { ReactComponent as PipetteSvg } from "./icons/default/pipette.svg";
import { ReactComponent as DragHandleSvg } from "./icons/default/drag-handle.svg";
import { ReactComponent as CustomizableSvg } from "./icons/default/customizable.svg";
import { ReactComponent as CustomizableNotSvg } from "./icons/default/customizable-not.svg";
import { ReactComponent as ForwardSvg } from "./icons/default/forward.svg";

// TODO: Check need to refactor custom icons in separate Files?

import Icon, {
  AppstoreAddOutlined,
  AppstoreOutlined,
  ArrowsAltOutlined,
  BarChartOutlined,
  BellOutlined,
  CheckCircleFilled,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleFilled,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DisconnectOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FilterOutlined,
  FolderAddOutlined,
  FolderOutlined,
  FormatPainterOutlined,
  GoldOutlined,
  HddOutlined,
  InfoCircleOutlined,
  KeyOutlined,
  LeftOutlined,
  LineChartOutlined,
  LinkOutlined,
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  MinusCircleOutlined,
  MoreOutlined,
  PartitionOutlined,
  PhoneOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PoweroffOutlined,
  ReloadOutlined,
  RetweetOutlined,
  RightOutlined,
  SaveOutlined,
  SettingOutlined,
  ShareAltOutlined,
  ShrinkOutlined,
  StopOutlined,
  ToolOutlined,
  UndoOutlined,
  UnlockOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";

const PipetteIcon = (props: React.ComponentProps<typeof Icon>) => (
  <Icon component={PipetteSvg} {...props} />
);

const DragHandleIcon = (props: React.ComponentProps<typeof Icon>) => (
  <Icon component={DragHandleSvg} {...props} />
);
const CustomizableIcon = (props: React.ComponentProps<typeof Icon>) => (
  <Icon component={CustomizableSvg} {...props} />
);

const CustomizableNotIcon = (props: React.ComponentProps<typeof Icon>) => (
  <Icon component={CustomizableNotSvg} {...props} />
);

const ForwardIcon = (props: React.ComponentProps<typeof Icon>) => (
  <Icon component={ForwardSvg} {...props} />
);

export {
  AppstoreAddOutlined as AddIcon,
  AppstoreOutlined as AppIcon,
  ArrowsAltOutlined as ArrowsAltIcon,
  BarChartOutlined as BarChartIcon,
  BellOutlined as NotificationIcon,
  CheckCircleFilled as SuccessIcon,
  CheckOutlined as CheckIcon,
  ClockCircleOutlined as ClockIcon,
  CloseCircleFilled as ErrorIcon,
  CloseOutlined as CloseIcon,
  CopyOutlined as DuplicateIcon,
  CustomizableIcon,
  CustomizableNotIcon,
  DeleteOutlined as DeleteIcon,
  DisconnectOutlined as UnlinkIcon,
  DownloadOutlined as DownloadIcon,
  DragHandleIcon,
  EditOutlined as EditIcon,
  ExclamationCircleOutlined as ExclamationCircleIcon,
  EyeOutlined as EyeIcon,
  FileOutlined as FileIcon,
  FilePdfOutlined as PDFIcon,
  FileSearchOutlined as FileSearchIcon,
  FileTextOutlined as TextIcon,
  FilterOutlined as FilterIcon,
  FolderAddOutlined as FolderAddIcon,
  FolderOutlined as FolderIcon,
  FormatPainterOutlined as FormatIcon,
  ForwardIcon,
  GoldOutlined as InfrastructureIcon,
  HddOutlined as DiskIcon,
  InfoCircleOutlined as InfoIcon,
  KeyOutlined as KeyIcon,
  LeftOutlined as LeftIcon,
  LineChartOutlined as ChartIcon,
  LinkOutlined as LinkIcon,
  LoadingOutlined as LoadingIcon,
  LockOutlined as LockIcon,
  MailOutlined as MailIcon,
  MenuFoldOutlined as MenuFoldIcon,
  MenuOutlined as MenuIcon,
  MenuUnfoldOutlined as MenuUnfoldIcon,
  MinusCircleOutlined as RemoveIcon,
  MoreOutlined as MoreIcon,
  PartitionOutlined as DataMappingIcon,
  PhoneOutlined as PhoneIcon,
  PipetteIcon,
  PlayCircleOutlined as PlayIcon,
  PlusCircleOutlined as PlusCircleIcon,
  PlusOutlined as PlusIcon,
  PoweroffOutlined as LogoutIcon,
  ReloadOutlined as ReloadIcon,
  RetweetOutlined as TransferIcon,
  RightOutlined as RightIcon,
  SaveOutlined as SaveIcon,
  SettingOutlined as SettingIcon,
  ShareAltOutlined as ShareIcon,
  ShrinkOutlined as ShrinkIcon,
  StopOutlined as StopIcon,
  ToolOutlined as ToolIcon,
  UndoOutlined as UndoIcon,
  UnlockOutlined as UnlockIcon,
  UploadOutlined as UploadIcon,
  UserOutlined as UserIcon,
};
