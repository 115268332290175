import { Button } from "melco-ui";
import { useTranslation } from "react-i18next";

type TrialModalInfoButtonProps = {
  info_url: string;
};

const TrialModalInfoButton: React.FC<TrialModalInfoButtonProps> = ({
  info_url,
}) => {
  const { t } = useTranslation();
  return (
    <Button href={info_url} target="_blank">
      {t("trial.info.label")}
    </Button>
  );
};

export default TrialModalInfoButton;
