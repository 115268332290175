import { useCurrentUser } from "../user/useCurrentUser";

export const useRequirePermissions = (permissionCodes: string[]) => {
  const user = useCurrentUser();

  if (!user || !user.permissions) {
    return false;
  }

  const { permissions } = user;

  const userPermissionCodes = permissions.map(({ code }) => code ?? "");

  for (const permissionCode of permissionCodes) {
    if (!userPermissionCodes.includes(permissionCode)) {
      return false;
    }
  }

  return true;
};
