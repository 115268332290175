import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "melco-ui";
import {
  VerificationState,
  ResendCodeProcess,
  useResendCode,
} from "melco-shared-logic";

type ResendVerifyCodeButtonProps = {
  process: ResendCodeProcess;
  verificationState: VerificationState;
  setStatus: (status?: any) => void;
};

const ResendVerifyCodeButton: React.FC<ResendVerifyCodeButtonProps> = ({
  process,
  verificationState,
  setStatus,
}) => {
  const { t } = useTranslation();
  const resendVerifyCode = useResendCode();
  const [isResendingCode, setIsResendingCode] = useState(false);
  const { eMail } = verificationState;

  const translationPrefix =
    process === "forgotPassword"
      ? "forgotpasswordverifypage"
      : "registrationverifypage";

  if (!eMail) {
    return null;
  }

  const resendCode = async () => {
    setStatus(undefined);
    setIsResendingCode(true);

    try {
      await resendVerifyCode(process, eMail);
      setStatus({
        messages: [
          {
            type: "success",
            message: `${translationPrefix}.success.resent`,
          },
        ],
      });
    } catch (error) {
      console.error(error);
      setStatus({
        type: "error",
        message: `${translationPrefix}.error.default`,
      });
    } finally {
      setIsResendingCode(false);
    }
  };

  return (
    <Button
      hasHeroHeight
      disabled={isResendingCode}
      loading={isResendingCode}
      onClick={resendCode}
      block
    >
      {t(`${translationPrefix}.secondary.label_resend`)}
    </Button>
  );
};

export default ResendVerifyCodeButton;

// FIXME: Check all usages of status and whether there would be a clash
