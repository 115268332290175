import { useTranslation } from "react-i18next";
import {
  Button,
  FlowBoxButton,
  FlowBox,
  FlowBoxButtonRow,
  FullWidthSpace,
  Paragraph,
} from "melco-ui";
import { Form, FormikProps } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { DisplayFormErrors } from "melco-shared-logic";
import { VerifyCodeFormProps, VerifyCodeFormValueType } from "./VerifyCodeForm";
import CodeInput from "../input/CodeInput";
import ResendVerifyCodeButton from "./ResendVerifyCodeButton";
import { DisplayFormStatusMessages } from "../form/DisplayFormStatusMessages";

type DisplayVerifyCodeFormProps = {
  children?: React.ReactNode;
} & FormikProps<VerifyCodeFormValueType> &
  VerifyCodeFormProps;

export const DisplayVerifyCodeForm: React.FC<DisplayVerifyCodeFormProps> = ({
  isSubmitting,
  children,
  verificationState,
  setStatus,
  process,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const translationPrefix =
    process === "forgotPassword"
      ? "forgotpasswordverifypage"
      : "registrationverifypage";

  const searchParams = new URLSearchParams(location.search);

  const isChangingEMailAllowed = searchParams.get("type") !== "subuser";

  const buttons = [];

  if (process === "forgotPassword") {
    buttons.push(
      <Button hasHeroHeight href="/" block>
        {t("forgotpasswordverifypage.secondary.label_cancel")}
      </Button>
    );
  } else if (isChangingEMailAllowed) {
    buttons.push(
      <Button
        hasHeroHeight
        block
        onClick={() => {
          navigate("/register/changeemail");
        }}
      >
        {t("registrationverifypage.secondary.label_email")}
      </Button>
    );
  }

  buttons.push(
    <ResendVerifyCodeButton
      process={process}
      verificationState={verificationState}
      setStatus={setStatus}
    />
  );

  return (
    <>
      <DisplayFormStatusMessages />

      <DisplayFormErrors />

      <FullWidthSpace direction="vertical">
        <FlowBox>
          {children}

          <Form>
            <CodeInput fieldName="code" />

            <FlowBoxButton
              loading={isSubmitting}
              disabled={isSubmitting}
              htmlType="submit"
            >
              {t(`${translationPrefix}.form.submit`)}
            </FlowBoxButton>
          </Form>
        </FlowBox>

        <Paragraph size="small" alignment="center" type="secondary">
          {t(`${translationPrefix}.secondary.text`)}
        </Paragraph>
      </FullWidthSpace>

      <FlowBoxButtonRow>{buttons}</FlowBoxButtonRow>
    </>
  );
};
