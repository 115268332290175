import { SecondaryNavigationMenuDropdown } from "melco-ui";
import { useNavigate } from "react-router-dom";
import { useLogout } from "melco-shared-logic";
import LogoutMenuItem from "./LogoutMenuItem";

type SecondaryNavigationMenuProps = {
  setDropDownVisible?: (isVisible: boolean) => void;
};

const SecondaryNavigationMenu: React.FC<SecondaryNavigationMenuProps> = ({
  setDropDownVisible,
  ...rest
}) => {
  const navigate = useNavigate();
  const [logout, isLoggingOut] = useLogout();

  return (
    <SecondaryNavigationMenuDropdown
      {...rest}
      onClick={async (menuInfo) => {
        if (menuInfo.key === "logout") {
          try {
            await logout();
            navigate("/?logout-success");
          } catch (error) {
            setDropDownVisible && setDropDownVisible(false);
          }
        }
      }}
    >
      <LogoutMenuItem key="logout" isLoggingOut={isLoggingOut} />
    </SecondaryNavigationMenuDropdown>
  );
};

export default SecondaryNavigationMenu;
