import { MelcoCoreModelsUsersServiceUser } from "melco-shared-logic";
import { Button, FullWidthSpace, CopyableTextWithLabel } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useResetPassword } from "../../hooks/administration/useResetPassword";
import { PasswordResetConfirmationModal } from "./PasswordResetConfirmationModal";

type UserLoginProps = {
  user: MelcoCoreModelsUsersServiceUser;
};

export const UserLoginUsername: React.FC<UserLoginProps> = ({ user }) => {
  const { t } = useTranslation();

  const { resetPassword, isLoading, passwordResetData, onOk } =
    useResetPassword(user);

  const { user_name } = user;

  return (
    <FullWidthSpace direction="vertical" size="large">
      <CopyableTextWithLabel
        label={t("users.details.login.username.labels.username")}
        text={user_name ?? ""}
        onCopiedMessage={t("users.details.login.username.copyUsername.success")}
        copyTooltip={t(
          "users.details.login.username.copyUsername.tooltip_copy"
        )}
        copiedTooltip={t(
          "users.details.login.username.copyUsername.tooltip_copied"
        )}
      />

      <Button onClick={resetPassword} loading={isLoading}>
        {t("users.details.login.username.resetPassword.button")}
      </Button>

      <PasswordResetConfirmationModal
        passwordResetData={passwordResetData}
        onConfirm={onOk}
      />
    </FullWidthSpace>
  );
};
