import { useTranslation } from "react-i18next";
import { FormSection, FormRow, DesignTokens, Col, Row } from "melco-ui";
import {
  InputField,
  VerticalForm,
  DisplayFormErrors,
  SaveFormButton,
  DebugForm,
} from "melco-shared-logic";
import CountrySelect from "../form/CountrySelect";
import GenderSelect from "../form/GenderSelect";
import StateSelect from "../form/StateSelect";

const { spacings } = DesignTokens;

export const DisplayUserProfileForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <VerticalForm>
      <DisplayFormErrors />

      <FormSection title={t("global.form.label.profile.personal_information")}>
        <GenderSelect />

        <Row gutter={[spacings.middle, 0]}>
          <Col xs={24} md={12}>
            <InputField
              name="first_name"
              label={t("global.form.label.profile.first_name")}
            />
          </Col>
          <Col xs={24} md={12}>
            <InputField
              name="last_name"
              label={t("global.form.label.profile.last_name")}
            />
          </Col>
        </Row>

        <InputField
          name="company_name"
          label={t("global.form.label.profile.company")}
        />

        <Row gutter={[spacings.middle, 0]}>
          <Col xs={24} md={12}>
            <InputField
              name="phone"
              label={t("global.form.label.profile.phone")}
            />
          </Col>
          <Col xs={24} md={12}>
            <InputField
              name="website"
              label={t("global.form.label.profile.website")}
            />
          </Col>
        </Row>
      </FormSection>

      <FormSection title={t("global.form.label.profile.address_information")}>
        <InputField
          name="street1"
          label={t("global.form.label.profile.street")}
        />

        <Row gutter={[spacings.middle, 0]}>
          <Col xs={24} md={12}>
            <InputField
              name="postal_code"
              label={t("global.form.label.profile.postal_code")}
            />
          </Col>
          <Col xs={24} md={12}>
            <InputField
              name="city"
              label={t("global.form.label.profile.city")}
            />
          </Col>
        </Row>

        <Row gutter={[spacings.middle, 0]}>
          <Col xs={24} md={12}>
            <CountrySelect
              name="country_code"
              label={t("global.form.label.profile.country_code")}
              disabled
            />
          </Col>
          <Col xs={24} md={12}>
            <StateSelect label={t("global.form.label.profile.state_code")} />
          </Col>
        </Row>
      </FormSection>

      <FormRow justify="end">
        <SaveFormButton />
      </FormRow>

      {false && <DebugForm />}
    </VerticalForm>
  );
};
