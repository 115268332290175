import { useCallback } from "react";
import { UserManagementApi } from "../../api/apis/UserManagementApi";
import { useBaseAPIConfig } from "../api/useAPIConfig";

export const useTokenValidityCheck = () => {
  const baseApiConfig = useBaseAPIConfig();
  return useCallback(
    async (token: string, userId: string) => {
      const api = new UserManagementApi(
        baseApiConfig({ token, skipRedirectToLoginIfUnauthorized: true })
      );

      return await api.userGetUser({
        id: userId,
      });
    },
    [baseApiConfig]
  );
};
