import { useCallback, useState } from "react";
import {
  MelcoCoreModelsUsersServiceUser,
  useAPIAction,
  UserAccountApi,
} from "melco-shared-logic";

export type PasswordResetData = {
  user_name: string;
  new_password: string;
};

export const useResetPassword = (user: MelcoCoreModelsUsersServiceUser) => {
  const [passwordResetData, setPasswordResetData] = useState<
    PasswordResetData | undefined
  >(undefined);
  const { id } = user;

  const [resetPassword, isLoading] = useAPIAction(
    UserAccountApi,
    async (api) => {
      if (!id) {
        throw new Error("User id missing");
      }

      const data = await api.userAccountRegeneratePassword({ id });

      const { user_name, new_password } = data;

      setPasswordResetData({
        user_name: user_name ?? "",
        new_password: new_password ?? "",
      });

      return {
        successMessages: [],
        data,
      };
    },
    {
      translationPrefix: "users.details.login.username.resetPassword",
    }
  );

  const onOk = useCallback(() => {
    setPasswordResetData(undefined);
  }, [setPasswordResetData]);

  return { resetPassword, isLoading, passwordResetData, onOk };
};
