import { Formik } from "formik";
import {
  SupportApi,
  useAuthenticatedAPIConfig,
  useCurrentUser,
  useFormSubmit,
} from "melco-shared-logic";
import { message } from "melco-ui";
import { useTranslation } from "react-i18next";
import { object, number } from "yup";
import { useSupportStatus } from "../../hooks/support/useSupportStatus";
import { DisplayActivateSupportForm } from "./DisplayActivateSupportForm";

export type ActivateSupportFormValueType = {
  minutes: number;
};

const ActivateSupportFormSchema = object().shape({
  minutes: number().required("global.error.required.default"),
});

type ActivateSupportFormProps = {
  refetch: ReturnType<typeof useSupportStatus>["refetch"];
};

export const ActivateSupportForm: React.FC<ActivateSupportFormProps> = ({
  refetch,
}) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const onSubmit = useFormSubmit<ActivateSupportFormValueType>(
    async (values: ActivateSupportFormValueType) => {
      const { minutes } = values;

      const api = new SupportApi(authenticatedApiConfig());

      const result = await api.supportActivate({
        melcoCoreModelsActivateSupport: {
          minutes,
        },
      });

      if (result.is_active) {
        message.success(t("accountsupportpage.access.success.activated"));
        await refetch();
      } else {
        throw new Error();
      }
    },
    { translationPrefix: "accountsupportpage" }
  );

  if (!currentUser) {
    return null;
  }

  const initialValues: ActivateSupportFormValueType = {
    minutes: 24 * 60,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ActivateSupportFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => <DisplayActivateSupportForm {...props} />}
    </Formik>
  );
};
