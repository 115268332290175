import { useDefaultsAPI } from "../api/useDefaultsAPI";
import { SystemDefaultsApi } from "../../api";

export type Culture = {
  id: string;
  code: string;
  short_code: string;
  name: string;
};

type CulturesData = {
  cultures: Culture[];
};

const initialValue = { cultures: [] };

const handleRequest = async (api: SystemDefaultsApi) => {
  const response = await api.defaultsSearchCultures({});

  return {
    cultures: response.list,
  };
};

export const useCultures = () => {
  return useDefaultsAPI<CulturesData>({
    initialValue,
    handleRequest,
  });
};
