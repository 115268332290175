import {
  MelcoCoreModelsUserSubscriptionListItem,
  useAPIQuery,
  UserAccountApi,
  useTable,
} from "melco-shared-logic";
import isEmpty from "lodash-es/isEmpty";
import { useTableErrorComponent } from "../table/useTableErrorComponent";

export const useLicenses = () => {
  const {
    onTableConfigChange,
    pagination,
    setTotal,
    filters,
    sorter,
    currentPage,
    pageSize,
    sortBy,
    searchQuery,
    searchQueryChange,
  } = useTable<MelcoCoreModelsUserSubscriptionListItem>();

  const query = useAPIQuery(
    UserAccountApi,
    ["licenses", currentPage, pageSize, filters, sortBy(), searchQuery],
    async (api) => {
      const response = await api.accountGetSubscriptions({
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        sortBy: sortBy(),
        searchText: !isEmpty(searchQuery) ? searchQuery : undefined,
        typeList: "1", // 1=Activated
      });

      setTotal(response.total);

      return response.list;
    },
    {
      queryOptions: {
        keepPreviousData: true,
      },
    }
  );

  const errorComponent = useTableErrorComponent(query.error, query.refetch);

  return {
    onTableConfigChange,
    pagination,
    setTotal,
    filters,
    sorter,
    searchQuery,
    searchQueryChange,
    errorComponent,
    ...query,
  };
};
