import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import {
  Col,
  ContentHeader,
  DesignTokens,
  FullWidthSpace,
  MainContentArea,
  Row,
  Space,
  Title,
  ValueOrFallback,
  LabeledItem,
} from "melco-ui";
import { FormattedDate, HeaderButtonArea } from "melco-shared-logic";
import { useBreadcrumb } from "../../hooks/navigation/useBreadcrumb";
import { useLicense } from "../../hooks/infrastructure/useLicense";
import { LicenseMoreActionButton } from "../../components/infrastructure/LicenseMoreActionButton";
import { useGenericDrawer } from "melco-shared-logic";
import { LicenseActivationReminderDrawer } from "../../components/infrastructure/LicenseActivationReminderDrawer";
import { LicenseDetailForm } from "../../components/infrastructure/LicenseDetailForm";
import { LicensesHistoryList } from "../../components/infrastructure/LicensesHistoryList";
import { AssignUser } from "../../components/infrastructure/AssignUser";
import { AssignUserDrawer } from "../../components/infrastructure/AssignUserDrawer";

const { spacings } = DesignTokens;

type LicenseDetailsProps = {
  licenseId: string;
};

export const LicenseDetails: React.FC<LicenseDetailsProps> = ({
  licenseId,
}) => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("infrastructure", "infrastructureLicenses");
  const activationReminderDrawer = useGenericDrawer();
  const assignUserDrawer = useGenericDrawer();

  const { data: license } = useLicense(licenseId);

  const {
    display_name,
    product_name,
    product_version_number,
    product_level_name,
    serial_number,
    activated_on,
    updated_date,
  } = license || {};

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={
            <ValueOrFallback
              value={display_name}
              fallback={t("global.fallback.empty")}
            />
          }
          breadcrumb={breadcrumb}
          extra={
            <>
              <HeaderButtonArea />
              {license && (
                <LicenseMoreActionButton
                  activationReminderDrawer={activationReminderDrawer}
                />
              )}
            </>
          }
        />
      }
      contentPadding
    >
      <FullWidthSpace direction="vertical" size="large">
        <div>{license && <LicenseDetailForm license={license} />}</div>

        <div>
          <Title level={4}>
            {t("infrastructureLicenses.form.title.licenseDetails")}
          </Title>

          <Row gutter={[spacings.middle, 0]}>
            <Col xs={24} md={8}>
              <Space direction="vertical" size="middle">
                <LabeledItem
                  label={t("infrastructureLicenses.form.label.product_name")}
                  content={product_name ?? ""}
                  fallback={t("global.fallback.none")}
                />

                <LabeledItem
                  label={t(
                    "infrastructureLicenses.form.label.product_version_number"
                  )}
                  content={product_version_number ?? ""}
                  fallback={t("global.fallback.none")}
                />

                <LabeledItem
                  label={t(
                    "infrastructureLicenses.form.label.product_level_name"
                  )}
                  content={product_level_name ?? ""}
                  fallback={t("global.fallback.none")}
                />
              </Space>
            </Col>
            <Col xs={24} md={8}>
              <Space direction="vertical" size="middle">
                <LabeledItem
                  label={t("infrastructureLicenses.form.label.serial_number")}
                  content={serial_number ?? ""}
                  fallback={t("global.fallback.none")}
                />

                <LabeledItem
                  label={t("infrastructureLicenses.form.label.activated_on")}
                  content={<FormattedDate date={activated_on} />}
                  fallback={t("global.fallback.none")}
                />

                <LabeledItem
                  label={t("infrastructureLicenses.form.label.updated_date")}
                  content={<FormattedDate date={updated_date} />}
                  fallback={t("global.fallback.none")}
                />
              </Space>
            </Col>
            <Col xs={24} md={8}>
              <Space direction="vertical" size="middle">
                {license && (
                  <AssignUser license={license} drawer={assignUserDrawer} />
                )}
              </Space>
            </Col>
          </Row>
        </div>

        <div>
          <Title level={4}>
            {t("infrastructureLicenses.form.title.history")}
          </Title>

          <LicensesHistoryList licenseId={licenseId} />
        </div>
      </FullWidthSpace>

      {license && (
        <LicenseActivationReminderDrawer
          license={license}
          drawer={activationReminderDrawer}
        />
      )}

      {license && (
        <AssignUserDrawer license={license} drawer={assignUserDrawer} />
      )}
    </MainContentArea>
  );
};
