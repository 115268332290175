import { SearchInput, SearchInputProps } from "melco-ui";
import { useTranslation } from "react-i18next";

type SearchProps = Pick<SearchInputProps, "value" | "onChange"> & {
  onSearchInputChange: (query: string) => void;
};

export const Search: React.FC<SearchProps> = ({
  onSearchInputChange,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ maxWidth: 300 }}>
      <SearchInput
        onSearchInputChange={(searchQuery: string) => {
          onSearchInputChange(searchQuery);
        }}
        placeholder={
          t("search.placeholder", {
            ns: "melco-common",
          }) ?? undefined
        }
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
