import React from "react";
import styled from "styled-components";

import { FormSectionHeadline } from "./FormSectionHeadline";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

const StyledFormSection = styled.div`
  margin-bottom: ${spacings.xlarge}px;
`;

type FormSectionProps = {
  title?: string;
  children?: React.ReactNode;
};

export const FormSection: React.FC<FormSectionProps> = ({
  title,
  children,
}) => (
  <StyledFormSection>
    {title && <FormSectionHeadline>{title}</FormSectionHeadline>}
    {children}
  </StyledFormSection>
);
