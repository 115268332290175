import styled from "styled-components";
import AntdMenu from "antd/es/menu";

const Menu = styled(AntdMenu)`
  &:not(.ant-menu-inline-collapsed) {
    border-right: 0;
  }
`;

export { Menu };
