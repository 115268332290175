import { useFormikContext } from "formik";
import { Alert } from "melco-ui";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash-es/isEmpty";

export const DisplayFormStatusMessages: React.FC = () => {
  const { status } = useFormikContext();
  const { t } = useTranslation();

  if (!status || isEmpty(status.messages)) {
    return null;
  }

  return (
    <>
      {status.messages.map(
        (message: { type: "success"; message: string }, idx: number) => (
          <Alert
            key={idx}
            type={message.type}
            message={t(message.message as any)}
          />
        )
      )}
    </>
  );
};
