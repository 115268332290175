import { useTranslation } from "react-i18next";
import { MelcoCoreModelsProductListItem } from "melco-shared-logic";
import { PageTitle } from "melco-ui";
import AppIcons from "./AppIcons";

type YourAppsProps = {
  products: MelcoCoreModelsProductListItem[];
};

const YourApps: React.FC<YourAppsProps> = ({ products }) => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t("dashboard.apps.title")}</PageTitle>
      <AppIcons products={products} />
    </>
  );
};

export default YourApps;
