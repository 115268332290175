import { useTranslation } from "react-i18next";
import {
  Button,
  SaveIcon,
  FormSection,
  FormRow,
  DesignTokens,
  Col,
  Row,
} from "melco-ui";
import { FormikProps } from "formik";
import { ProfileFormValueType } from "./ProfileForm";
import CountrySelect from "../form/CountrySelect";
import GenderSelect from "../form/GenderSelect";
import StateSelect from "../form/StateSelect";
import {
  InputField,
  VerticalForm,
  DisplayFormErrors,
  useIsFormChanged,
} from "melco-shared-logic";
import { useIsAllowedToChangeAccountName } from "../../hooks/user/useIsAllowedToChangeAccountName";

const { spacings } = DesignTokens;

export const DisplayProfileForm: React.FC<
  FormikProps<ProfileFormValueType>
> = ({ isSubmitting }) => {
  const { t } = useTranslation();
  const isFormChanged = useIsFormChanged();
  const isAllowedToChangeAccountName = useIsAllowedToChangeAccountName();

  return (
    <VerticalForm>
      <DisplayFormErrors />

      <FormSection title={t("global.form.label.profile.account")}>
        <InputField
          name="account_name"
          label={t("global.form.label.profile.account_name")}
          disabled={!isAllowedToChangeAccountName}
        />
      </FormSection>

      <FormSection title={t("global.form.label.profile.personal_information")}>
        <GenderSelect />

        <InputField
          name="first_name"
          label={t("global.form.label.profile.first_name")}
        />

        <InputField
          name="last_name"
          label={t("global.form.label.profile.last_name")}
        />

        <InputField
          name="company_name"
          label={t("global.form.label.profile.company")}
        />

        <Row gutter={[spacings.middle, 0]}>
          <Col xs={24} md={12}>
            <InputField
              name="phone"
              label={t("global.form.label.profile.phone")}
            />
          </Col>
          <Col xs={24} md={12}>
            <InputField
              name="website"
              label={t("global.form.label.profile.website")}
            />
          </Col>
        </Row>
      </FormSection>

      <FormSection title={t("global.form.label.profile.address_information")}>
        <InputField
          name="street1"
          label={t("global.form.label.profile.street")}
        />

        <InputField
          name="postal_code"
          label={t("global.form.label.profile.postal_code")}
        />

        <InputField name="city" label={t("global.form.label.profile.city")} />

        <CountrySelect
          name="country_code"
          label={t("global.form.label.profile.country_code")}
          disabled
        />

        <StateSelect label={t("global.form.label.profile.state_code")} />
      </FormSection>

      <FormRow justify="end">
        <Button
          type="primary"
          htmlType="submit"
          icon={<SaveIcon />}
          loading={isSubmitting}
          disabled={isSubmitting || !isFormChanged}
        >
          {t("accountprofilepage.submit")}
        </Button>
      </FormRow>
    </VerticalForm>
  );
};
