/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCoreModelsCustomWarning,
    MelcoCoreModelsCustomWarningFromJSON,
    MelcoCoreModelsCustomWarningFromJSONTyped,
    MelcoCoreModelsCustomWarningToJSON,
    MelcoCoreModelsUsersEfsUserList,
    MelcoCoreModelsUsersEfsUserListFromJSON,
    MelcoCoreModelsUsersEfsUserListFromJSONTyped,
    MelcoCoreModelsUsersEfsUserListToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserList
 */
export interface MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserList {
    /**
     * 
     * @type {Array<MelcoCoreModelsUsersEfsUserList>}
     * @memberof MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserList
     */
    list: Array<MelcoCoreModelsUsersEfsUserList>;
    /**
     * 
     * @type {Array<MelcoCoreModelsCustomWarning>}
     * @memberof MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserList
     */
    warning_list?: Array<MelcoCoreModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserList
     */
    total: number;
}

export function MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserListFromJSON(json: any): MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserList {
    return MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserListFromJSONTyped(json, false);
}

export function MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserListFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(MelcoCoreModelsUsersEfsUserListFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoCoreModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserListToJSON(value?: MelcoCoreModelsGenericList1MelcoCoreModelsUsersEfsUserList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(MelcoCoreModelsUsersEfsUserListToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoCoreModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


