import { createRef, RefObject, useRef } from "react";
import styled from "styled-components";
import { DesignTokens } from "melco-ui";
import { DisplayFieldErrors } from "melco-shared-logic";
import times from "lodash-es/times";
import { useField } from "formik";
import { InputRef } from "antd";
import SingleDigitInput from "./SingleDigitInput";

const { spacings } = DesignTokens;

const StyledCodeVerifier = styled.div`
  margin: ${spacings.large}px 0 ${spacings.middle}px 0;
  display: flex;
  justify-content: space-between;

  ::-webkit-input-placeholder {
    padding-top: 13px;
  }
`;

type CodeInputProps = {
  fieldName: string;
};

const CodeInput: React.FC<CodeInputProps> = ({ fieldName }) => {
  const [field, , helpers] = useField(fieldName);
  const length = field.value.length;

  const fieldRefs = useRef<RefObject<InputRef>[]>([]);

  const replaceValueAtIndex = (index: number, value: string | undefined) => {
    const oldValues = field.value.split("");

    if (!value) {
      oldValues[index] = " ";
    } else {
      const trimmedCode = value.trim();
      if (trimmedCode.length === 1) {
        oldValues[index] = trimmedCode;
      }
    }

    helpers.setValue(oldValues.join(""));
  };

  const handlePaste = (code: string) => {
    const trimmedCode = code.trim();
    if (trimmedCode.length !== length) {
      return;
    }

    helpers.setValue(trimmedCode);
  };

  const focusFieldWithIndex = (index: number) => {
    if (index < 0 || index >= length) {
      return;
    }

    const fieldToFocus = fieldRefs.current[index];
    fieldToFocus?.current?.focus();
  };

  // prepare array of refs if necessary
  if (fieldRefs.current.length !== length) {
    times(length, (i) => {
      if (!fieldRefs.current[i]) {
        fieldRefs.current[i] = createRef();
      }
    });
  }

  return (
    <>
      <StyledCodeVerifier data-cy="code-input-field">
        {times(length, (index) => (
          <SingleDigitInput
            key={`digit-${index}`}
            ref={fieldRefs.current[index]}
            value={field.value[index] === " " ? undefined : field.value[index]}
            onDigitEntered={(digit: string | undefined) => {
              replaceValueAtIndex(index, digit);

              if (digit) {
                focusFieldWithIndex(index + 1);
              }
            }}
            onPaste={handlePaste}
            onBackspace={() => {
              focusFieldWithIndex(index - 1);
            }}
          />
        ))}
      </StyledCodeVerifier>
      <DisplayFieldErrors name={fieldName} />
    </>
  );
};

export default CodeInput;
