import { Formik, FormikHelpers } from "formik";
import {
  MelcoCoreModelsPermissionsListItem,
  MelcoCoreModelsPermissionsSubListItem,
  MelcoCoreModelsUserProductPermissions,
  MelcoCoreModelsUsersServiceUser,
  MelcoCoreModelsUsersSubUserDetail,
  useAuthenticatedAPIConfig,
  useFormSubmit,
  UserAccountApi,
  ViewContext,
} from "melco-shared-logic";
import { message } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useIsAdminRoleId } from "../../hooks/permissions/useIsAdminRoleId";
import { useUserPermissions } from "../../hooks/permissions/useUserPermissions";
import { useRoles } from "../../hooks/product/useRoles";
import { DisplayUserPermissionsForm } from "./DisplayUserPermissionsForm";

type PermissionsHash = Record<string, MelcoCoreModelsPermissionsListItem>;

export type UserProductAccess = {
  active: boolean;
  is_removable: boolean;
  product_code: string;
  product_name: string;
  permissions: PermissionsHash;
};

export type UserProductAccessMap = Record<string, UserProductAccess>;

export type PermissionsMap = Record<
  string,
  MelcoCoreModelsPermissionsSubListItem
>;

type PermissionsValueType = {
  role: { id: string };
  user_product_access: UserProductAccessMap;
};

const permissionsToInitialValues = (
  permissions: MelcoCoreModelsUserProductPermissions
) => {
  const { role, user_product_access } = permissions;

  const userProductAccess = (user_product_access ?? []).reduce((hash, upa) => {
    const { active, is_removable, product_code, product_name, permissions } =
      upa;

    if (!product_code) {
      return hash;
    }

    const permissionsMap = permissions?.reduce((hash, permission) => {
      const { code } = permission;

      if (!code) {
        return hash;
      }

      hash[code] = permission;

      return hash;
    }, {} as PermissionsMap);

    hash[product_code] = {
      active: active ?? false,
      is_removable: is_removable ?? false,
      product_code: product_code ?? "",
      product_name: product_name ?? "",
      permissions: permissionsMap!,
    };

    return hash;
  }, {} as UserProductAccessMap);

  const initialValues: PermissionsValueType = {
    role: { id: role?.id ?? "" },
    user_product_access: userProductAccess,
  };

  return initialValues;
};

type UserPermissionsProps = {
  user: MelcoCoreModelsUsersServiceUser | MelcoCoreModelsUsersSubUserDetail;
  afterPermissionsSaved?: () => void;
  viewContext?: ViewContext;
};

export const UserPermissions: React.FC<UserPermissionsProps> = ({
  viewContext,
  user,
  afterPermissionsSaved,
}) => {
  const { t } = useTranslation();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const { data: permissions } = useUserPermissions(user);
  const { data: roles } = useRoles();
  const isAdminRoleId = useIsAdminRoleId();

  const onHandleSubmit = useFormSubmit(
    async (
      values: PermissionsValueType,
      formikHelpers: FormikHelpers<PermissionsValueType>
    ) => {
      const api = new UserAccountApi(authenticatedApiConfig());

      const { role, user_product_access } = values;

      const isAdminRole = isAdminRoleId(role.id);

      const userProductAccessAsArray = isAdminRole
        ? []
        : Object.values(user_product_access).map((product) => {
            const { permissions } = product;
            return { ...product, permissions: Object.values(permissions) };
          });

      const selectedRole = roles?.find((r) => r.id === role.id);

      const updatedPermissions = await api.userAccountEditAllPermissions({
        userId: user.id!,
        melcoCoreModelsUserProductPermissions: {
          role: selectedRole,
          user_product_access: userProductAccessAsArray,
        },
      });

      formikHelpers.resetForm({
        values: permissionsToInitialValues(updatedPermissions),
      });
    },
    {
      translationPrefix: "users.details.permissions",
      onSuccess: () => {
        viewContext === ViewContext.Drawer
          ? afterPermissionsSaved && afterPermissionsSaved()
          : message.success(t("users.details.permissions.success.default"));
      },
    }
  );

  if (!permissions) {
    return null;
  }

  const initialValues = permissionsToInitialValues(permissions);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) =>
        onHandleSubmit(values, formikHelpers, { isManuallyResettingForm: true })
      }
    >
      <DisplayUserPermissionsForm viewContext={viewContext} />
    </Formik>
  );
};
