import { useCallback } from "react";
import { UserManagementApi } from "../../api";
import { useBaseAPIConfig } from "../api/useAPIConfig";

export const useIsEmailAvailable = () => {
  const baseApiConfig = useBaseAPIConfig();
  return useCallback(
    async (username: string) => {
      const api = new UserManagementApi(baseApiConfig());

      const response = await api.userIsAvailable({
        username,
      });

      return response.result ?? false;
    },
    [baseApiConfig]
  );
};
