import { useTranslation } from "react-i18next";
import { FlowBox, FlowBoxButton, FullWidthSpace, Paragraph } from "melco-ui";
import { Form, FormikProps } from "formik";
import { ResetPasswordFormValueType } from "./ResetPasswordForm";
import { InputField, DisplayFormErrors } from "melco-shared-logic";
import { DisplayFormStatusMessages } from "../form/DisplayFormStatusMessages";

type DisplayResetPasswordFormProps = {
  children?: React.ReactNode;
} & FormikProps<ResetPasswordFormValueType>;

export const DisplayResetPasswordForm: React.FC<
  DisplayResetPasswordFormProps
> = ({ isSubmitting, children }) => {
  const { t } = useTranslation();

  return (
    <>
      <DisplayFormStatusMessages />

      <DisplayFormErrors />

      <FullWidthSpace direction="vertical">
        <FlowBox>
          {children}

          <Form>
            <InputField
              name="password"
              size="large"
              placeholder={t("global.form.label.profile.password")}
              type="password"
              autoComplete="current-password"
            />

            <InputField
              name="confirm_password"
              size="large"
              placeholder={t("global.form.label.profile.confirm_password")}
              type="password"
              autoComplete="confirm"
            />

            <Paragraph size="small" type="secondary">
              {t("global.hint.password_requirements")}
            </Paragraph>

            <FlowBoxButton
              loading={isSubmitting}
              disabled={isSubmitting}
              htmlType="submit"
            >
              {t("forgotpasswordresetpage.form.submit")}
            </FlowBoxButton>
          </Form>
        </FlowBox>
      </FullWidthSpace>
    </>
  );
};
