import { useField } from "formik";
import { SwitchField } from "melco-shared-logic";
import { Collapse, CollapseHeader, DesignTokens, Space } from "melco-ui";
import styled from "styled-components";
import isEmpty from "lodash-es/isEmpty";
import { ManagePermissionGroup } from "./ManagePermissionGroup";
import { UserProductAccess } from "./UserPermissions";
import { SimpleAppPermissionsHint } from "./SimpleAppPermissionsHint";

const { spacings } = DesignTokens;

const NoMarginSwitchFieldWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const CollapseHeaderWrapper = styled.div`
  margin-top: ${spacings.xsmall}px;
`;

type ManageUserProductAccessProps = {
  userProductAccess: UserProductAccess;
};

export const ManageUserProductAccess: React.FC<
  ManageUserProductAccessProps
> = ({ userProductAccess }) => {
  const { product_code, product_name, permissions, is_removable } =
    userProductAccess;

  const rootPermissions =
    Object.values(permissions).filter((p) => isEmpty(p.parent_code)) ?? [];

  const [{ value: isProductActive }] = useField(
    `user_product_access.${product_code}.active`
  );

  return (
    <Collapse
      expandIcon={() => (
        <NoMarginSwitchFieldWrapper>
          <SwitchField
            name={`user_product_access.${product_code}.active`}
            data-cy-id={`permissions-product-enabled-${product_code}`}
            disabled={!is_removable}
          />
        </NoMarginSwitchFieldWrapper>
      )}
      activeKey={isProductActive ? product_code : undefined}
      collapsible="icon"
    >
      <Collapse.Panel
        key={product_code}
        header={
          <CollapseHeaderWrapper>
            <CollapseHeader title={product_name} />
          </CollapseHeaderWrapper>
        }
        data-cy-id={`permissions-product-panel-${product_code}`}
      >
        {isEmpty(rootPermissions) ? (
          <SimpleAppPermissionsHint />
        ) : (
          <Space direction="vertical" size="middle">
            {rootPermissions.map((permission) => (
              <ManagePermissionGroup
                key={permission.code}
                productCode={product_code ?? ""}
                permission={permission}
                allPermissions={Object.values(permissions)}
              />
            ))}
          </Space>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};
