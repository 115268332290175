import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthenticationProvider, useConfigProvider } from "melco-shared-logic";
import { Main } from "./Main";

import "melco-ui/dist/melcoUI.css";
import "./fonts.css";
import { ConfigProvider } from "melco-ui";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
    },
  },
});

const App: React.FC = () => {
  const configProviderProps = useConfigProvider();

  return (
    <Suspense fallback={null}>
      <ConfigProvider {...configProviderProps}>
        <QueryClientProvider client={queryClient}>
          <AuthenticationProvider>
            <Main />
          </AuthenticationProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </Suspense>
  );
};

export default App;
