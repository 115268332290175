/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCalculatorModelsCalculation,
    MelcoCalculatorModelsCalculationFromJSON,
    MelcoCalculatorModelsCalculationFromJSONTyped,
    MelcoCalculatorModelsCalculationToJSON,
    MelcoCalculatorModelsJobResponse,
    MelcoCalculatorModelsJobResponseFromJSON,
    MelcoCalculatorModelsJobResponseFromJSONTyped,
    MelcoCalculatorModelsJobResponseToJSON,
    MelcoCalculatorModelsMachineResponse,
    MelcoCalculatorModelsMachineResponseFromJSON,
    MelcoCalculatorModelsMachineResponseFromJSONTyped,
    MelcoCalculatorModelsMachineResponseToJSON,
    MelcoCalculatorModelsSettingResponse,
    MelcoCalculatorModelsSettingResponseFromJSON,
    MelcoCalculatorModelsSettingResponseFromJSONTyped,
    MelcoCalculatorModelsSettingResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCalculatorModelsCalculationDetail
 */
export interface MelcoCalculatorModelsCalculationDetail {
    /**
     * 
     * @type {MelcoCalculatorModelsSettingResponse}
     * @memberof MelcoCalculatorModelsCalculationDetail
     */
    settings?: MelcoCalculatorModelsSettingResponse;
    /**
     * 
     * @type {MelcoCalculatorModelsMachineResponse}
     * @memberof MelcoCalculatorModelsCalculationDetail
     */
    machine?: MelcoCalculatorModelsMachineResponse;
    /**
     * 
     * @type {MelcoCalculatorModelsJobResponse}
     * @memberof MelcoCalculatorModelsCalculationDetail
     */
    job?: MelcoCalculatorModelsJobResponse;
    /**
     * 
     * @type {MelcoCalculatorModelsCalculation}
     * @memberof MelcoCalculatorModelsCalculationDetail
     */
    calculation?: MelcoCalculatorModelsCalculation;
}

export function MelcoCalculatorModelsCalculationDetailFromJSON(json: any): MelcoCalculatorModelsCalculationDetail {
    return MelcoCalculatorModelsCalculationDetailFromJSONTyped(json, false);
}

export function MelcoCalculatorModelsCalculationDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCalculatorModelsCalculationDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settings': !exists(json, 'settings') ? undefined : MelcoCalculatorModelsSettingResponseFromJSON(json['settings']),
        'machine': !exists(json, 'machine') ? undefined : MelcoCalculatorModelsMachineResponseFromJSON(json['machine']),
        'job': !exists(json, 'job') ? undefined : MelcoCalculatorModelsJobResponseFromJSON(json['job']),
        'calculation': !exists(json, 'calculation') ? undefined : MelcoCalculatorModelsCalculationFromJSON(json['calculation']),
    };
}

export function MelcoCalculatorModelsCalculationDetailToJSON(value?: MelcoCalculatorModelsCalculationDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settings': MelcoCalculatorModelsSettingResponseToJSON(value.settings),
        'machine': MelcoCalculatorModelsMachineResponseToJSON(value.machine),
        'job': MelcoCalculatorModelsJobResponseToJSON(value.job),
        'calculation': MelcoCalculatorModelsCalculationToJSON(value.calculation),
    };
}


