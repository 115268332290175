import { UserAccountApi, useUserAccountAPI } from "melco-shared-logic";
import { useCallback } from "react";
import { BasicSettingsFormValueType } from "./useBasicSettingsFormSubmit";

const initialValue = { settings: undefined };

type BasicSettings = {
  settings?: BasicSettingsFormValueType;
};

export const useBasicSettings = () => {
  const handleRequest = useCallback(async (api: UserAccountApi) => {
    const { default_language } = await api.userDefaultsGet();

    return {
      settings: {
        default_language: default_language ?? "",
      },
    };
  }, []);

  return useUserAccountAPI<BasicSettings>({
    initialValue,
    handleRequest,
  });
};
