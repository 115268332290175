import { useTranslation } from "react-i18next";
import {
  MelcoCoreModelsUserProductListItem,
  SelectField,
} from "melco-shared-logic";
import sortBy from "lodash-es/sortBy";
import { useProducts } from "../../hooks/product/useProducts";

const API_KEY_GENERATION_PERMISSION = "GENERATE_APIKEYS";

const isSupportingAPIKeyGeneration = (
  product: MelcoCoreModelsUserProductListItem
) => {
  if (!product?.permissions) {
    return false;
  }

  return product.permissions.includes(API_KEY_GENERATION_PERMISSION);
};

export const SelectAPIKeyProductField: React.FC = () => {
  const { t } = useTranslation();

  const { data: products, isLoading } = useProducts({ skipFilter: true });

  const filteredProducts = (products ?? []).filter(
    isSupportingAPIKeyGeneration
  );

  const unsortedOptions = filteredProducts.map(({ code, name }) => {
    return {
      label: name ?? code ?? "",
      value: code ?? "",
    };
  });

  const options = sortBy(unsortedOptions, ["label"], ["asc"]);

  return (
    <SelectField
      name="product_code"
      label={t("api_list.actions.add.form.label.product_code")}
      options={options}
      optionFilterProp="label"
      loading={isLoading}
    />
  );
};
