import styled, { css } from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";
import { Elevated } from "../appearance/Elevated";

const { spacings } = DesignTokens;

const CanvasPanelWrapper = styled.div``;

const CanvasPanelInner = styled.div.withConfig({
  shouldForwardProp: (prop) => !["contentPadding"].includes(prop),
})<Pick<CanvasPanelProps, "contentPadding">>`
  ${({ contentPadding }) =>
    contentPadding &&
    css`
      && {
        padding: 0 ${spacings.xlarge}px ${spacings.xlarge}px;
      }
    `}
`;

export type CanvasPanelProps = {
  elevation: "left" | "right";
  contentPadding?: boolean;
  children?: React.ReactNode;
};

export const CanvasPanel: React.FC<CanvasPanelProps> = ({
  elevation,
  contentPadding,
  children,
  ...rest
}) => {
  return (
    <CanvasPanelWrapper {...rest}>
      <Elevated
        level={1}
        left={elevation === "left"}
        right={elevation === "right"}
      >
        <CanvasPanelInner contentPadding={contentPadding}>
          {children}
        </CanvasPanelInner>
      </Elevated>
    </CanvasPanelWrapper>
  );
};
