import { Alert, FullWidthSpace } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useIsAdminRole } from "../../hooks/permissions/useIsAdminRole";
import { useManageUserProductAccess } from "../../hooks/permissions/useManageUserProductAccess";
import { ManageUserProductAccess } from "./ManageUserProductAccess";

export const ManageUserProductAccessOrShowAdminHint: React.FC = () => {
  const { t } = useTranslation();
  const isAdminRole = useIsAdminRole();
  const userProductAccess = useManageUserProductAccess();

  if (isAdminRole) {
    return (
      <Alert
        type="info"
        withBackground
        showIcon
        description={t("users.details.permissions.hint.adminRole")}
      />
    );
  }

  return (
    <FullWidthSpace direction="vertical" size="small">
      {Object.values(userProductAccess).map((upa) => (
        <ManageUserProductAccess
          key={upa.product_code}
          userProductAccess={upa}
        />
      ))}
    </FullWidthSpace>
  );
};
