import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { SelectField, useStates } from "melco-shared-logic";
import { SizeType } from "../../types/layout";

type StateSelectProps = {
  label?: string;
  size?: SizeType;
};

const StateSelect: React.FC<StateSelectProps> = ({ label, size }) => {
  const { t } = useTranslation();
  const [field] = useField("country_code");
  const { states, isLoading } = useStates(field.value);

  return (
    <SelectField
      name="state_code"
      label={label}
      size={size}
      placeholder={t("global.form.label.profile.state_code")}
      options={states.map((s) => {
        return {
          label: s.name,
          value: s.code,
        };
      })}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      disabled={isLoading}
    />
  );
};

export default StateSelect;
