import styled, { css } from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { colors } = DesignTokens;

export const CanvasActionGroupItem = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  padding: 7px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  &:active {
    background-color: ${colors.gray.light};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      && {
        border: 1px solid ${colors.main.primary};
      }
    `}
`;
