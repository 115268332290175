import AntdPageHeader, {
  PageHeaderProps as AntdPageHeaderProps,
} from "antd/es/page-header";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings, colors, fonts } = DesignTokens;

export type ContentHeaderProps = AntdPageHeaderProps;

const ContentHeader = styled(AntdPageHeader)`
  padding: ${spacings.xlarge * 2 + 10}px ${spacings.xlarge}px
    ${spacings.middle}px ${spacings.xlarge}px;
  border-bottom: 1px solid ${colors.gray.light};
  background-color: ${colors.gray.superLight};

  .ant-page-header-heading-title {
    font-weight: 500;
    font-family: ${fonts.sansSerifMd};
  }

  .ant-page-header-heading-left {
    margin-top: 0;
  }

  .ant-page-header-heading-extra {
    display: flex;
  }

  &.has-breadcrumb {
    /* Needed because we want to have the buttons on the right correct aligned */
    padding-top: ${spacings.xlarge + 10}px;
  }
`;

export { ContentHeader };
