/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
} from '../models';

export interface UtilsApiUtilsRequest {
    designId: number;
    productCode: string | null;
}

/**
 * 
 */
export class UtilsApi extends runtime.BaseAPI {

    /**
     * reset design cache
     * reset design rfm cache
     */
    async utilsRaw(requestParameters: UtilsApiUtilsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.designId === null || requestParameters.designId === undefined) {
            throw new runtime.RequiredError('designId','Required parameter requestParameters.designId was null or undefined when calling utils.');
        }

        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling utils.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/utils/designs/regenerate_rfm/{designId}/{productCode}`.replace(`{${"designId"}}`, encodeURIComponent(String(requestParameters.designId))).replace(`{${"productCode"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * reset design cache
     * reset design rfm cache
     */
    async utils(requestParameters: UtilsApiUtilsRequest): Promise<void> {
        await this.utilsRaw(requestParameters);
    }

}
