import { includes } from "lodash-es";
import reject from "lodash-es/reject";
import {
  MelcoCoreModelsUserProductListItem,
  useIsSubUser,
} from "melco-shared-logic";
import { useAllProducts } from "./useAllProducts";
import { isInternalProduct, isProductDisplayed } from "./useProducts";

export const useMoreProducts = (
  userProducts: MelcoCoreModelsUserProductListItem[] | undefined
) => {
  const { data: allProducts } = useAllProducts();
  const isSubUser = useIsSubUser();

  if (!userProducts || !allProducts || isSubUser) {
    return [];
  }

  const userProductCodes = userProducts.map((p) => p.code);

  const moreProducts = reject(
    allProducts,
    (p) =>
      includes(userProductCodes, p.code) ||
      !isProductDisplayed(p) ||
      isInternalProduct(p)
  );

  return moreProducts;
};
