import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import DisplayChangeEMailForm from "./DisplayChangeEMailForm";
import {
  useBaseAPIConfig,
  useFlowFormSubmit,
  UserManagementApi,
  VerificationState,
} from "melco-shared-logic";
import { EmailAvailabilityState } from "./EmailFieldWithAvailabilityCheck";

export type ChangeEMailFormValueType = {
  user_name: string;
  new_user_name: string;
  current_password: string | undefined;
  isEmailAvailable: EmailAvailabilityState;
};

export const ChangeEMailFormSchema = Yup.object().shape({
  user_name: Yup.string().required("global.error.required.default"),
  new_user_name: Yup.string()
    .email("global.error.shouldbe.email")
    .required("global.error.required.default")
    .test(
      "isNotTaken",
      "registrationpage.error.user_name_exists",
      (_, context) =>
        context.parent.isEmailAvailable !== EmailAvailabilityState.Taken
    ),
});

type ChangeEMailFormProps = {
  verificationState: VerificationState;
};

export const ChangeEMailForm: React.FC<ChangeEMailFormProps> = ({
  verificationState,
}) => {
  const baseApiConfig = useBaseAPIConfig();
  const navigate = useNavigate();

  const { eMail, setEMail } = verificationState;

  const onSubmit = useFlowFormSubmit<ChangeEMailFormValueType>(
    async (values: ChangeEMailFormValueType) => {
      const { user_name, new_user_name } = values;

      const api = new UserManagementApi(baseApiConfig());

      const success = await api.userChangeUserNameAnonymously({
        melcoCoreModelsUsersChangeAnonymousUserName: {
          user_name,
          new_user_name,
        },
      });

      if (success) {
        setEMail(new_user_name);
        navigate("/register/verify?changeemail-success");
      } else {
        throw new Error();
      }
    },
    { translationPrefix: "accountloginpage.tab_2" }
  );

  if (!eMail) {
    return null;
  }

  const initialValues: ChangeEMailFormValueType = {
    user_name: eMail,
    new_user_name: "",
    current_password: undefined,
    isEmailAvailable: EmailAvailabilityState.Unknown,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangeEMailFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => <DisplayChangeEMailForm {...props} />}
    </Formik>
  );
};
