import { APIOptionsWithoutAPIClass, useAPI } from "./useAPI";
import { SystemDefaultsApi } from "../../api";

export const useDefaultsAPI = <T>({
  initialValue,
  handleRequest,
}: APIOptionsWithoutAPIClass<T, SystemDefaultsApi>) => {
  return useAPI<T, SystemDefaultsApi>({
    initialValue,
    APIClass: SystemDefaultsApi,
    handleRequest,
  });
};
