import { useCallback } from "react";
import { useRoles } from "../product/useRoles";

export const useIsAdminRoleId = () => {
  const { data: roles } = useRoles();

  return useCallback(
    (roleId: string | undefined) => {
      const role = (roles ?? []).find((r) => roleId && r.id === roleId);

      return role?.code === "ADMIN";
    },
    [roles]
  );
};
