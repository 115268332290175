import { useTranslation } from "react-i18next";
import { FlowBoxButton, FormRow, Paragraph } from "melco-ui";
import { Form, FormikProps } from "formik";
import { InputField } from "melco-shared-logic";
import { RegisterFormValueType } from "./RegisterForm";
import { EmailFieldWithAvailabilityCheck } from "./EmailFieldWithAvailabilityCheck";
import CountrySelect from "../form/CountrySelect";

export const DisplayRegisterForm: React.FC<
  FormikProps<RegisterFormValueType>
> = ({ isSubmitting }) => {
  const { t } = useTranslation();

  // FIXME: Add error message display!

  return (
    <Form>
      <FormRow>
        <InputField
          name="first_name"
          size="large"
          placeholder={t("global.form.label.profile.first_name")}
        />

        <InputField
          name="last_name"
          size="large"
          placeholder={t("global.form.label.profile.last_name")}
        />
      </FormRow>

      <CountrySelect name="country_code" size="large" />

      <EmailFieldWithAvailabilityCheck
        name="user_name"
        placeholder={t("global.form.label.profile.email")}
        size="large"
      />

      <InputField
        name="password"
        size="large"
        placeholder={t("global.form.label.profile.password")}
        type="password"
        autoComplete="current-password"
      />

      <InputField
        name="confirm_password"
        size="large"
        placeholder={t("global.form.label.profile.confirm_password")}
        type="password"
        autoComplete="confirm"
      />

      <Paragraph size="small" type="secondary">
        {t("global.hint.password_requirements")}
      </Paragraph>

      <FlowBoxButton
        loading={isSubmitting}
        disabled={isSubmitting}
        htmlType="submit"
      >
        {t("registrationpage.form.submit")}
      </FlowBoxButton>
    </Form>
  );
};
