import { APIOptionsWithoutAPIClass, useAPI } from "./useAPI";
import { UserAccountApi } from "../../api";

export const useUserAccountAPI = <T>({
  initialValue,
  handleRequest,
}: APIOptionsWithoutAPIClass<T, UserAccountApi>) => {
  return useAPI<T, UserAccountApi>({
    initialValue,
    APIClass: UserAccountApi,
    handleRequest,
  });
};
