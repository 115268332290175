import { MouseEventHandler, useCallback, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DesignTokens } from "melco-ui";
import {
  isTrialProduct,
  MelcoCoreModelsUserProductListItem,
} from "melco-shared-logic";
import LoadingIndicator from "../loading/LoadingIndicator";
import AppIconAndName from "./AppIconAndName";
import LaunchTrialAppModal from "../trial/LaunchTrialAppModal";
import { useLaunchApp } from "../../hooks/product/useLaunchApp";

const { colors, spacings, animations } = DesignTokens;

const APP_ICON_WIDTH = 194;
const APP_ICON_HEIGHT = 194;
const APP_ICON_BORDER_RADIUS = 25;

const AppIconWrapper = styled.div`
  display: inline-block;
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_HEIGHT}px;
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
  margin: ${spacings.middle}px;
  background-color: ${colors.gray.middle};
  cursor: pointer;
  overflow: hidden;

  &:hover {
    .open-product {
      opacity: 1;
    }
    .logo-content {
      transform: translateY(-20px);
    }
  }
`;

const AppIconContent = styled.div`
  position: relative;
  width: ${APP_ICON_WIDTH}px;
  height: ${APP_ICON_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacings.middle}px;
`;

const OpenProduct = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 55px;

  background-color: ${colors.main.primary};
  opacity: 0;
  transition: opacity ${animations.durations.slow}s;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 ${APP_ICON_BORDER_RADIUS}px ${APP_ICON_BORDER_RADIUS}px;

  color: ${colors.main.negative};
`;

type ProductProps = {
  product: MelcoCoreModelsUserProductListItem;
};

const AppIcon: React.FC<ProductProps> = ({ product }) => {
  const { code } = product;

  const { t } = useTranslation();
  const [launchApp, isLaunching] = useLaunchApp(product);
  const [isLaunchTrialAppModalOpen, setIsLaunchTrialAppModalOpen] =
    useState(false);

  const isTrial = isTrialProduct(product);

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    async (e) => {
      e.preventDefault();
      if (isTrial) {
        setIsLaunchTrialAppModalOpen(true);
        return;
      }

      await launchApp();
    },
    [setIsLaunchTrialAppModalOpen, isTrial, launchApp]
  );

  const handleLaunchTrialApp = useCallback(async () => {
    setIsLaunchTrialAppModalOpen(false);
    await launchApp();
  }, [setIsLaunchTrialAppModalOpen, launchApp]);

  return (
    <>
      <AppIconWrapper data-cy={`product-${code}`}>
        <LoadingIndicator isShown={isLaunching}>
          <AppIconContent onClick={onClick}>
            <AppIconAndName product={product} />
            <OpenProduct className="open-product">
              {t("dashboard.apps.open")}
            </OpenProduct>
          </AppIconContent>
        </LoadingIndicator>
      </AppIconWrapper>

      {isTrial && (
        <LaunchTrialAppModal
          product={product}
          key={code}
          open={isLaunchTrialAppModalOpen}
          onLaunch={handleLaunchTrialApp}
          onCancel={() => setIsLaunchTrialAppModalOpen(false)}
        />
      )}
    </>
  );
};

export default AppIcon;
