import { Formik } from "formik";
import * as Yup from "yup";
import { DisplayBasicSettingsForm } from "./DisplayBasicSettingsForm";
import { useBasicSettings } from "../../hooks/settings/useBasicSettings";
import { useBasicSettingsFormSubmit } from "../../hooks/settings/useBasicSettingsFormSubmit";
import { HeaderSaveFormButton, useCultures } from "melco-shared-logic";

const BasicSettingsSchema = Yup.object().shape({
  default_language: Yup.string().required(),
});

export const BasicSettingsForm: React.FC = () => {
  const { settings, isLoading } = useBasicSettings();
  const { cultures, isLoading: isCulturesLoading } = useCultures();
  const onSubmit = useBasicSettingsFormSubmit();

  if (isLoading || !settings || isCulturesLoading || !cultures) {
    return null;
  }

  return (
    <Formik
      initialValues={settings}
      validationSchema={BasicSettingsSchema}
      onSubmit={onSubmit}
    >
      <>
        <DisplayBasicSettingsForm cultures={cultures} />
        <HeaderSaveFormButton />
      </>
    </Formik>
  );
};
