import { useFormikContext } from "formik";
import { Button, SaveIcon } from "melco-ui";
import { useTranslation } from "react-i18next";

const AddAccountNameButton: React.FC = () => {
  const { t } = useTranslation();

  const { handleSubmit, isSubmitting } = useFormikContext();

  return (
    <Button
      type="primary"
      icon={<SaveIcon />}
      loading={isSubmitting}
      disabled={isSubmitting}
      onClick={() => handleSubmit()}
    >
      {t("dashboard.add_account_name.ok")}
    </Button>
  );
};

export default AddAccountNameButton;
