import { useTranslation } from "react-i18next";
import { PageContentWrapper, PageTitle } from "melco-ui";
import { ProfileForm } from "../../components/profile/ProfileForm";

const AccountProfile: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContentWrapper narrow>
      <PageTitle>{t("accountprofilepage.title")}</PageTitle>
      <ProfileForm />
    </PageContentWrapper>
  );
};

export default AccountProfile;
