import { useTranslation } from "react-i18next";
import {
  isTrialExpired,
  isTrialProduct,
  MelcoCoreModelsUserProductListItem,
} from "melco-shared-logic";
import { DesignTokens } from "melco-ui";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const { fonts, colors } = DesignTokens;

const ActiveTrial = styled.div`
  font-family: ${fonts.sansSerifMd};
  font-size: ${fonts.sizes.small}px;
`;

const ExpiredTrial = styled.div`
  font-family: ${fonts.sansSerifMd};
  font-size: ${fonts.sizes.small}px;
  color: ${colors.feedback.error};
`;

type AppTrialInfoProps = {
  product: MelcoCoreModelsUserProductListItem;
};

export const AppTrialInfo: React.FC<AppTrialInfoProps> = ({ product }) => {
  const { t } = useTranslation();

  if (!isTrialProduct(product)) {
    return null;
  }

  const isExpired = isTrialExpired(product);

  if (isExpired) {
    return <ExpiredTrial>{t("dashboard.apps.trial.expired")}</ExpiredTrial>;
  }

  const { expires_on } = product;

  return (
    <ActiveTrial>
      {t("dashboard.apps.trial.active", {
        fromNow: dayjs(expires_on).fromNow(),
      })}
    </ActiveTrial>
  );
};
