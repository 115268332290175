import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import find from "lodash-es/find";
import {
  parseAPIErrorResponse,
  useFlowFormSubmit,
  useLogin,
} from "melco-shared-logic";
import { DisplayLoginForm } from "./DisplayLoginForm";

export type LoginFormValueType = {
  user_name: string;
  password: string;
};

const initialValues: LoginFormValueType = {
  user_name: "",
  password: "",
};

const LoginFormSchema = Yup.object().shape({
  user_name: Yup.string().required("global.error.required.user_name"),
  password: Yup.string().required("global.error.required.default"),
});

const apiErrorsContain = (apiErrors: any[], errorKey: string) => {
  return find(
    apiErrors,
    (e) => e.key?.toUpperCase() === errorKey.toUpperCase()
  );
};

export const LoginForm = () => {
  const login = useLogin();
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = useFlowFormSubmit(
    async (values: LoginFormValueType) => {
      const { user_name, password } = values;

      const searchParams = new URLSearchParams(location.search);

      try {
        const { forcePasswordChange } = await login(user_name, password);

        if (forcePasswordChange) {
          const redirectSearchParams = new URLSearchParams();

          if (searchParams.has("redirectTo")) {
            redirectSearchParams.set(
              "redirectTo",
              searchParams.get("redirectTo")!
            );
          }

          navigate(
            `/updatepassword${
              redirectSearchParams.size ? `?${redirectSearchParams}` : ""
            }`
          );
        } else {
          if (searchParams.has("redirectTo")) {
            window.location.href = searchParams.get("redirectTo")!;
          } else {
            navigate("/dashboard");
          }
        }
      } catch (error) {
        // check if special error handling is neccessary (user needs to be verified)
        const apiErrors = await parseAPIErrorResponse(error);
        if (apiErrors) {
          if (
            apiErrorsContain(apiErrors, "USER_NOT_VERIFIED") ||
            apiErrorsContain(apiErrors, "NOT_VERIFIED")
          ) {
            navigate(`/register/verify?eMail=${user_name}`);
            return;
          }
        }

        // status kept in URL search param like "forget password" should be reset
        // redirect param however should not be lost
        if (searchParams.has("redirectTo")) {
          navigate(`/?redirectTo=${searchParams.get("redirectTo")}`, {
            replace: true,
          });
        } else {
          navigate("/", { replace: true });
        }

        throw error;
      }
    },
    { translationPrefix: "loginpage" }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => <DisplayLoginForm {...props} />}
    </Formik>
  );
};
