import { useCallback } from "react";
import { UserManagementApi } from "../../api";
import { useBaseAPIConfig } from "../api/useAPIConfig";

export type ResendCodeProcess = "forgotPassword" | "register";

export const useResendCode = () => {
  const baseApiConfig = useBaseAPIConfig();
  return useCallback(
    async (process: ResendCodeProcess, eMail: string) => {
      const api = new UserManagementApi(baseApiConfig());

      let response;
      switch (process) {
        case "forgotPassword":
          response = await api.userResendForgotPasswordCode({
            username: eMail,
          });
          break;
        case "register":
          response = await api.userResendCode({ username: eMail });
      }

      return response.result ?? false;
    },
    [baseApiConfig]
  );
};
