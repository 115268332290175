import { Formik } from "formik";
import {
  useFormSubmit,
  UserAccountApi,
  MelcoCoreModelsDRMUserSerialNumber,
  HeaderSaveFormButton,
  useAuthenticatedAPIConfig,
} from "melco-shared-logic";
import { useQueryClient } from "react-query";
import { DisplayLicenseDetailForm } from "./DisplayLicenseDetailForm";

type LicenseDetailFormValueType = {
  display_name: string;
};

export const licenseToInitialValues = (
  license: MelcoCoreModelsDRMUserSerialNumber
) => {
  const { display_name } = license;

  const initialValues: LicenseDetailFormValueType = {
    display_name: display_name ?? "",
  };

  return initialValues;
};

type LicenseDetailFormProps = {
  license: MelcoCoreModelsDRMUserSerialNumber;
};

export const LicenseDetailForm: React.FC<LicenseDetailFormProps> = ({
  license,
}) => {
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const queryClient = useQueryClient();

  const initialValues = licenseToInitialValues(license);

  const onSubmit = useFormSubmit<LicenseDetailFormValueType>(
    async (values: LicenseDetailFormValueType) => {
      const { display_name } = values;

      const api = new UserAccountApi(authenticatedApiConfig());

      await api.subscriptionEdit({
        userSubscriptionId: license!.id!,
        melcoCoreModelsDRMUserSerialNumberEdit: {
          display_name,
        },
      });

      return { successMessages: [{ message: "default" }] };
    },
    {
      translationPrefix: "infrastructureLicenses.form",
      onSuccess: () => {
        queryClient.invalidateQueries(["licenses"]);
      },
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <>
        <DisplayLicenseDetailForm />
        <HeaderSaveFormButton />
      </>
    </Formik>
  );
};
