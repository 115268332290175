import React from "react";
import { useAuthProvider } from "../../hooks/auth/useAuthProvider";
import { CurrentUser } from "../../hooks/user/useCurrentUser";

type AuthenticationContextType = {
  currentUser: CurrentUser | undefined;
  changeCurrentUser: (currentUser: CurrentUser | undefined) => void;
  isCurrentlyTryingToRestoreToken: boolean;
  setIsCurrentlyTryingToRestoreToken: (value: boolean) => void;
};

export const AuthenticationContext = React.createContext<
  AuthenticationContextType | undefined
>(undefined);

type AuthenticationProviderProps = {
  children?: React.ReactNode;
};

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children,
}) => {
  const auth = useAuthProvider();

  return (
    <AuthenticationContext.Provider value={auth}>
      {children}
    </AuthenticationContext.Provider>
  );
};
