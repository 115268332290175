import React from "react";
import { Title } from "../../typography/Title";

export type FlowBoxTitleProps = {
  children?: string;
};

export const FlowBoxTitle: React.FC<FlowBoxTitleProps> = ({ children }) => (
  <Title level={4}>{children}</Title>
);
