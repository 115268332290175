import { useTranslation } from "react-i18next";
import { FlowPageTitle } from "melco-ui";
import { ForgotPasswordForm } from "../../components/forgotpassword/ForgotPasswordForm";
import { VerificationState } from "melco-shared-logic";

type ForgotPasswordProps = {
  verificationState: VerificationState;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  verificationState,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FlowPageTitle>{t("forgotpasswordpage.title")}</FlowPageTitle>

      <ForgotPasswordForm verificationState={verificationState} />
    </>
  );
};

export default ForgotPassword;
