import {
  MelcoCoreModelsUserProductListItem,
  useAPIQuery,
  useCurrentUser,
  useIsSubUser,
  UserAccountApi,
} from "melco-shared-logic";

export const isProductDisplayed = (
  product: MelcoCoreModelsUserProductListItem
) => !!product.is_display;

export const isInternalProduct = (
  product: MelcoCoreModelsUserProductListItem
) => !!product.is_internal;

export type UseProductOptions = {
  skipFilter?: boolean;
};

export const useProducts = ({ skipFilter }: UseProductOptions = {}) => {
  const user = useCurrentUser();
  const isSubUser = useIsSubUser();

  return useAPIQuery(
    UserAccountApi,
    ["products", user?.userId, isSubUser],
    async (api) => {
      if (!user) {
        return undefined;
      }

      const response = isSubUser
        ? await api.userAccountGetProductsList({ id: user?.userId })
        : await api.accountProductsList();

      if (skipFilter) {
        return response.list ?? [];
      } else {
        return (response.list ?? []).filter(isProductDisplayed);
      }
    }
  );
};
