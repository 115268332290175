import { useCallback } from "react";
import { notification, NotificationList } from "melco-ui";
import { useTranslation } from "react-i18next";
import { TOptions } from "i18next";
import castArray from "lodash-es/castArray";

type TranslationOptions = {
  title?: TOptions;
  message?: TOptions;
};

export const useErrorNotification = () => {
  const { t } = useTranslation();

  return useCallback(
    (
      title: string,
      message: string | string[],
      translationOptions?: TranslationOptions
    ) => {
      const messages = castArray(message);

      const description =
        messages.length > 1 ? (
          <NotificationList>
            {messages.map((message) => (
              <li key={message}>
                {t(message, translationOptions?.message ?? {})}
              </li>
            ))}
          </NotificationList>
        ) : (
          t(message, translationOptions?.message ?? {})
        );

      notification.error({
        message: t(title, translationOptions?.title ?? {}),
        description,
        duration: 8,
      });
    },
    [t]
  );
};
