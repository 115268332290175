import AntdInputNumber, {
  InputNumberProps as AntdInputNumberProps,
} from "antd/es/input-number";
import styled from "styled-components";

const StyledInputNumber = styled(AntdInputNumber)`
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export type InputNumberProps = AntdInputNumberProps;

const InputNumber = StyledInputNumber;

export { InputNumber };
