/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCalculatorModelsJob
 */
export interface MelcoCalculatorModelsJob {
    /**
     * 
     * @type {string}
     * @memberof MelcoCalculatorModelsJob
     */
    referenceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCalculatorModelsJob
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    orders?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    pieces?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    stitches?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    colors?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    colorChanges?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    trims?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    coneChanges?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    garmentHandling?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    hooping?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    otherWork?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsJob
     */
    usedMachineId?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoCalculatorModelsJob
     */
    lastModifiedOn?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsJob
     */
    globalSettings?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsJob
     */
    recalculate?: boolean;
}

export function MelcoCalculatorModelsJobFromJSON(json: any): MelcoCalculatorModelsJob {
    return MelcoCalculatorModelsJobFromJSONTyped(json, false);
}

export function MelcoCalculatorModelsJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCalculatorModelsJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referenceId': !exists(json, 'referenceId') ? undefined : json['referenceId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'orders': !exists(json, 'orders') ? undefined : json['orders'],
        'pieces': !exists(json, 'pieces') ? undefined : json['pieces'],
        'stitches': !exists(json, 'stitches') ? undefined : json['stitches'],
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'colorChanges': !exists(json, 'colorChanges') ? undefined : json['colorChanges'],
        'trims': !exists(json, 'trims') ? undefined : json['trims'],
        'coneChanges': !exists(json, 'coneChanges') ? undefined : json['coneChanges'],
        'garmentHandling': !exists(json, 'garmentHandling') ? undefined : json['garmentHandling'],
        'hooping': !exists(json, 'hooping') ? undefined : json['hooping'],
        'otherWork': !exists(json, 'otherWork') ? undefined : json['otherWork'],
        'usedMachineId': !exists(json, 'usedMachineId') ? undefined : json['usedMachineId'],
        'lastModifiedOn': !exists(json, 'lastModifiedOn') ? undefined : (new Date(json['lastModifiedOn'])),
        'globalSettings': !exists(json, 'globalSettings') ? undefined : json['globalSettings'],
        'recalculate': !exists(json, 'recalculate') ? undefined : json['recalculate'],
    };
}

export function MelcoCalculatorModelsJobToJSON(value?: MelcoCalculatorModelsJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referenceId': value.referenceId,
        'name': value.name,
        'orders': value.orders,
        'pieces': value.pieces,
        'stitches': value.stitches,
        'colors': value.colors,
        'colorChanges': value.colorChanges,
        'trims': value.trims,
        'coneChanges': value.coneChanges,
        'garmentHandling': value.garmentHandling,
        'hooping': value.hooping,
        'otherWork': value.otherWork,
        'usedMachineId': value.usedMachineId,
        'lastModifiedOn': value.lastModifiedOn === undefined ? undefined : (value.lastModifiedOn.toISOString()),
        'globalSettings': value.globalSettings,
        'recalculate': value.recalculate,
    };
}


