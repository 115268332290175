import { Button, Modal } from "melco-ui";
import { useTranslation } from "react-i18next";
import { PasswordResetData } from "../../hooks/administration/useResetPassword";
import { PasswordResetLoginData } from "./PasswordResetLoginData";

type PasswordResetConfirmationModalProps = {
  passwordResetData: PasswordResetData | undefined;
  onConfirm: () => void;
};

export const PasswordResetConfirmationModal: React.FC<
  PasswordResetConfirmationModalProps
> = ({ passwordResetData, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("users.details.login.username.resetPassword.title")}
      open={Boolean(passwordResetData)}
      footer={
        <Button onClick={onConfirm}>
          {t("confirm.ok", { ns: "melco-common" })}
        </Button>
      }
      onCancel={onConfirm}
    >
      <PasswordResetLoginData passwordResetData={passwordResetData} />
    </Modal>
  );
};
