import { useTranslation } from "react-i18next";
import { FlowPageTitle } from "melco-ui";
import MelcoAccountFlowLayout from "../../components/layout/MelcoAccountFlowLayout";
import { UpdatePasswordForm } from "../../components/updatepassword/UpdatePasswordForm";

export const UpdatePassword: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MelcoAccountFlowLayout>
      <FlowPageTitle>{t("updatepasswordpage.title")}</FlowPageTitle>

      <UpdatePasswordForm />
    </MelcoAccountFlowLayout>
  );
};
