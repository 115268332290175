import {
  Drawer as DrawerType,
  MelcoCoreModelsDRMUserSerialNumber,
} from "melco-shared-logic";
import { Drawer } from "melco-ui";
import { useTranslation } from "react-i18next";
import { AssignUserList } from "./AssignUserList";

type AssignUserDrawerProps = {
  license: MelcoCoreModelsDRMUserSerialNumber;
  drawer: DrawerType;
};

export const AssignUserDrawer: React.FC<AssignUserDrawerProps> = ({
  drawer,
  license,
}) => {
  const { t } = useTranslation();
  const { closeDrawer, isDrawerOpen } = drawer;

  return (
    <Drawer
      title={t("infrastructureLicenses.actions.assign.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      visible={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
      destroyOnClose
    >
      <AssignUserList drawer={drawer} license={license} />
    </Drawer>
  );
};
