import { Button, CheckIcon, Col, Table, TableActionBarRow } from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  MelcoCoreModelsDRMUserSerialNumber,
  Search,
  ViewContext,
  Drawer as DrawerType,
  MelcoCoreModelsUsersSubscriptionUserList,
  TableCellLink,
} from "melco-shared-logic";
import { UsersListColumns } from "../administration/UsersList";
import { AssignLicenseButton } from "./AssignLicenseButton";
import { useLicensesUsers } from "../../hooks/infrastructure/useLicensesUsers";

type AssignUserListProps = {
  license: MelcoCoreModelsDRMUserSerialNumber;
  drawer: DrawerType;
};

const LICENSE_DETAIL_VIEW_PATH = "/infrastructure/licenses";

export const AssignUserList: React.FC<AssignUserListProps> = ({
  license,
  drawer,
}) => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
  } = useLicensesUsers(true);

  const { t } = useTranslation();

  const columns = [
    ...UsersListColumns(t, ViewContext.Drawer),
    {
      title: t("infrastructureLicenses.assign.list.license_name"),
      key: "licenseName",
      dataIndex: "license_name",
      render: (_: string, user: MelcoCoreModelsUsersSubscriptionUserList) => {
        if (!user.license_id) {
          return null;
        }
        return (
          <TableCellLink
            to={[LICENSE_DETAIL_VIEW_PATH, user.license_id].join("/")}
          >
            {user.license_name ?? user.license_id}
          </TableCellLink>
        );
      },
    },
    {
      title: t("users.list.columns.actions"),
      key: "actions",
      dataIndex: "actions",
      sorter: false,
      render: (_: string, user: MelcoCoreModelsUsersSubscriptionUserList) => {
        return user.license_id ? (
          <Button type="primary" icon={<CheckIcon />} size="small" disabled>
            {t("infrastructureLicenses.actions.assign.actions.assigned")}
          </Button>
        ) : (
          <AssignLicenseButton
            userId={user.id!}
            licenseId={license.id!}
            drawer={drawer}
          />
        );
      },
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col xs={24} xl={17}>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        noDataMessage={t("users.list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
