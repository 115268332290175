import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  DisplayFormErrors,
  InputField,
  VerticalForm,
} from "melco-shared-logic";
import { Button, PlusIcon } from "melco-ui";
import { SelectAPIKeyProductField } from "./SelectAPIKeyProductField";
import { SelectAPIKeyScopeField } from "./SelectAPIKeyScopeField";

type DisplayCreateAPIKeyFormProps = {
  isLoading: boolean;
};

export const DisplayCreateAPIKeyForm: React.FC<
  DisplayCreateAPIKeyFormProps
> = ({ isLoading }) => {
  const { t } = useTranslation();

  const { handleSubmit } = useFormikContext();

  return (
    <VerticalForm>
      <DisplayFormErrors />

      <InputField
        name="name"
        label={t("api_list.actions.add.form.label.name")}
      />

      <SelectAPIKeyProductField />

      <SelectAPIKeyScopeField />

      <Button
        key="submit-create-api-form"
        type="primary"
        icon={<PlusIcon />}
        disabled={isLoading}
        loading={isLoading}
        onClick={() => handleSubmit()}
      >
        {t("api_list.actions.add.button")}
      </Button>
    </VerticalForm>
  );
};
