import { useCallback, useState } from "react";
import {
  Drawer as DrawerType,
  DrawerFooterArea,
  MelcoCoreModelsUsersSubUserDetail,
} from "melco-shared-logic";
import { Drawer, FullWidthSpace, message, Steps } from "melco-ui";
import { useTranslation } from "react-i18next";
import { AddUserForm } from "./AddUserForm";
import { SetPermissionsForm } from "./SetPermissionsForm";
import { AddedUserInfo } from "./AddedUserInfo";

export type AddUserSteps = "addUser" | "setPermissions" | "finish";

const stepToIndexMapping = {
  addUser: 0,
  setPermissions: 1,
  finish: 2,
};

type AddUserDrawerProps = {
  drawer: DrawerType;
};

export const AddUserDrawer: React.FC<AddUserDrawerProps> = ({ drawer }) => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState<AddUserSteps>("addUser");

  const [addedUser, setAddedUser] = useState<
    MelcoCoreModelsUsersSubUserDetail | undefined
  >(undefined);

  const { closeDrawer, isDrawerOpen } = drawer;

  const closeAndReset = useCallback(() => {
    closeDrawer();
    setCurrentStep("addUser");
    setAddedUser(undefined);
  }, [closeDrawer, setCurrentStep, setAddedUser]);

  const afterPermissionsSaved = useCallback(() => {
    message.success(t("users.details.permissions.success.default"));
    setCurrentStep("finish");
  }, [t, setCurrentStep]);

  return (
    <Drawer
      title={t("users.actions.add.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeAndReset}
      visible={isDrawerOpen}
      footer={<DrawerFooterArea />}
    >
      <FullWidthSpace direction="vertical">
        <Steps
          items={[
            {
              title: t("users.actions.add.steps.addUser"),
            },
            {
              title: t("users.actions.add.steps.setPermissions"),
            },
            {
              title: t("users.actions.add.steps.finish"),
            },
          ]}
          current={stepToIndexMapping[currentStep]}
          labelPlacement="vertical"
          size="small"
        />

        {currentStep === "addUser" && (
          <AddUserForm
            drawer={drawer}
            setCurrentStep={setCurrentStep}
            setAddedUser={setAddedUser}
          />
        )}

        {currentStep === "setPermissions" && (
          <SetPermissionsForm
            user={addedUser}
            afterPermissionsSaved={afterPermissionsSaved}
          />
        )}

        {currentStep === "finish" && (
          <AddedUserInfo addedUser={addedUser} closeAndReset={closeAndReset} />
        )}
      </FullWidthSpace>
    </Drawer>
  );
};
