import { useTranslation } from "react-i18next";
import { PageContentWrapper, PageTitle } from "melco-ui";
import { ActivateSupportForm } from "../../components/support/ActivateSupportForm";
import { useSupportStatus } from "../../hooks/support/useSupportStatus";
import { DeactivateSupport } from "../../components/support/DeactivateSupport";

const AccountSupport: React.FC = () => {
  const { t } = useTranslation();

  const { data: supportStatus, isLoading, refetch } = useSupportStatus();

  if (isLoading || !supportStatus) {
    return null;
  }

  const { isActive } = supportStatus;

  return (
    <PageContentWrapper narrow>
      <PageTitle>{t("accountsupportpage.title")}</PageTitle>

      {isActive ? (
        <DeactivateSupport refetch={refetch} supportStatus={supportStatus} />
      ) : (
        <ActivateSupportForm refetch={refetch} />
      )}
    </PageContentWrapper>
  );
};

export default AccountSupport;
