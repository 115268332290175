import {
  MelcoCoreModelsUsersServiceUser,
  MelcoCoreModelsUsersSubUserDetail,
  useAPIQuery,
  UserAccountApi,
} from "melco-shared-logic";

export const useUserPermissions = (
  user: MelcoCoreModelsUsersServiceUser | MelcoCoreModelsUsersSubUserDetail
) => {
  const userId = user.id;

  return useAPIQuery(UserAccountApi, ["permissions", userId], async (api) => {
    if (userId == null) {
      return;
    }

    return api.userAccountGetAllPermissions({ userId });
  });
};
