import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { AuthenticationApi } from "../../api/apis/AuthenticationApi";
import { useBaseAPIConfig } from "../api/useAPIConfig";
import { useAuth } from "./useAuth";
import { useSupportToken } from "./useSupportToken";
import { useTokenValidityCheck } from "./useTokenValidityCheck";
import { useAppStartup } from "../product/useAppStartup";
import { parseAPIErrorResponse } from "../../helper/errorHandling/parseAPIErrorResponse";
import {
  isTrialError,
  redirectAfterTrialError,
} from "../../helper/trial/trialHelper";

export type MelcoAccountData = {
  token: string;
  userId: string;
};

export const MELCO_ACCOUNT_KEY = "MELCO_ACCOUNT";

export enum TokenRestoreState {
  Restoring,
  RestoredSuccessfully,
  RestoreFailed,
}

type TokenRestoreOptions = {
  skipRedirectOnStartupError?: boolean;
  skipTermsHandlingOnStartup?: boolean;
};

export const useTokenRestore = (options?: TokenRestoreOptions) => {
  const [tokenRestoreState, setTokenRestoreState] = useState(
    TokenRestoreState.Restoring
  );
  const auth = useAuth();
  const [melcoAccountData, setMelcoAccountData, removeMelcoAccountData] =
    useLocalStorage<MelcoAccountData>(MELCO_ACCOUNT_KEY);
  const baseApiConfig = useBaseAPIConfig();
  const checkTokenValidity = useTokenValidityCheck();
  const loginViaSupportToken = useSupportToken();
  const appStartup = useAppStartup({
    skipRedirectOnError: options?.skipRedirectOnStartupError,
    skipTermsHandling: options?.skipTermsHandlingOnStartup,
  });

  // try to restore token on load
  useEffect(
    () => {
      const restoreToken = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const supportToken = searchParams.get("token");

        if (supportToken) {
          const { isValidToken, user } = await loginViaSupportToken(
            supportToken
          );

          if (isValidToken && user) {
            const {
              eMail,
              token,
              userId,
              firstName,
              lastName,
              accountName,
              parentUserId,
              impersonationForId,
            } = user;

            const {
              success: startupSuccess,
              permissions,
              defaultLanguage,
            } = await appStartup(token, impersonationForId);

            //If the startup wasnt successful, we immediatly abort.
            if (!startupSuccess) {
              return;
            }

            auth.changeCurrentUser({
              eMail,
              token,
              userId,
              firstName,
              lastName,
              accountName,
              parentUserId,
              impersonationForId,
              permissions,
              defaultLanguage,
            });

            setTokenRestoreState(TokenRestoreState.RestoredSuccessfully);
            return;
          }
        }

        // load token from local storage
        let tryToRefreshToken = true;

        if (melcoAccountData) {
          try {
            const { token, userId } = melcoAccountData;

            const {
              email,
              first_name: firstName,
              last_name: lastName,
              account_name: accountName,
              parent_user_id: parentUserId,
            } = await checkTokenValidity(token, userId);

            const {
              success: startupSuccess,
              permissions,
              defaultLanguage,
            } = await appStartup(token);

            //If the startup wasnt successful, we immediatly abort.
            if (!startupSuccess) {
              tryToRefreshToken = false;
              return;
            }

            auth.changeCurrentUser({
              eMail: email ?? "",
              token: token ?? "",
              userId: userId ?? "",
              firstName: firstName ?? "",
              lastName: lastName ?? "",
              accountName: accountName ?? "",
              parentUserId: parentUserId ?? undefined,
              permissions,
              defaultLanguage,
            });

            tryToRefreshToken = false;
            setTokenRestoreState(TokenRestoreState.RestoredSuccessfully);
            return;
          } catch (e) {
            console.error(e);
            removeMelcoAccountData();
          }
        }

        if (tryToRefreshToken) {
          try {
            const api = new AuthenticationApi(
              baseApiConfig({
                includeCredentials: true,
                skipRedirectToLoginIfUnauthorized: true,
              })
            );

            const {
              first_name: firstName,
              last_name: lastName,
              token,
              user_id: userId,
              account_name: accountName,
              parent_user_id: parentUserId,
            } = await api.authenticationMelcoRefresh();

            const {
              success: startupSuccess,
              permissions,
              defaultLanguage,
            } = await appStartup(token!);

            //If the startup wasnt successful, we immediatly abort.
            if (!startupSuccess) {
              return;
            }

            auth.changeCurrentUser({
              eMail: "",
              token: token ?? "",
              userId: userId ?? "",
              firstName: firstName ?? "",
              lastName: lastName ?? "",
              accountName: accountName ?? "",
              parentUserId: parentUserId ?? undefined,
              permissions,
              defaultLanguage,
            });

            if (token && userId) {
              setMelcoAccountData({ token, userId });
              setTokenRestoreState(TokenRestoreState.RestoredSuccessfully);
              return;
            }
          } catch (e) {
            console.error(e);

            const apiErrors = await parseAPIErrorResponse(e);

            if (apiErrors && isTrialError(apiErrors)) {
              redirectAfterTrialError();
              return;
            }
          }
        }

        setTokenRestoreState(TokenRestoreState.RestoreFailed);
      };

      restoreToken();
    },
    // explicitly want to run this only once on bootup
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return tokenRestoreState;
};
