import { useTranslation } from "react-i18next";
import { ContentHeader, MainContentArea } from "melco-ui";
import { useBreadcrumb } from "../../hooks/navigation/useBreadcrumb";
import { useGenericDrawer } from "melco-shared-logic";
import { LicensesList } from "../../components/infrastructure/LicensesList";
import { AddLicenseDrawer } from "../../components/infrastructure/AddLicenseDrawer";
import { AddLicenseButton } from "../../components/infrastructure/AddLicenseButton";

export const Licenses: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("infrastructure");

  const addLicenseDrawer = useGenericDrawer();
  const { openDrawer } = addLicenseDrawer;

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("infrastructureLicenses.title")}
          breadcrumb={breadcrumb}
          extra={<AddLicenseButton onClick={openDrawer} />}
        />
      }
    >
      <LicensesList />
      <AddLicenseDrawer drawer={addLicenseDrawer} />
    </MainContentArea>
  );
};
