/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCalculatorModelsCalculation
 */
export interface MelcoCalculatorModelsCalculation {
    /**
     * 
     * @type {string}
     * @memberof MelcoCalculatorModelsCalculation
     */
    Id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    heads?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCalculatorModelsCalculation
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsCalculation
     */
    multihead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsCalculation
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsCalculation
     */
    recommended?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    efficiency?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    working_hours?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    fixed_hourly_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    machine_hourly_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    production_hourly_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_pieces?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_stitches?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_setup_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    head_setup_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    stitch_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    trim_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    colorchange_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    machine_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    avg_speed_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    pieces_produced_during_setup?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    first_design_machine_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    stitches_during_setup_calc1?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    stitches_during_setup_calc2?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    stitches_during_setup?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    stitches_after_setup?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_stitch_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_trim_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_colorchange_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_machine_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    average_machine_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    average_idle_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    average_machine_time_per_head?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_bobbinchange_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_threadbreak_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_hoopchange_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_idle_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_down_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    working_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_working_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    effective_machine_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_production_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    production_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    total_production_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    production_cost_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    optimal_heads?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    optimal_total_production_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    optimal_production_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    optimal_total_production_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    optimal_production_cost_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    readonly potential_total_production_time?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    readonly potential_production_time_per_piece?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    readonly potential_total_production_cost?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsCalculation
     */
    readonly potential_production_cost_per_piece?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCalculatorModelsCalculation
     */
    performance_cost?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCalculatorModelsCalculation
     */
    performance_time?: string | null;
}

export function MelcoCalculatorModelsCalculationFromJSON(json: any): MelcoCalculatorModelsCalculation {
    return MelcoCalculatorModelsCalculationFromJSONTyped(json, false);
}

export function MelcoCalculatorModelsCalculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCalculatorModelsCalculation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Id': !exists(json, 'Id') ? undefined : json['Id'],
        'heads': !exists(json, 'heads') ? undefined : json['heads'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'multihead': !exists(json, 'multihead') ? undefined : json['multihead'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'recommended': !exists(json, 'recommended') ? undefined : json['recommended'],
        'efficiency': !exists(json, 'efficiency') ? undefined : json['efficiency'],
        'working_hours': !exists(json, 'working_hours') ? undefined : json['working_hours'],
        'fixed_hourly_cost': !exists(json, 'fixed_hourly_cost') ? undefined : json['fixed_hourly_cost'],
        'machine_hourly_cost': !exists(json, 'machine_hourly_cost') ? undefined : json['machine_hourly_cost'],
        'production_hourly_cost': !exists(json, 'production_hourly_cost') ? undefined : json['production_hourly_cost'],
        'total_pieces': !exists(json, 'total_pieces') ? undefined : json['total_pieces'],
        'total_stitches': !exists(json, 'total_stitches') ? undefined : json['total_stitches'],
        'total_setup_time': !exists(json, 'total_setup_time') ? undefined : json['total_setup_time'],
        'head_setup_time': !exists(json, 'head_setup_time') ? undefined : json['head_setup_time'],
        'stitch_time_per_piece': !exists(json, 'stitch_time_per_piece') ? undefined : json['stitch_time_per_piece'],
        'trim_time_per_piece': !exists(json, 'trim_time_per_piece') ? undefined : json['trim_time_per_piece'],
        'colorchange_time_per_piece': !exists(json, 'colorchange_time_per_piece') ? undefined : json['colorchange_time_per_piece'],
        'machine_time_per_piece': !exists(json, 'machine_time_per_piece') ? undefined : json['machine_time_per_piece'],
        'avg_speed_per_piece': !exists(json, 'avg_speed_per_piece') ? undefined : json['avg_speed_per_piece'],
        'pieces_produced_during_setup': !exists(json, 'pieces_produced_during_setup') ? undefined : json['pieces_produced_during_setup'],
        'first_design_machine_time': !exists(json, 'first_design_machine_time') ? undefined : json['first_design_machine_time'],
        'stitches_during_setup_calc1': !exists(json, 'stitches_during_setup_calc1') ? undefined : json['stitches_during_setup_calc1'],
        'stitches_during_setup_calc2': !exists(json, 'stitches_during_setup_calc2') ? undefined : json['stitches_during_setup_calc2'],
        'stitches_during_setup': !exists(json, 'stitches_during_setup') ? undefined : json['stitches_during_setup'],
        'stitches_after_setup': !exists(json, 'stitches_after_setup') ? undefined : json['stitches_after_setup'],
        'total_stitch_time': !exists(json, 'total_stitch_time') ? undefined : json['total_stitch_time'],
        'total_trim_time': !exists(json, 'total_trim_time') ? undefined : json['total_trim_time'],
        'total_colorchange_time': !exists(json, 'total_colorchange_time') ? undefined : json['total_colorchange_time'],
        'total_machine_time': !exists(json, 'total_machine_time') ? undefined : json['total_machine_time'],
        'average_machine_time': !exists(json, 'average_machine_time') ? undefined : json['average_machine_time'],
        'average_idle_time': !exists(json, 'average_idle_time') ? undefined : json['average_idle_time'],
        'average_machine_time_per_head': !exists(json, 'average_machine_time_per_head') ? undefined : json['average_machine_time_per_head'],
        'total_bobbinchange_time': !exists(json, 'total_bobbinchange_time') ? undefined : json['total_bobbinchange_time'],
        'total_threadbreak_time': !exists(json, 'total_threadbreak_time') ? undefined : json['total_threadbreak_time'],
        'total_hoopchange_time': !exists(json, 'total_hoopchange_time') ? undefined : json['total_hoopchange_time'],
        'total_idle_time': !exists(json, 'total_idle_time') ? undefined : json['total_idle_time'],
        'total_down_time': !exists(json, 'total_down_time') ? undefined : json['total_down_time'],
        'working_time_per_piece': !exists(json, 'working_time_per_piece') ? undefined : json['working_time_per_piece'],
        'total_working_time': !exists(json, 'total_working_time') ? undefined : json['total_working_time'],
        'effective_machine_time': !exists(json, 'effective_machine_time') ? undefined : json['effective_machine_time'],
        'total_production_time': !exists(json, 'total_production_time') ? undefined : json['total_production_time'],
        'production_time_per_piece': !exists(json, 'production_time_per_piece') ? undefined : json['production_time_per_piece'],
        'total_production_cost': !exists(json, 'total_production_cost') ? undefined : json['total_production_cost'],
        'production_cost_per_piece': !exists(json, 'production_cost_per_piece') ? undefined : json['production_cost_per_piece'],
        'optimal_heads': !exists(json, 'optimal_heads') ? undefined : json['optimal_heads'],
        'optimal_total_production_time': !exists(json, 'optimal_total_production_time') ? undefined : json['optimal_total_production_time'],
        'optimal_production_time_per_piece': !exists(json, 'optimal_production_time_per_piece') ? undefined : json['optimal_production_time_per_piece'],
        'optimal_total_production_cost': !exists(json, 'optimal_total_production_cost') ? undefined : json['optimal_total_production_cost'],
        'optimal_production_cost_per_piece': !exists(json, 'optimal_production_cost_per_piece') ? undefined : json['optimal_production_cost_per_piece'],
        'potential_total_production_time': !exists(json, 'potential_total_production_time') ? undefined : json['potential_total_production_time'],
        'potential_production_time_per_piece': !exists(json, 'potential_production_time_per_piece') ? undefined : json['potential_production_time_per_piece'],
        'potential_total_production_cost': !exists(json, 'potential_total_production_cost') ? undefined : json['potential_total_production_cost'],
        'potential_production_cost_per_piece': !exists(json, 'potential_production_cost_per_piece') ? undefined : json['potential_production_cost_per_piece'],
        'performance_cost': !exists(json, 'performance_cost') ? undefined : json['performance_cost'],
        'performance_time': !exists(json, 'performance_time') ? undefined : json['performance_time'],
    };
}

export function MelcoCalculatorModelsCalculationToJSON(value?: MelcoCalculatorModelsCalculation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.Id,
        'heads': value.heads,
        'name': value.name,
        'multihead': value.multihead,
        'active': value.active,
        'recommended': value.recommended,
        'efficiency': value.efficiency,
        'working_hours': value.working_hours,
        'fixed_hourly_cost': value.fixed_hourly_cost,
        'machine_hourly_cost': value.machine_hourly_cost,
        'production_hourly_cost': value.production_hourly_cost,
        'total_pieces': value.total_pieces,
        'total_stitches': value.total_stitches,
        'total_setup_time': value.total_setup_time,
        'head_setup_time': value.head_setup_time,
        'stitch_time_per_piece': value.stitch_time_per_piece,
        'trim_time_per_piece': value.trim_time_per_piece,
        'colorchange_time_per_piece': value.colorchange_time_per_piece,
        'machine_time_per_piece': value.machine_time_per_piece,
        'avg_speed_per_piece': value.avg_speed_per_piece,
        'pieces_produced_during_setup': value.pieces_produced_during_setup,
        'first_design_machine_time': value.first_design_machine_time,
        'stitches_during_setup_calc1': value.stitches_during_setup_calc1,
        'stitches_during_setup_calc2': value.stitches_during_setup_calc2,
        'stitches_during_setup': value.stitches_during_setup,
        'stitches_after_setup': value.stitches_after_setup,
        'total_stitch_time': value.total_stitch_time,
        'total_trim_time': value.total_trim_time,
        'total_colorchange_time': value.total_colorchange_time,
        'total_machine_time': value.total_machine_time,
        'average_machine_time': value.average_machine_time,
        'average_idle_time': value.average_idle_time,
        'average_machine_time_per_head': value.average_machine_time_per_head,
        'total_bobbinchange_time': value.total_bobbinchange_time,
        'total_threadbreak_time': value.total_threadbreak_time,
        'total_hoopchange_time': value.total_hoopchange_time,
        'total_idle_time': value.total_idle_time,
        'total_down_time': value.total_down_time,
        'working_time_per_piece': value.working_time_per_piece,
        'total_working_time': value.total_working_time,
        'effective_machine_time': value.effective_machine_time,
        'total_production_time': value.total_production_time,
        'production_time_per_piece': value.production_time_per_piece,
        'total_production_cost': value.total_production_cost,
        'production_cost_per_piece': value.production_cost_per_piece,
        'optimal_heads': value.optimal_heads,
        'optimal_total_production_time': value.optimal_total_production_time,
        'optimal_production_time_per_piece': value.optimal_production_time_per_piece,
        'optimal_total_production_cost': value.optimal_total_production_cost,
        'optimal_production_cost_per_piece': value.optimal_production_cost_per_piece,
        'performance_cost': value.performance_cost,
        'performance_time': value.performance_time,
    };
}


