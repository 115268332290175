import { useTranslation } from "react-i18next";
import { Paragraph } from "melco-ui";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import AccountsServiceTermModal from "../serviceterms/AccountsServiceTermModal";

type LinkToServiceTermsProps = {
  translationPrefix: string;
};

export const LinkToServiceTerms: React.FC<LinkToServiceTermsProps> = ({
  translationPrefix,
}) => {
  const { t } = useTranslation();

  const [isTermsModalShown, setTermsModalShown] = useState(false);

  const showDefaultServiceTerms = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      setTermsModalShown(true);
    },
    [setTermsModalShown]
  );

  return (
    <Paragraph size="small" alignment="center" type="secondary">
      {t(`${translationPrefix}.terms.text` as any)}{" "}
      <Link to="#" onClick={showDefaultServiceTerms}>
        {t(`${translationPrefix}.terms.link` as any)}
      </Link>
      <AccountsServiceTermModal
        isShown={isTermsModalShown}
        show={setTermsModalShown}
      />
    </Paragraph>
  );
};
