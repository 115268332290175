import styled from "styled-components";
import { MelcoCoreModelsUserProductListItem } from "melco-shared-logic";
import { DesignTokens } from "melco-ui";
import DemoProduct from "./DemoProduct";

const { spacings } = DesignTokens;

const DemoProductsWrapper = styled.div`
  margin: ${spacings.middle}px -${spacings.middle}px ${spacings.xlarge}px -${spacings.middle}px;
`;

type DemoProductsProps = {
  products: MelcoCoreModelsUserProductListItem[];
};

const DemoProducts: React.FC<DemoProductsProps> = ({ products }) => {
  return (
    <DemoProductsWrapper>
      {products.map((product) => (
        <DemoProduct key={product.code} product={product} />
      ))}
    </DemoProductsWrapper>
  );
};

export default DemoProducts;
