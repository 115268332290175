import styled from "styled-components";
import { MelcoCoreModelsUserProductListItem } from "melco-shared-logic";
import { DesignTokens } from "melco-ui";
import AppIcon from "./AppIcon";

const { spacings } = DesignTokens;

const AppIconsWrapper = styled.div`
  margin: ${spacings.middle}px -${spacings.middle}px ${spacings.xlarge}px -${spacings.middle}px;
`;

type AppIconsProps = {
  products: MelcoCoreModelsUserProductListItem[];
};

const AppIcons: React.FC<AppIconsProps> = ({ products }) => {
  return (
    <AppIconsWrapper>
      {products.map((product) => (
        <AppIcon key={product.code} product={product} />
      ))}
    </AppIconsWrapper>
  );
};

export default AppIcons;
