import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import {
  ContentHeader,
  MainContentArea,
  Tabs,
  TabsProps,
  ValueOrFallback,
} from "melco-ui";
import trim from "lodash-es/trim";
import { useBreadcrumb } from "../../hooks/navigation/useBreadcrumb";
import { useUser } from "../../hooks/administration/useUser";
import { UserMoreActionButton } from "../../components/administration/UserMoreActionButton";
import { UserLogin } from "../../components/administration/UserLogin";
import { UserProfile } from "../../components/administration/UserProfile";
import { UserPermissions } from "../../components/administration/UserPermissions";

export const UserDetails: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("administration", "administrationUsers");

  const { userId } = useParams<{
    userId: string;
  }>();

  const { data: user } = useUser(userId);

  const title = trim([user?.first_name, user?.last_name].join(" "));

  const tabItems: TabsProps["items"] = [
    {
      key: "profile",
      label: t("users.details.profile.title"),
      children: user && <UserProfile user={user} />,
    },
    {
      key: "login",
      label: t("users.details.login.title"),
      children: user && <UserLogin user={user} />,
    },
    {
      key: "permissions",
      label: t("users.details.permissions.title"),
      children: user && <UserPermissions user={user} />,
    },
  ];

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={
            <ValueOrFallback
              value={title}
              fallback={t("global.fallback.empty")}
            />
          }
          breadcrumb={breadcrumb}
          extra={<>{user && <UserMoreActionButton user={user} />}</>}
        />
      }
      contentPadding
    >
      <Tabs items={tabItems} />
    </MainContentArea>
  );
};
