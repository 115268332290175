import { useState } from "react";

export type VerificationState = {
  eMail: string | undefined;
  setEMail: React.Dispatch<React.SetStateAction<string | undefined>>;
  code: string | undefined;
  setCode: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const useVerificationState: () => VerificationState = () => {
  const [eMail, setEMail] = useState<string | undefined>();
  const [code, setCode] = useState<string | undefined>();

  return {
    eMail,
    setEMail,
    code,
    setCode,
  };
};
