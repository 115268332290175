import React from "react";
import Col from "antd/es/col";
import Row, { RowProps as AntdRowProps } from "antd/es/row";
import castArray from "lodash-es/castArray";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

const StyledRow = styled(Row)``;

const GRID_COLS = 24;

// FIXME: Ensure that the spacing is correct even if the FormRow is used as a last item before a FormSection

export type FormRowProps = {
  children?: React.ReactNode;
} & Omit<AntdRowProps, "align" | "wrap" | "gutter">;

export const FormRow: React.FC<FormRowProps> = ({ justify, children }) => {
  const amountOfChildren = castArray(children).length;

  return (
    <StyledRow gutter={[spacings.middle, 0]} justify={justify}>
      {castArray(children).map((child, idx) => (
        <Col
          key={idx}
          xs={GRID_COLS}
          md={Math.max(8, GRID_COLS / amountOfChildren)}
        >
          {child}
        </Col>
      ))}
    </StyledRow>
  );
};
