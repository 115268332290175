import ReactDOM from "react-dom";
import { DRAWER_FOOTER_AREA_DOM_ID } from "./DrawerFooterArea";

type DrawerFooterContentProps = {
  children: React.ReactNode;
};

export const DrawerFooterContent: React.FC<DrawerFooterContentProps> = ({
  children,
}) => {
  const domElement = document.getElementById(DRAWER_FOOTER_AREA_DOM_ID);

  if (!domElement) {
    return null;
  }

  return ReactDOM.createPortal(children, domElement);
};
