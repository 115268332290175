import { PropsWithChildren } from "react";
import styled from "styled-components";
import { InfoIcon } from "../icons/icons";

const InfoTextWrapper = styled.div`
  margin-top: 6px;
`;

export const FormItemInfo: React.FC<PropsWithChildren> = ({ children }) => (
  <InfoTextWrapper>
    <InfoIcon /> {children}
  </InfoTextWrapper>
);
