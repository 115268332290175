import styled, { css } from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";
import { Elevated } from "../appearance/Elevated";

const { colors, spacings, borders } = DesignTokens;

const ActionArea = styled.div.withConfig({
  shouldForwardProp: (prop) => !["positionY", "positionX"].includes(prop),
})<CanvasActionGroupProps>`
  background-color: ${colors.main.negative};
  position: absolute;
  z-index: 20;

  /* default is bottom center */
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  ${({ positionY }) =>
    positionY === "top" &&
    css`
      && {
        top: 0;
        bottom: auto;
      }
    `}

  ${({ positionX }) =>
    positionX === "left" &&
    css`
      && {
        left: ${spacings.xlarge}px;
        transform: none;
      }
    `}

  ${({ positionX }) =>
    positionX === "right" &&
    css`
      && {
        left: auto;
        right: ${spacings.xlarge}px;
        transform: none;
      }
    `}
`;

const HorizontalInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;

  /* Style for elements in the action group */
  > * {
    border-right: ${borders.width.normal}px solid ${colors.gray.middle};

    &:last-child {
      border-right: 0;
    }
  }
`;

const VerticalInner = styled(HorizontalInner)`
  width: 30px;
  height: auto;
  flex-direction: column;

  > * {
    border-right: 0;
    border-bottom: ${borders.width.normal}px solid ${colors.gray.middle};

    &:last-child {
      border-bottom: 0;
    }
  }
`;

type CanvasActionGroupProps = {
  direction?: "horizontal" | "vertical";
  positionY?: "top" | "bottom";
  positionX?: "left" | "center" | "right";
  children?: React.ReactNode;
};

export const CanvasActionGroup: React.FC<CanvasActionGroupProps> = ({
  direction = "horizontal",
  positionY = "bottom",
  positionX = "center",
  children,
}) => (
  <ActionArea positionX={positionX} positionY={positionY}>
    <Elevated level={2}>
      {direction === "horizontal" ? (
        <HorizontalInner>{children}</HorizontalInner>
      ) : (
        <VerticalInner>{children}</VerticalInner>
      )}
    </Elevated>
  </ActionArea>
);
