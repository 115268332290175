import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useUserLanguage } from "../user/useUserLanguage";

export const useSetApplicationLanguage = () => {
  const { i18n } = useTranslation();
  const language = useUserLanguage();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, i18n.changeLanguage]);
};
