import { useTranslation } from "react-i18next";
import { Paragraph, FlowBoxButton, FlowBoxTitle } from "melco-ui";

const CreateAccountBox = () => {
  const { t } = useTranslation();

  return (
    <>
      <FlowBoxTitle>{t("loginpage.noaccount.title")}</FlowBoxTitle>

      <Paragraph>{t("loginpage.noaccount.text")}</Paragraph>

      <FlowBoxButton href="/register">
        {t("loginpage.noaccount.link")}
      </FlowBoxButton>
    </>
  );
};

export default CreateAccountBox;
