import { useNavigate, useParams } from "react-router-dom";
import { useProducts } from "../../hooks/product/useProducts";
import { find } from "lodash-es";
import TrialExpiredModal from "../../components/trial/TrialExpiredModal";
import { useCallback } from "react";

export const TrialExpiredScreen: React.FC = () => {
  const navigate = useNavigate();

  const { productCode } = useParams<{ productCode: string }>();
  const { data: products } = useProducts();

  // fetch product
  const product = find(
    products ?? [],
    (p) => p.code?.toUpperCase() === productCode?.toUpperCase()
  );

  const onOk = useCallback(() => {
    navigate("/dashboard", { replace: true });
  }, [navigate]);

  if (!product) {
    return null;
  }

  return <TrialExpiredModal product={product} onOk={onOk} open />;
};
