import { useAPIQuery, UserAccountApi } from "melco-shared-logic";

export const useLicense = (licenseId: string | undefined) => {
  return useAPIQuery(UserAccountApi, ["license", licenseId], async (api) => {
    if (licenseId == null) {
      return;
    }

    return api.subscriptionGet({ userSubscriptionId: licenseId });
  });
};
