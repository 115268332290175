import { useTranslation } from "react-i18next";
import { FlowBox, FlowPageTitle, FlowBoxTitle, FullWidthSpace } from "melco-ui";
import { RegisterForm } from "../../components/register/RegisterForm";
import { VerificationState } from "melco-shared-logic";
import { LinkToServiceTerms } from "../../components/setpassword/LinkToServiceTerms";

type RegisterProps = {
  verificationState: VerificationState;
};

const Register: React.FC<RegisterProps> = ({ verificationState }) => {
  const { t } = useTranslation();

  return (
    <>
      <FlowPageTitle>{t("registrationpage.title")}</FlowPageTitle>

      <FlowBox>
        <FlowBoxTitle>{t("registrationpage.box_title")}</FlowBoxTitle>

        <FullWidthSpace direction="vertical" size="middle">
          <RegisterForm verificationState={verificationState} />

          <LinkToServiceTerms translationPrefix="registrationpage" />
        </FullWidthSpace>
      </FlowBox>
    </>
  );
};

export default Register;
