import {
  SupportApi,
  useAPIQuery,
  useRequirePermissions,
} from "melco-shared-logic";

export type SupportStatus = {
  activatedOn?: Date;
  isActive?: boolean;
  minutes?: number;
};

export const useSupportStatus = () => {
  const isAllowed = useRequirePermissions(["GRANT_ACCESS"]);

  return useAPIQuery(SupportApi, ["supportStatus"], async (api) => {
    if (!isAllowed) {
      return;
    }

    const response = await api.supportStatus();

    const { activated_on, minutes, is_active } = response;

    return {
      activatedOn: activated_on,
      minutes,
      isActive: is_active,
    };
  });
};
