import styled from "styled-components";
import AntdMenu from "antd/es/menu";
import { DesignTokens } from "../../styles/design-tokens";
import { ElevationHelper } from "../../helper/ElevationHelper";

const { colors, borders } = DesignTokens;
const { createBoxShadowCSSString } = ElevationHelper;

const SubMenu = styled(AntdMenu.SubMenu)`
  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      box-shadow: ${createBoxShadowCSSString({ level: 1, bottom: true })};
    }
    ul {
      border-bottom: ${borders.width.normal}px solid ${colors.border.light};
    }
  }
`;

export { SubMenu };
