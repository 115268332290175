import { ReactNode } from "react";
import AntdForm from "antd/es/form";
import {
  FormItemProps as AntdFormItemProps,
  ValidateStatus as FormValidateStatus,
} from "antd/es/form/FormItem";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";
import { FormItemInfo } from "./FormItemInfo";

const { spacings } = DesignTokens;

type FormItemProps = AntdFormItemProps & {
  info?: string | ReactNode;
};

const StyledFormItem = styled(AntdForm.Item)`
  margin-bottom: ${spacings.middle}px;

  .ant-form-item-label {
    padding-bottom: ${spacings.xsmall}px;
  }
`;

export const FormItem: React.FC<FormItemProps> = ({ info, ...rest }) => (
  <StyledFormItem
    extra={info && <FormItemInfo>{info}</FormItemInfo>}
    {...rest}
  />
);

export type { FormValidateStatus };
