import { Formik } from "formik";
import {
  useFormSubmit,
  Drawer as DrawerType,
  UserAccountApi,
  MelcoCoreModelsUsersSubUserDetail,
  useAuthenticatedAPIConfig,
} from "melco-shared-logic";
import { useQueryClient } from "react-query";
import { object, string } from "yup";
import { EmailAvailabilityState } from "../register/EmailFieldWithAvailabilityCheck";
import { AddUserSteps } from "./AddUserDrawer";
import { DisplayAddUserForm } from "./DisplayAddUserForm";

type AddUserFormValueType = {
  user_name: string;
  first_name: string;
  last_name: string;
  country_code: string;
  is_email: boolean;
  isEmailAvailable: EmailAvailabilityState;
};

const initialValues: AddUserFormValueType = {
  user_name: "",
  first_name: "",
  last_name: "",
  country_code: "",
  is_email: true,
  isEmailAvailable: EmailAvailabilityState.Unknown,
};

type AddUserFormProps = {
  drawer: DrawerType;
  setCurrentStep: (newStep: AddUserSteps) => void;
  setAddedUser: (addedUser: MelcoCoreModelsUsersSubUserDetail) => void;
};

export const AddUserForm: React.FC<AddUserFormProps> = ({
  setCurrentStep,
  setAddedUser,
}) => {
  const AddUserFormSchema = object().shape({
    user_name: string()
      .when("is_email", {
        is: true,
        then: (schema) => schema.email("global.error.shouldbe.email"),
      })
      .test(
        "isNotTaken",
        "registrationpage.error.user_name_exists",
        (_, context) => {
          return context.parent.is_email
            ? context.parent.isEmailAvailable !== EmailAvailabilityState.Taken
            : true;
        }
      ),
  });

  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const queryClient = useQueryClient();

  const onSubmit = useFormSubmit<AddUserFormValueType>(
    async (values: AddUserFormValueType) => {
      const { user_name, first_name, last_name, country_code, is_email } =
        values;

      const api = new UserAccountApi(authenticatedApiConfig());

      const user = await api.userAccountCreate({
        melcoCoreModelsUsersSubUser: {
          user_name,
          first_name,
          last_name,
          address: {
            country_code,
          },
          is_email,
        },
      });

      setAddedUser(user);

      return { successMessages: [{ message: "default" }] };
    },
    {
      translationPrefix: "users.actions.add",
      onSuccess: (_, { resetForm }) => {
        queryClient.invalidateQueries(["users"]);
        resetForm({ values: initialValues });
        setCurrentStep("setPermissions");
      },
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddUserFormSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <DisplayAddUserForm />
    </Formik>
  );
};
