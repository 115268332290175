import { Button, CheckIcon } from "melco-ui";
import { Drawer as DrawerType } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { useAssignLicense } from "../../hooks/infrastructure/useAssignLicense";
import { useQueryClient } from "react-query";

type AssignLicenseButtonProps = {
  licenseId: string;
  userId: string;
  drawer: DrawerType;
};

export const AssignLicenseButton: React.FC<AssignLicenseButtonProps> = ({
  licenseId,
  userId,
  drawer,
}) => {
  const { t } = useTranslation();
  const { closeDrawer } = drawer;

  const [assignLicense, isLoading] = useAssignLicense(licenseId, userId);

  const queryClient = useQueryClient();

  return (
    <Button
      type="primary"
      icon={<CheckIcon />}
      size="small"
      onClick={async () => {
        const license = await assignLicense();

        if (license) {
          queryClient.invalidateQueries(["license", licenseId]);
          queryClient.invalidateQueries(["licenseHistory", licenseId]);
          closeDrawer();
        }
      }}
      loading={isLoading}
    >
      {t("infrastructureLicenses.actions.assign.actions.assign")}
    </Button>
  );
};
