import { Button } from "melco-ui";
import { useTranslation } from "react-i18next";

type SkipAddAccountNameButtonProps = {
  onClick: () => void;
};

const SkipAddAccountNameButton: React.FC<SkipAddAccountNameButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Button onClick={onClick}>{t("dashboard.add_account_name.cancel")}</Button>
  );
};

export default SkipAddAccountNameButton;
