import { useState } from "react";
import { CurrentUser } from "../user/useCurrentUser";

export const useAuthProvider = () => {
  const [currentUser, changeCurrentUser] = useState<CurrentUser | undefined>(
    undefined
  );

  const [isCurrentlyTryingToRestoreToken, setIsCurrentlyTryingToRestoreToken] =
    useState(true);

  return {
    currentUser,
    changeCurrentUser,
    isCurrentlyTryingToRestoreToken,
    setIsCurrentlyTryingToRestoreToken,
  };
};
