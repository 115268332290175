import { Button } from "melco-ui";
import { useTranslation } from "react-i18next";

type TrialActiveModalOkButtonProps = {
  onClick: () => void;
};

const TrialActiveModalOkButton: React.FC<TrialActiveModalOkButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Button type="primary" onClick={onClick}>
      {t("trial.active.ok")}
    </Button>
  );
};

export default TrialActiveModalOkButton;
