import { Formik } from "formik";
import {
  useBaseAPIConfig,
  useFlowFormSubmit,
  UserAccountApi,
} from "melco-shared-logic";
import { FlowPageTitle } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { object, ref, string } from "yup";
import MelcoAccountFlowLayout from "../layout/MelcoAccountFlowLayout";
import { DisplaySetPasswordForm } from "./DisplaySetPasswordForm";

type SetPasswordFormValueType = {
  new_password: string;
  confirm_password: string;
};

const SetPasswordFormSchema = object().shape({
  new_password: string().required("global.error.required.default"),
  confirm_password: string()
    .oneOf([ref("new_password")], "global.error.match.password")
    .required("global.error.required.default"),
});

type SetPasswordProps = {
  code: string;
  username: string;
};

export const SetPassword: React.FC<SetPasswordProps> = ({ code, username }) => {
  const { t } = useTranslation();
  const baseApiConfig = useBaseAPIConfig();

  const navigate = useNavigate();

  const initialValues: SetPasswordFormValueType = {
    new_password: "",
    confirm_password: "",
  };

  const onSubmit = useFlowFormSubmit(
    async (values: SetPasswordFormValueType) => {
      const { new_password } = values;

      const api = new UserAccountApi(baseApiConfig());

      const response = await api.userAccountSetPassword({
        melcoCoreModelsUsersSetPassword: {
          user_name: username,
          code,
          new_password,
          accept_service_terms: true,
        },
      });

      if (response.result) {
        const searchParams = new URLSearchParams();
        searchParams.set("eMail", username);
        searchParams.set("type", "subuser");

        navigate(`/register/verify?${searchParams}`);
      } else {
        throw new Error();
      }
    },
    {
      translationPrefix: "setpasswordpage",
    }
  );

  return (
    <MelcoAccountFlowLayout>
      <FlowPageTitle>{t("setpasswordpage.title")}</FlowPageTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={SetPasswordFormSchema}
        onSubmit={onSubmit}
      >
        <DisplaySetPasswordForm />
      </Formik>
    </MelcoAccountFlowLayout>
  );
};
