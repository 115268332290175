import dayjs from "dayjs";

import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/it";
import "dayjs/locale/fr";
import "dayjs/locale/sv";
import "dayjs/locale/nl";
import "dayjs/locale/nn";
import "dayjs/locale/pt";
import "dayjs/locale/es";

import localizedFormat from "dayjs/plugin/localizedFormat";

import { useEffect } from "react";
import { useApplicationLanguage } from "./useApplicationLanguage";

dayjs.extend(localizedFormat);

export const useSetDayjsLocale = () => {
  const language = useApplicationLanguage();

  useEffect(() => {
    dayjs.locale(language);
  }, [language]);
};
