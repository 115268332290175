import React from "react";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";
import { ContentHeader, ContentHeaderProps } from "../layout/ContentHeader";
import { Drawer, DrawerProps } from "./Drawer";

const { spacings } = DesignTokens;

const StyledDrawer = styled(Drawer)<DrawerProps>`
  .ant-drawer-header {
    border-bottom: 0; /*Reset border bottom due its handled within the ContentHeader. Border not handled via less variable.*/

    .ant-drawer-close {
      /*The icon should be aligned with the title*/
      bottom: 4px;
      top: auto;
    }

    padding: 0; /*Reset padding for header*/
  }
`;

const StyledContentHeader = styled(ContentHeader)`
  && {
    /*Higher specifity for this styling needed*/
    padding: ${spacings.middle}px ${spacings.large}px; /*Set correct padding for page header to match content of drawer*/
    min-height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .ant-page-header-heading-left {
      margin: 0;
    }
  }
`;

//Make sure breadcrumb is picked from ContentHeader and title is mandatory
type MelcoProps = Pick<ContentHeaderProps, "breadcrumb"> & {
  title: ContentHeaderProps["title"];
};

export type DrawerWithHeaderProps = Omit<DrawerProps, "title"> & MelcoProps;

const DrawerWithHeader: React.FC<DrawerWithHeaderProps> = ({
  title,
  breadcrumb,
  ...rest
}) => (
  <StyledDrawer
    {...rest}
    title={<StyledContentHeader title={title} breadcrumb={breadcrumb} />}
  />
);

export { DrawerWithHeader };
