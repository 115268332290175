import { MouseEventHandler, useCallback, useState } from "react";
import { Key, TableRowSelection } from "antd/lib/table/interface";

// TODO: Expand to pass additional props to rowSelection
export const useTableSelection = <T>(
  onRowClick: (row: T) => void,
  rowSelectionProps?: TableRowSelection<T>
) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const onSelectChange = useCallback(
    (selectedRowKeys: Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    [setSelectedRowKeys]
  );

  const onRow = useCallback(
    (row: T) => {
      const onClick: MouseEventHandler<HTMLElement> = (e) => {
        const clickedElement = e.target as HTMLDivElement;
        const isClickOnSelectionColumn = clickedElement.classList.contains(
          "ant-table-selection-column"
        );

        if (isClickOnSelectionColumn) {
          // forward click to checkbox
          const selectionCheckbox =
            clickedElement.querySelector<HTMLLabelElement>("input");

          if (selectionCheckbox) {
            selectionCheckbox.click();
          }
        } else {
          onRowClick(row);
        }
      };

      return {
        onClick,
      };
    },
    [onRowClick]
  );

  const rowSelection = {
    ...rowSelectionProps,
    selectedRowKeys,
    onChange: onSelectChange,
  } as TableRowSelection<T>;

  return {
    selectedRowKeys,
    setSelectedRowKeys,
    rowSelection,
    onRow,
  };
};
