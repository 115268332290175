import { Tabs } from "melco-ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  ServiceTerm,
  useServiceTerm,
} from "../../hooks/serviceterms/useServiceTerm";

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 50vh;
  border: 0;
`;

const idForServiceTerm = (serviceTerm: ServiceTerm) => {
  return serviceTerm.tags;
};

type LegalTextProps = {
  productCode: string;
};

const ServiceTermContent: React.FC<LegalTextProps> = ({ productCode }) => {
  const { t } = useTranslation();
  const { serviceTerms, isLoading } = useServiceTerm(productCode);

  if (isLoading) {
    return <p>Loading…</p>;
  }

  const tabItems = serviceTerms.map((serviceTerm) => ({
    label: t(`dashboard.apps.legal_policy.tags.${serviceTerm.tags}` as any),
    key: idForServiceTerm(serviceTerm),
    children: (
      <div id={idForServiceTerm(serviceTerm)}>
        {serviceTerm.type === "url" ? (
          <StyledIFrame src={serviceTerm.htmlContent} />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: serviceTerm.htmlContent,
            }}
          />
        )}
      </div>
    ),
  }));

  return <Tabs items={tabItems} destroyInactiveTabPane />;
};

export default ServiceTermContent;
