import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import isEmpty from "lodash-es/isEmpty";
import { SelectField, useCountries } from "melco-shared-logic";
import { SizeType } from "../../types/layout";

type CountrySelectProps = {
  name: string;
  disabled?: boolean;
  label?: string;
  size?: SizeType;
};

const CountrySelect: React.FC<CountrySelectProps> = ({
  name,
  disabled,
  label,
  size,
}) => {
  const { t } = useTranslation();
  const { countries, isLoading } = useCountries();
  const [field, , helpers] = useField("country_code");

  useEffect(() => {
    if (isLoading) {
      return;
    }

    // select US by default
    if (isEmpty(field.value)) {
      helpers.setValue("US");
    }
  }, [isLoading, field, helpers]);

  return (
    <SelectField
      name={name}
      label={label}
      size={size}
      placeholder={t("global.form.label.profile.country_code")}
      options={countries.map((c) => {
        return {
          label: c.name,
          value: c.code,
        };
      })}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      disabled={disabled || isLoading}
    />
  );
};

export default CountrySelect;
