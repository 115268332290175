import {
  MelcoCoreModelsUsersServiceUser,
  MelcoCoreModelsUsersSubUserDetail,
  useAPIAction,
  UserAccountApi,
} from "melco-shared-logic";
import { useCallback, useState } from "react";

export const useResendInvite = () => {
  const [isResendingForUserId, setResendingForUserId] = useState<
    string | undefined
  >();

  const [resendInvite] = useAPIAction(
    UserAccountApi,
    async (api, userId: string) => {
      setResendingForUserId(userId);

      try {
        const data = await api.userAccountResendInvite({ id: userId });

        const { result } = data;

        if (!result) {
          throw new Error("Could not send invite");
        }

        return {
          successMessages: [{ message: "default" }],
          data,
        };
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        setResendingForUserId(undefined);
      }
    },
    {
      translationPrefix: "users.actions.invite",
    }
  );

  const isResendAvailable = useCallback(
    ({
      is_email,
      verified_on,
    }: MelcoCoreModelsUsersServiceUser | MelcoCoreModelsUsersSubUserDetail) =>
      Boolean(is_email) && !verified_on,

    []
  );

  return [isResendAvailable, resendInvite, isResendingForUserId] as const;
};
