import { MelcoCoreModelsPermissionsListItem } from "melco-shared-logic";
import { Title } from "melco-ui";
import { DerivedPermission } from "./DerivedPermission";
import { ManagePermission } from "./ManagePermission";

type ManagePermissionGroupProps = {
  productCode: string;
  permission: MelcoCoreModelsPermissionsListItem;
  allPermissions: MelcoCoreModelsPermissionsListItem[];
};

export const ManagePermissionGroup: React.FC<ManagePermissionGroupProps> = ({
  productCode,
  permission,
  allPermissions,
}) => {
  const childPermissions = allPermissions.filter(
    (p) => p.parent_code === permission.code
  );

  const { name, code } = permission;

  const hasChildren = childPermissions.length > 0;

  return hasChildren ? (
    <div>
      <Title level={5}>{name ?? code ?? ""}</Title>

      <DerivedPermission
        productCode={productCode}
        permission={permission}
        childPermissions={childPermissions}
      />

      {childPermissions.map((p) => (
        <ManagePermission
          key={[productCode, p.code].join()}
          productCode={productCode}
          permission={p}
        />
      ))}
    </div>
  ) : (
    <div>
      <Title level={5}>{name ?? code ?? ""}</Title>

      <ManagePermission productCode={productCode} permission={permission} />
    </div>
  );
};
