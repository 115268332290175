import styled, { css } from "styled-components";
import { DesignTokens } from "../../../styles/design-tokens";
import { ColorSwatch, ColorSwatchProps } from "./ColorSwatch";

const { spacings, colors } = DesignTokens;

const StyledColorSwatchLabel = styled.div<{ isGrayedOut: boolean }>`
  margin-left: ${spacings.small}px;

  ${({ isGrayedOut }) => css`
    ${isGrayedOut && `color: ${colors.gray.middle}`};
  `}
`;

const StyledColorSwatchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type LabeledColorSwatchProps = {
  label: React.ReactNode;
};

const LabeledColorSwatch: React.FC<
  LabeledColorSwatchProps & ColorSwatchProps
> = ({ argb, label, ...rest }) => (
  <StyledColorSwatchWrapper>
    <ColorSwatch {...rest} argb={argb} />
    <StyledColorSwatchLabel isGrayedOut={!argb}>{label}</StyledColorSwatchLabel>
  </StyledColorSwatchWrapper>
);

export { LabeledColorSwatch };
