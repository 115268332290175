import { useTranslation } from "react-i18next";
import { FlowPageTitle, FlowBox, Space } from "melco-ui";
import MelcoAccountFlowLayout from "../../components/layout/MelcoAccountFlowLayout";
import CreateAccountBox from "../../components/login/CreateAccountBox";
import { LoginForm } from "../../components/login/LoginForm";
import { useRegistrationInvite } from "../../hooks/login/useRegistrationInvite";
import { SetPassword } from "../../components/setpassword/SetPassword";

const Login = () => {
  const { t } = useTranslation();

  const registrationInvite = useRegistrationInvite();

  if (registrationInvite) {
    return (
      <SetPassword
        code={registrationInvite.code}
        username={registrationInvite.username}
      />
    );
  }

  return (
    <MelcoAccountFlowLayout>
      <FlowPageTitle>{t("loginpage.title")}</FlowPageTitle>

      <Space direction="vertical" size="xlarge">
        <LoginForm />

        <FlowBox isLight>
          <CreateAccountBox />
        </FlowBox>
      </Space>
    </MelcoAccountFlowLayout>
  );
};

export default Login;
