import React from "react";
import styled, { css } from "styled-components";
import { DesignTokens } from "../../../styles/design-tokens";

const { colors, spacings, borders } = DesignTokens;

const StyledFlowBox = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isLight"].includes(prop),
})<FlowBoxProps>`
  padding: ${spacings.xlarge}px;
  background-color: ${colors.gray.light};
  border-radius: ${borders.radius.normal}px;

  ${({ isLight }) => css`
    ${isLight &&
    `
    background-color: ${colors.gray.superLight}; 
    border: 1px solid ${colors.gray.light};`}
  `}
`;

export type FlowBoxProps = {
  isLight?: boolean;
  children?: React.ReactNode;
};

const FlowBox: React.FC<FlowBoxProps> = (props) => <StyledFlowBox {...props} />;

export { FlowBox };
