import { MelcoCoreModelsUsersServiceUser } from "melco-shared-logic";
import { UserLoginEmail } from "./UserLoginEmail";
import { UserLoginUsername } from "./UserLoginUsername";

type UserLoginProps = {
  user: MelcoCoreModelsUsersServiceUser;
};

export const UserLogin: React.FC<UserLoginProps> = ({ user }) => {
  const { is_email } = user;

  return is_email ? (
    <UserLoginEmail user={user} />
  ) : (
    <UserLoginUsername user={user} />
  );
};
