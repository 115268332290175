import { SystemManagementApi, useAPIQuery } from "melco-shared-logic";

export const useAPIKeyScopes = (productCode: string) => {
  return useAPIQuery(
    SystemManagementApi,
    ["scopes", productCode],
    async (api) => {
      if (!productCode) {
        return [];
      }

      const result = await api.productsScopes({ productCodeList: productCode });

      return result.list ?? [];
    }
  );
};
