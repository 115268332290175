import {
  DebugForm,
  DisplayFormErrors,
  InputField,
  VerticalForm,
} from "melco-shared-logic";
import { FullWidthSpace } from "melco-ui";
import { useTranslation } from "react-i18next";

export const DisplayLicenseDetailForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <VerticalForm>
      <DisplayFormErrors />

      <FullWidthSpace direction="vertical" size="middle">
        <InputField
          name="display_name"
          label={t("infrastructureLicenses.form.label.display_name")}
          info={t("infrastructureLicenses.form.info.display_name")}
        />
      </FullWidthSpace>

      {false && <DebugForm />}
    </VerticalForm>
  );
};
