import { useTranslation } from "react-i18next";
import { FlowBoxButton } from "melco-ui";
import { Form, FormikProps } from "formik";
import { InputField } from "melco-shared-logic";
import { ChangeEMailFormValueType } from "./ChangeEMailForm";
import { EmailFieldWithAvailabilityCheck } from "./EmailFieldWithAvailabilityCheck";

const DisplayChangeEMailForm: React.FC<
  FormikProps<ChangeEMailFormValueType>
> = ({ isSubmitting }) => {
  const { t } = useTranslation();

  return (
    <Form>
      <InputField
        name="user_name"
        size="large"
        placeholder={t("global.form.label.profile.email_current")}
        disabled
      />

      <EmailFieldWithAvailabilityCheck
        name="new_user_name"
        placeholder={t("global.form.label.profile.email_new")}
        size="large"
      />

      <FlowBoxButton
        loading={isSubmitting}
        disabled={isSubmitting}
        htmlType="submit"
      >
        {t("registrationchangeemailpage.form.submit")}
      </FlowBoxButton>
    </Form>
  );
};

export default DisplayChangeEMailForm;
