import { Formik } from "formik";
import * as Yup from "yup";
import {
  useAuthenticatedAPIConfig,
  useCurrentUser,
  useFormSubmit,
  UserManagementApi,
} from "melco-shared-logic";
import { DisplayChangePasswordForm } from "./DisplayChangePasswordForm";

export type ChangePasswordFormValueType = {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
};

const ChangePasswordFormSchema = Yup.object().shape({
  current_password: Yup.string().required("global.error.required.default"),
  new_password: Yup.string().required("global.error.required.default"),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref("new_password")], "global.error.match.password")
    .required("global.error.required.default"),
});

export const ChangePasswordForm: React.FC = () => {
  const currentUser = useCurrentUser();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const onSubmit = useFormSubmit<ChangePasswordFormValueType>(
    async (values: ChangePasswordFormValueType) => {
      const { current_password, new_password } = values;

      const api = new UserManagementApi(authenticatedApiConfig());

      const success = await api.userChangePassword({
        id: currentUser?.userId ?? "",
        melcoCoreModelsUsersChangePassword: {
          current_password,
          new_password,
        },
      });

      if (success) {
        return { successMessages: [{ message: "saved" }] };
      } else {
        throw new Error();
      }
    },
    {
      translationPrefix: "accountloginpage.tab_2",
      onSuccess: (_, { resetForm }) => {
        resetForm({ values: initialValues });
      },
    }
  );

  if (!currentUser) {
    return null;
  }

  const initialValues: ChangePasswordFormValueType = {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangePasswordFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => <DisplayChangePasswordForm {...props} />}
    </Formik>
  );
};
