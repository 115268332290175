import { Modal, Space } from "melco-ui";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import TrialModalInfoButton from "./TrialModalInfoButton";
import {
  isTrialExpired,
  isTrialProduct,
  MelcoCoreModelsUserProductListItem,
} from "melco-shared-logic";
import TrialExpiredModalOkButton from "./TrialExpiredModalOkButton";

type TrialExpiredModalProps = {
  product: MelcoCoreModelsUserProductListItem;
  open?: boolean;
  onOk: () => void;
};

const TrialExpiredModal: React.FC<TrialExpiredModalProps> = ({
  product,
  open,
  onOk,
}) => {
  const { t } = useTranslation();

  const { code, expires_on, info_url } = product;

  if (!isTrialProduct(product)) {
    return null;
  }

  if (!isTrialExpired(product)) {
    return null;
  }

  const wrapClassName = `modal-trial-expired-${code}`;

  return (
    <Modal
      data-cy-id="modal-trial-expired"
      title={t("trial.expired.title")}
      open={open}
      centered
      closable={false}
      destroyOnClose
      wrapClassName={wrapClassName}
      footer={[<TrialExpiredModalOkButton key="ok" onClick={onOk} />]}
    >
      <Space direction="vertical" size="small">
        {t("trial.expired.message", { date: dayjs(expires_on).format("L") })}
        {info_url && <TrialModalInfoButton info_url={info_url} />}
      </Space>
    </Modal>
  );
};

export default TrialExpiredModal;
