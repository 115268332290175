import compact from "lodash-es/compact";
import { DesignTokens } from "../styles/design-tokens";

const { elevations } = DesignTokens;

export type ElevationLevels = 1 | 2 | 3;

//Accept level 1 - 3, rename to ElevationHelper
type sidedBoxShadowCSSStringParams = {
  level: ElevationLevels;
  left?: boolean;
  right?: boolean;
  bottom?: boolean;
};

const createSidedElevationString = (
  level: sidedBoxShadowCSSStringParams["level"],
  side: "left" | "right" | "bottom",
  elevated?: boolean
) => {
  if (elevated) {
    return elevations.sided[side][`level${level}` as "level1" | "level2"]
      .shadow;
  }
};

const createBoxShadowCSSString = ({
  level,
  left,
  right,
  bottom,
}: sidedBoxShadowCSSStringParams): string => {
  //If no side is given: Global shadow
  if (!left && !right && !bottom) {
    return elevations[`level${level}` as "level1" | "level2"].shadow;
  }
  //Otherwise: Sided shadow
  const sidedElevationStrings = [
    createSidedElevationString(level, "left", left),
    createSidedElevationString(level, "right", right),
    createSidedElevationString(level, "bottom", bottom),
  ];
  return compact(sidedElevationStrings).join(",");
};

const ElevationHelper = {
  createBoxShadowCSSString,
};

export { ElevationHelper };
