import { useParams } from "react-router";
import { LicenseDetails } from "./LicenseDetails";

export const LicenseDetailsRoute: React.FC = () => {
  const { licenseId } = useParams<{
    licenseId: string;
  }>();

  if (!licenseId) {
    return null;
  }

  return <LicenseDetails key={licenseId} licenseId={licenseId} />;
};
