import React from "react";
import AntdTag, { TagProps as AntdTagProps } from "antd/es/tag";
import styled, { css } from "styled-components";

export type TagProps = AntdTagProps;

const StyledTag = styled(AntdTag)<TagProps>`
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  ${(props) =>
    props.icon &&
    css`
      & > :not(.anticon):first-child {
        margin-right: 7px; /*Apply to the first element of tag a margin right, if its not an element with .anticon*/
      }
      img {
        max-width: 16px;
        max-height: 16px;
      }
    `}
`;

const Tag: React.FC<TagProps> = (props) => <StyledTag {...props} />;

export { Tag };
