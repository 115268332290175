import {
  useBaseAPIConfig,
  useFlowFormSubmit,
  UserManagementApi,
  VerificationState,
} from "melco-shared-logic";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { DisplayVerifyCodeForm } from "./DisplayVerifyCodeForm";

export type VerifyCodeFormValueType = {
  code: string;
};

const initialValues: VerifyCodeFormValueType = {
  code: "      ",
};

const VerifyCodeFormSchema = Yup.object().shape({
  code: Yup.string().length(6).required("global.error.required.default"),
});

export type VerifyCodeFormProps = {
  verificationState: VerificationState;
  process: "register" | "forgotPassword";
  children?: React.ReactNode;
};

export const VerifyCodeForm: React.FC<VerifyCodeFormProps> = (props) => {
  const { verificationState, process } = props;
  const { eMail, setCode } = verificationState;
  const navigate = useNavigate();
  const baseApiConfig = useBaseAPIConfig();

  const onSubmit = useFlowFormSubmit(
    async (values: VerifyCodeFormValueType) => {
      const { code } = values;

      const api = new UserManagementApi(baseApiConfig());

      if (process === "forgotPassword") {
        const response = await api.userVerifyForgotPwd({
          username: eMail,
          code,
        });

        if (response.result) {
          setCode(code);
          navigate("/forgotpassword/reset");
          return;
        }
      } else {
        const response = await api.userVerify({
          username: eMail,
          code,
        });

        if (response.result) {
          setCode(code);
          navigate("/?registration-success");
          return;
        }
      }
    },
    {
      translationPrefix:
        process === "forgotPassword"
          ? "forgotpasswordverifypage"
          : "registrationverifypage",
      onError: (_, actions) => {
        // reset the code input fields in case the user entered a wrong code (last parameter is false to avoid triggering an validation that would remove the error message)
        actions.setFieldValue("code", "      ", false);
      },
    }
  );

  if (!eMail || !setCode) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VerifyCodeFormSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => <DisplayVerifyCodeForm {...formikProps} {...props} />}
    </Formik>
  );
};
