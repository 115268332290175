import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { MainContentArea } from "melco-ui";
import {
  RequireAuth,
  RequirePermissions,
  useSetApplicationLanguage,
  useTokenRestore,
  useVerificationState,
} from "melco-shared-logic";

import Login from "./screens/login/Login";
import Error404 from "./screens/404/Error404";
import Register from "./screens/register/Register";
import VerifyRegistration from "./screens/register/VerifyRegistration";
import ChangeEMail from "./screens/register/ChangeEMail";
import ForgotPassword from "./screens/forgotpassword/ForgotPassword";
import VerifyCode from "./screens/forgotpassword/VerifyCode";
import ResetPassword from "./screens/forgotpassword/ResetPassword";
import { Dashboard } from "./screens/dashboard/Dashboard";
import AccountProfile from "./screens/account/AccountProfile";
import AccountLogin from "./screens/account/AccountLogin";
import AccountSupport from "./screens/account/AccountSupport";
import ServiceTermModal from "./components/serviceterms/ServiceTermModal";
import MelcoAccountFlowLayout from "./components/layout/MelcoAccountFlowLayout";
import MelcoAccountApplicationLayout from "./components/layout/MelcoAccountApplicationLayout";
import { Users } from "./screens/administration/Users";
import { UserDetails } from "./screens/administration/UserDetails";
import { UpdatePassword } from "./screens/login/UpdatePassword";
import { Licenses } from "./screens/infrastructure/Licenses";
import { BasicSettings } from "./screens/settings/BasicSettings";
import { LicenseDetailsRoute } from "./screens/infrastructure/LicenseDetailsRoute";
import { APIKeys } from "./screens/settings/APIKeys";
import { TrialExpiredScreen } from "./screens/dashboard/TrialExpiredScreen";

export const Main: React.FC = () => {
  const tokenRestoreState = useTokenRestore({
    skipRedirectOnStartupError: true,
    skipTermsHandlingOnStartup: true,
  });
  const verificationState = useVerificationState();

  useSetApplicationLanguage();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="dashboard"
          element={
            <RequireAuth tokenRestoreState={tokenRestoreState}>
              <MelcoAccountApplicationLayout>
                <MainContentArea contentPadding>
                  <Dashboard />
                </MainContentArea>
              </MelcoAccountApplicationLayout>
            </RequireAuth>
          }
        >
          <Route path="" />
          <Route path="terms/:productCode" element={<ServiceTermModal />} />
          <Route path="trial/:productCode" element={<TrialExpiredScreen />} />
        </Route>

        <Route
          path="infrastructure"
          element={
            <RequireAuth tokenRestoreState={tokenRestoreState}>
              <RequirePermissions
                permissions={["INFRASTRUCTURE", "MANAGE_LICENSES"]}
                otherwise={<Error404 />}
              >
                <MelcoAccountApplicationLayout>
                  <Outlet />
                </MelcoAccountApplicationLayout>
              </RequirePermissions>
            </RequireAuth>
          }
        >
          <Route path="" element={<Navigate to="licenses" replace />} />
          <Route path="licenses" element={<Licenses />} />
          <Route path="licenses/:licenseId" element={<LicenseDetailsRoute />} />
        </Route>

        <Route
          path="administration"
          element={
            <RequireAuth tokenRestoreState={tokenRestoreState}>
              <RequirePermissions
                permissions={["ADMINISTRATION", "MANAGE_USERS"]}
                otherwise={<Error404 />}
              >
                <MelcoAccountApplicationLayout>
                  <Outlet />
                </MelcoAccountApplicationLayout>
              </RequirePermissions>
            </RequireAuth>
          }
        >
          <Route path="" element={<Navigate to="users" replace />} />
          <Route path="users" element={<Users />} />
          <Route path="users/:userId" element={<UserDetails />} />
        </Route>

        <Route
          path="account"
          element={
            <RequireAuth tokenRestoreState={tokenRestoreState}>
              <MelcoAccountApplicationLayout>
                <MainContentArea contentPadding>
                  <Outlet />
                </MainContentArea>
              </MelcoAccountApplicationLayout>
            </RequireAuth>
          }
        >
          <Route path="profile" element={<AccountProfile />} />
          <Route path="login" element={<AccountLogin />} />
          <Route
            path="support"
            element={
              <RequirePermissions
                permissions={["GRANT_ACCESS"]}
                otherwise={<Error404 />}
              >
                <AccountSupport />
              </RequirePermissions>
            }
          />
        </Route>

        <Route
          path="settings"
          element={
            <RequireAuth tokenRestoreState={tokenRestoreState}>
              <MelcoAccountApplicationLayout>
                <Outlet />
              </MelcoAccountApplicationLayout>
            </RequireAuth>
          }
        >
          <Route path="" element={<Navigate to="basic" replace />} />
          <Route path="basic" element={<BasicSettings />} />
          <Route
            path="api"
            element={
              <RequirePermissions
                permissions={["API_KEYS"]}
                otherwise={<Error404 />}
              >
                <APIKeys />
              </RequirePermissions>
            }
          />
        </Route>

        <Route
          path="register"
          element={
            <MelcoAccountFlowLayout>
              <Outlet />
            </MelcoAccountFlowLayout>
          }
        >
          <Route
            path=""
            element={<Register verificationState={verificationState} />}
          />
          <Route
            path="verify"
            element={
              <VerifyRegistration verificationState={verificationState} />
            }
          />
          <Route
            path={"changeemail"}
            element={<ChangeEMail verificationState={verificationState} />}
          />
        </Route>

        <Route
          path="forgotpassword"
          element={
            <MelcoAccountFlowLayout>
              <Outlet />
            </MelcoAccountFlowLayout>
          }
        >
          <Route
            path=""
            element={<ForgotPassword verificationState={verificationState} />}
          />
          <Route
            path="verify"
            element={<VerifyCode verificationState={verificationState} />}
          />
          <Route
            path="reset"
            element={<ResetPassword verificationState={verificationState} />}
          />
        </Route>

        <Route path="updatepassword" element={<UpdatePassword />} />

        <Route path="login" element={<Login />} />
        <Route path="" element={<Login />} />

        <Route element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};
