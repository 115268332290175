import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, FormItem, FormValidateStatus } from "melco-ui";
import { Field, useField } from "formik";
import { useIsEmailAvailable } from "melco-shared-logic";
import trim from "lodash-es/trim";
import isEmpty from "lodash-es/isEmpty";
import useDebounce from "react-use/lib/useDebounce";

export enum EmailAvailabilityState {
  Unknown = "Unknown",
  Loading = "Loading",
  Taken = "Taken",
  Available = "Available",
}

type EmailFieldWithAvailabilityCheckProps = {
  name: string;
  placeholder?: string;
  label?: string;
  size?: string;
};

export const EmailFieldWithAvailabilityCheck: React.FC<
  EmailFieldWithAvailabilityCheckProps
> = ({ name, placeholder, label, size }) => {
  const { t } = useTranslation();
  const isEmailAvailable = useIsEmailAvailable();

  const [{ value: eMail }, { touched, error }] = useField<string>(name);

  const [debouncedEMail, setDebouncedEMail] = useState("");

  // debounce to avoid calling the endpoint too often when the user is typing
  useDebounce(
    () => {
      setDebouncedEMail(eMail);
    },
    1000,
    [eMail]
  );

  const [
    { value: eMailAvailabilityState },
    ,
    { setValue: setEmailAvailability },
  ] = useField<EmailAvailabilityState>("isEmailAvailable");

  useEffect(() => {
    const trimmedEMail = trim(debouncedEMail);

    if (isEmpty(trimmedEMail)) {
      return;
    }

    const checkEmailAvailability = async (eMail: string) => {
      setEmailAvailability(EmailAvailabilityState.Loading);

      const isAvailable = await isEmailAvailable(eMail);

      setEmailAvailability(
        isAvailable
          ? EmailAvailabilityState.Available
          : EmailAvailabilityState.Taken
      );
    };

    checkEmailAvailability(trimmedEMail);
  }, [debouncedEMail]);

  let validateStatus: FormValidateStatus = "";

  if (touched && error) {
    validateStatus = "error";
  } else if (eMailAvailabilityState === EmailAvailabilityState.Loading) {
    validateStatus = "validating";
  } else if (eMailAvailabilityState === EmailAvailabilityState.Taken) {
    validateStatus = "error";
  } else if (eMailAvailabilityState === EmailAvailabilityState.Available) {
    validateStatus = "success";
  }

  const help = touched && error && t(error as any);

  return (
    <FormItem
      validateStatus={validateStatus}
      hasFeedback={!isEmpty(validateStatus)}
      help={help}
      label={label}
    >
      <Field
        as={Input}
        name={name}
        placeholder={placeholder}
        type="email"
        size={size}
        autoComplete="username"
      />
    </FormItem>
  );
};
