/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoCoreModelsUsersEfsUserList
 */
export interface MelcoCoreModelsUsersEfsUserList {
    /**
     * 
     * @type {number}
     * @memberof MelcoCoreModelsUsersEfsUserList
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEfsUserList
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCoreModelsUsersEfsUserList
     */
    last_name?: string | null;
}

export function MelcoCoreModelsUsersEfsUserListFromJSON(json: any): MelcoCoreModelsUsersEfsUserList {
    return MelcoCoreModelsUsersEfsUserListFromJSONTyped(json, false);
}

export function MelcoCoreModelsUsersEfsUserListFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCoreModelsUsersEfsUserList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
    };
}

export function MelcoCoreModelsUsersEfsUserListToJSON(value?: MelcoCoreModelsUsersEfsUserList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.user_id,
        'first_name': value.first_name,
        'last_name': value.last_name,
    };
}


