import { ReactNode } from "react";
import { FormItem, Switch, SwitchProps } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

type SwitchFieldProps = {
  name: string;
  label?: string;
  valueConverter?: {
    fromValueToBoolean: (fromValue: any) => boolean | undefined;
    fromBooleanToValue: (booleanValue?: boolean) => any;
  };
  info?: ReactNode;
};

export const SwitchField = (props: SwitchFieldProps & SwitchProps) => {
  const { t } = useTranslation();
  const { name, label, valueConverter, info, ...switchProps } = props;
  const [field, meta, { setValue }] = useField(name!);

  const hasFeedback = !!(meta.touched && meta.error);
  const help = meta.touched && meta.error && t(meta.error);
  const validateStatus = meta.touched && meta.error ? "error" : undefined;

  const isChecked = valueConverter
    ? valueConverter.fromValueToBoolean(meta.value)
    : meta.value;

  return (
    <FormItem
      help={hasFeedback ? help : undefined}
      validateStatus={validateStatus}
      label={label}
      info={info}
    >
      <Switch
        {...switchProps}
        {...field}
        checked={isChecked}
        onChange={(value: boolean) => {
          const convertedValue = valueConverter
            ? valueConverter.fromBooleanToValue(value)
            : value;

          setValue(convertedValue);
        }}
      />
    </FormItem>
  );
};
