import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";
import { Elevated } from "../appearance/Elevated";

const { spacings } = DesignTokens;

const StyledPreviewSider = styled.div`
  padding: ${spacings.xlarge}px 0 ${spacings.xlarge}px ${spacings.xlarge}px;
  min-height: 100%;
`;

type PreviewSiderProps = {
  children?: React.ReactNode;
};

const PreviewSider: React.FC<PreviewSiderProps> = (props) => (
  <Elevated level={1} left>
    <StyledPreviewSider {...props} />
  </Elevated>
);

export { PreviewSider };
