import { ReactNode } from "react";
import { FormItem, Select, SelectProps, SelectValue } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

interface SelectFieldProps<VT> extends SelectProps<VT> {
  name: string;
  label?: string;
  onChange?: (value: VT, option?: any) => void;
  valueConverter?: {
    toSelectValue: (
      value: VT
    ) => string | number | string[] | number[] | undefined;
    toFormValue: (value: VT, option: any) => VT;
  };
  info?: ReactNode;
}

const SelectField = <VT extends SelectValue>(props: SelectFieldProps<VT>) => {
  const { t } = useTranslation();
  const { name, label, valueConverter, info, ...selectProps } = props;
  const [field, meta, helpers] = useField(name);

  const hasFeedback = !!(meta.touched && meta.error);
  const help = meta.touched && meta.error && t(meta.error);
  const validateStatus = meta.touched && meta.error ? "error" : undefined;

  const convertedValue = valueConverter
    ? valueConverter.toSelectValue(meta.value)
    : meta.value;

  return (
    <FormItem
      help={hasFeedback ? help : undefined}
      validateStatus={validateStatus}
      label={label}
      info={info}
    >
      <Select
        {...selectProps}
        {...field}
        value={convertedValue}
        onChange={(value: VT, option: any) => {
          const convertedValue = valueConverter
            ? valueConverter.toFormValue(value, option)
            : value;

          helpers.setValue(convertedValue);

          if (selectProps.onChange) {
            selectProps.onChange(value, option);
          }
        }}
        onBlur={() => helpers.setTouched(true)}
      />
    </FormItem>
  );
};

export { SelectField };
