import styled from "styled-components";
import isEmpty from "lodash-es/isEmpty";
import trim from "lodash-es/trim";
import { DesignTokens } from "../../styles/design-tokens";

const { colors } = DesignTokens;

const FallbackWrapper = styled.span`
  && {
    margin: 0;
    color: ${colors.gray.middle};
  }
`;

type ValueOrFallbackProps = {
  value?: React.ReactNode;
  useFallback?: boolean;
  render?: (value?: React.ReactNode) => React.ReactElement;
  fallback: string;
};

export const ValueOrFallback: React.FC<ValueOrFallbackProps> = ({
  value,
  useFallback,
  render,
  fallback,
}) => {
  if (useFallback || isEmpty(trim(String(value)))) {
    return <FallbackWrapper>{fallback}</FallbackWrapper>;
  }

  if (render) {
    return render(value);
  }

  return <>{value}</>;
};
