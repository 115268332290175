import { message } from "../feedback/message";
import { Space } from "../space/Space";
import { Text } from "./Text";

export type CopyableTextWithLabelProps = {
  text: string;
  label: string;
  onCopiedMessage: string;
  copyTooltip: string;
  copiedTooltip: string;
};

const CopyableTextWithLabel: React.FC<CopyableTextWithLabelProps> = ({
  text,
  label,
  onCopiedMessage,
  copyTooltip,
  copiedTooltip,
}) => (
  <Space direction="vertical" size="xsmall">
    <Text>{label}</Text>

    <Text
      type="secondary"
      copyable={{
        onCopy: () => {
          message.success(onCopiedMessage);
        },
        tooltips: [copyTooltip, copiedTooltip],
      }}
    >
      {text}
    </Text>
  </Space>
);

export { CopyableTextWithLabel };
