import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { DisplayForgotPasswordForm } from "./DisplayForgotPasswordForm";
import {
  useBaseAPIConfig,
  useFlowFormSubmit,
  UserManagementApi,
  VerificationState,
} from "melco-shared-logic";

export type ForgotPasswordFormValueType = {
  username: string;
};

const initialValues: ForgotPasswordFormValueType = {
  username: "",
};

const ForgotPasswordFormSchema = Yup.object().shape({
  username: Yup.string()
    .email("global.error.shouldbe.email")
    .required("global.error.required.default"),
});

type ForgotPasswordFormProps = {
  verificationState: VerificationState;
};

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  verificationState,
}) => {
  const navigate = useNavigate();
  const baseApiConfig = useBaseAPIConfig();
  const { setEMail } = verificationState;

  const onSubmit = useFlowFormSubmit(
    async (values: ForgotPasswordFormValueType) => {
      const { username } = values;

      const api = new UserManagementApi(baseApiConfig());

      const response = await api.userForgotPassword({ username });

      if (response.result) {
        setEMail(username);
        navigate("/forgotpassword/verify");
        return;
      } else {
        throw new Error();
      }
    },
    { translationPrefix: "forgotpasswordpage" }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ForgotPasswordFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => <DisplayForgotPasswordForm {...props} />}
    </Formik>
  );
};
