import ReactDOM from "react-dom";

import { HEADER_BUTTON_AREA_DOM_ID } from "./HeaderButtonArea";

type HeaderButtonAreaPortalProps = {
  children: React.ReactNode;
};

export const HeaderButtonAreaPortal: React.FC<HeaderButtonAreaPortalProps> = ({
  children,
}) => {
  const domElement = document.getElementById(HEADER_BUTTON_AREA_DOM_ID);

  if (!domElement) {
    return null;
  }
  return ReactDOM.createPortal(children, domElement);
};
