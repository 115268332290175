import { FormWrapper } from "melco-ui";
import { useFormikContext } from "formik";

type VerticalFormProps = {
  children?: React.ReactNode;
};

export const VerticalForm: React.FC<VerticalFormProps> = ({ children }) => {
  const { handleSubmit, handleReset } = useFormikContext();

  return (
    <FormWrapper
      layout="vertical"
      onSubmitCapture={handleSubmit}
      onReset={handleReset}
    >
      {children}
    </FormWrapper>
  );
};
