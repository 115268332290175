import {
  BulkActionContainerCol,
  Button,
  Col,
  Table,
  TableActionBarRow,
  Text,
  ValueOrFallback,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  BulkActionCount,
  FormattedDate,
  MelcoCoreModelsUsersSubUserDetail,
  Search,
  TableCellLink,
  ViewContext,
  useTableSelection,
} from "melco-shared-logic";
import { useUsers } from "../../hooks/administration/useUsers";
import { BulkActionDeleteUsers } from "./BulkActionDeleteUsers";
import { useNavigate } from "react-router-dom";
import { useResendInvite } from "../../hooks/administration/useResendInvite";
import { TFunction } from "i18next";

const DETAIL_VIEW_PATH = "/administration/users";

export const UsersListColumns = (
  t: TFunction<"ns1">,
  context: ViewContext = ViewContext.List
) => {
  return [
    {
      title: t("users.list.columns.first_name"),
      key: "first_name",
      dataIndex: "first_name",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsUsersSubUserDetail) => (
        <ValueOrFallback
          value={item.first_name}
          render={(value) =>
            context === ViewContext.List ? (
              <TableCellLink to={[DETAIL_VIEW_PATH, item.id].join("/")}>
                {value}
              </TableCellLink>
            ) : (
              <>{value}</>
            )
          }
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("users.list.columns.last_name"),
      key: "last_name",
      dataIndex: "last_name",
      sorter: true,
    },
    {
      title: t("users.list.columns.user_name"),
      key: "user_name",
      dataIndex: "user_name",
      sorter: true,
    },
  ];
};

export const UsersList: React.FC = () => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
    refetch,
  } = useUsers();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [isResendAvailable, resendInvite, isResendingForUserId] =
    useResendInvite();

  const { selectedRowKeys, setSelectedRowKeys, rowSelection, onRow } =
    useTableSelection<MelcoCoreModelsUsersSubUserDetail>((row) => {
      navigate([DETAIL_VIEW_PATH, row.id].join("/"));
    });

  const columns = [
    ...UsersListColumns(t),
    {
      title: t("users.list.columns.last_login_date"),
      key: "last_login_date",
      dataIndex: "last_login_date",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsUsersSubUserDetail) => (
        <FormattedDate date={item.last_login_date} />
      ),
    },
    {
      title: t("users.list.columns.created_date"),
      key: "created_date",
      dataIndex: "created_date",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsUsersSubUserDetail) => (
        <FormattedDate date={item.created_date} />
      ),
    },
    {
      title: t("users.list.columns.updated_date"),
      key: "updated_date",
      dataIndex: "updated_date",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsUsersSubUserDetail) => (
        <FormattedDate date={item.updated_date} />
      ),
    },
    {
      title: t("users.list.columns.status"),
      key: "status",
      dataIndex: "status",
      sorter: false,
      render: (_: string, user: MelcoCoreModelsUsersSubUserDetail) =>
        user.verified_on ? (
          <Text type="success">{t("users.list.status.active")}</Text>
        ) : (
          <Text type="warning">{t("users.list.status.invited")}</Text>
        ),
    },
    {
      title: t("users.list.columns.actions"),
      key: "actions",
      dataIndex: "actions",
      sorter: false,
      render: (_: string, user: MelcoCoreModelsUsersSubUserDetail) =>
        isResendAvailable(user) && (
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              resendInvite(user.id!);
            }}
            loading={
              isResendingForUserId != null && isResendingForUserId === user.id
            }
          >
            {t("users.actions.invite.button")}
          </Button>
        ),
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col xs={24} xl={17}>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
        <BulkActionContainerCol>
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionDeleteUsers
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            refetch={refetch}
          />
        </BulkActionContainerCol>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={onRow}
        noDataMessage={t("users.list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
