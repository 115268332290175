import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Paragraph, FlowPageTitle, FlowBoxTitle } from "melco-ui";
import { VerifyCodeForm } from "../../components/forgotpassword/VerifyCodeForm";
import { VerificationState } from "melco-shared-logic";

type VerifyCodeProps = {
  verificationState: VerificationState;
};

const VerifyCode: React.FC<VerifyCodeProps> = ({ verificationState }) => {
  const { t } = useTranslation();
  const { eMail } = verificationState;

  if (!eMail) {
    return <Navigate to="/forgotpassword" replace />;
  }

  return (
    <>
      <FlowPageTitle>{t("forgotpasswordverifypage.title")}</FlowPageTitle>

      <VerifyCodeForm
        verificationState={verificationState}
        process="forgotPassword"
      >
        <FlowBoxTitle>{t("forgotpasswordverifypage.box_title")}</FlowBoxTitle>

        <Paragraph>{t("forgotpasswordverifypage.box_text")}</Paragraph>
      </VerifyCodeForm>
    </>
  );
};

export default VerifyCode;
