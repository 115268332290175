import React from "react";
import AntdLayout from "antd/es/layout/layout";
import styled from "styled-components";
import { Header, HeaderProps } from "../components/layout/flow/Header";

const StyledLayout = styled(AntdLayout)`
  padding: 30px;
  width: 1024px;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
`;
const StyledFlowWrapper = styled.section`
  width: 380px;
  max-width: 100%;
  margin: 0 auto;
`;

export interface FlowLayoutProps extends HeaderProps {
  children: React.ReactNode;
}

export const FlowLayout: React.FC<FlowLayoutProps> = ({
  children,
  ...headerProps
}) => (
  <StyledLayout>
    <Header {...headerProps} />
    <StyledFlowWrapper>{children}</StyledFlowWrapper>
  </StyledLayout>
);
