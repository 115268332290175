import Row, { RowProps as AntdRowProps } from "antd/es/row";
import Col from "antd/es/col";
import castArray from "lodash-es/castArray";
import styled from "styled-components";
import React from "react";
import { DesignTokens } from "../../../styles/design-tokens";

const { spacings } = DesignTokens;

const GRID_COLS = 24;

const RowWrapper = styled.div`
  margin: 0 ${spacings.xlarge}px;
`;
// FIXME: Ensure that the spacing is correct even if the FormRow is used as a last item before a FormSection

export type FlowBoxButtonRowProps = {
  children?: React.ReactNode;
} & Omit<AntdRowProps, "align" | "wrap" | "gutter">;

export const FlowBoxButtonRow: React.FC<FlowBoxButtonRowProps> = ({
  children,
}) => {
  const amountOfChildren = castArray(children).length;

  return (
    <RowWrapper>
      <Row gutter={[spacings.middle, spacings.middle]}>
        {castArray(children).map((child, idx) => (
          <Col
            key={idx}
            xs={GRID_COLS}
            md={Math.max(8, GRID_COLS / amountOfChildren)}
          >
            {child}
          </Col>
        ))}
      </Row>
    </RowWrapper>
  );
};
