import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import {
  FlowBox,
  FlowPageTitle,
  FlowBoxTitle,
  Button,
  Paragraph,
  FlowBoxButtonRow,
} from "melco-ui";
import { ChangeEMailForm } from "../../components/register/ChangeEMailForm";
import { VerificationState } from "melco-shared-logic";

type ChangeEMailProps = {
  verificationState: VerificationState;
};

const ChangeEMail: React.FC<ChangeEMailProps> = ({ verificationState }) => {
  const { t } = useTranslation();
  const { eMail } = verificationState;

  if (!eMail) {
    return <Navigate to="/register" replace />;
  }

  return (
    <>
      <FlowPageTitle>{t("registrationchangeemailpage.title")}</FlowPageTitle>

      <FlowBox>
        <FlowBoxTitle>
          {t("registrationchangeemailpage.box_title")}
        </FlowBoxTitle>

        <ChangeEMailForm verificationState={verificationState} />
      </FlowBox>

      <Paragraph size="small" alignment="center" type="secondary">
        {t("registrationchangeemailpage.secondary.text")}
      </Paragraph>

      <FlowBoxButtonRow>
        <Button hasHeroHeight href="/" block>
          {t("forgotpasswordverifypage.secondary.label_cancel")}
        </Button>
      </FlowBoxButtonRow>
    </>
  );
};

export default ChangeEMail;
