import React from "react";
import styled from "styled-components";

const StyledHeader = styled.div`
  padding: 10px 0 10px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const HeaderLogo = styled.img`
  width: 109px;
  margin-top: -8px;
`;

export type HeaderProps = {
  header?: React.ReactNode;
};

const Header: React.FC<HeaderProps> = ({ header }) => (
  <StyledHeader>{header}</StyledHeader>
);

export { Header, HeaderLogo };
