import { Formik } from "formik";
import {
  useAuthenticatedAPIConfig,
  useCurrentUser,
  useFormSubmit,
  UserManagementApi,
} from "melco-shared-logic";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  ChangeEMailFormSchema,
  ChangeEMailFormValueType,
} from "../register/ChangeEMailForm";
import { DisplayChangeEMailForm } from "./DisplayChangeEMailForm";
import { EmailAvailabilityState } from "../register/EmailFieldWithAvailabilityCheck";
import { useIsAllowedToUpdateEMailAddress } from "../../hooks/user/useIsAllowedToUpdateEMailAddress";

const AccountChangeEMailFormSchema = ChangeEMailFormSchema.shape({
  current_password: Yup.string().required("global.error.required.default"),
});

export const ChangeEMailForm: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const isAllowedToUpdateEMailAddress = useIsAllowedToUpdateEMailAddress();

  const onSubmit = useFormSubmit<ChangeEMailFormValueType>(
    async (values: ChangeEMailFormValueType) => {
      if (!isAllowedToUpdateEMailAddress) {
        return;
      }

      const { userId } = currentUser!;
      const { user_name, current_password, new_user_name } = values;

      const api = new UserManagementApi(authenticatedApiConfig());

      const success = await api.userChangeUserName({
        id: userId,
        melcoCoreModelsUsersChangeUserName: {
          user_name,
          new_user_name,
          current_password,
        },
      });

      if (success) {
        navigate(`/register/verify?eMail=${new_user_name}`);
      } else {
        throw new Error();
      }
    },
    { translationPrefix: "registrationchangeemailpage" }
  );

  if (!currentUser || !isAllowedToUpdateEMailAddress) {
    return null;
  }

  const { eMail } = currentUser;

  const initialValues: ChangeEMailFormValueType = {
    user_name: eMail,
    new_user_name: "",
    current_password: undefined,
    isEmailAvailable: EmailAvailabilityState.Unknown,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AccountChangeEMailFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => <DisplayChangeEMailForm {...props} />}
    </Formik>
  );
};
