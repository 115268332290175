/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCalculatorModelsMachineSetting,
    MelcoCalculatorModelsMachineSettingFromJSON,
    MelcoCalculatorModelsMachineSettingFromJSONTyped,
    MelcoCalculatorModelsMachineSettingToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCalculatorModelsMachineResponse
 */
export interface MelcoCalculatorModelsMachineResponse {
    /**
     * 
     * @type {string}
     * @memberof MelcoCalculatorModelsMachineResponse
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoCalculatorModelsMachineResponse
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsMachineResponse
     */
    multihead?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsMachineResponse
     */
    heads?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsMachineResponse
     */
    investment?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsMachineResponse
     */
    amortization_months?: number;
    /**
     * 
     * @type {MelcoCalculatorModelsMachineSetting}
     * @memberof MelcoCalculatorModelsMachineResponse
     */
    settings?: MelcoCalculatorModelsMachineSetting;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsMachineResponse
     */
    incomplete?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsMachineResponse
     */
    avg_efficiency?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsMachineResponse
     */
    is_melco_created?: boolean;
}

export function MelcoCalculatorModelsMachineResponseFromJSON(json: any): MelcoCalculatorModelsMachineResponse {
    return MelcoCalculatorModelsMachineResponseFromJSONTyped(json, false);
}

export function MelcoCalculatorModelsMachineResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCalculatorModelsMachineResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'multihead': !exists(json, 'multihead') ? undefined : json['multihead'],
        'heads': !exists(json, 'heads') ? undefined : json['heads'],
        'investment': !exists(json, 'investment') ? undefined : json['investment'],
        'amortization_months': !exists(json, 'amortization_months') ? undefined : json['amortization_months'],
        'settings': !exists(json, 'settings') ? undefined : MelcoCalculatorModelsMachineSettingFromJSON(json['settings']),
        'incomplete': !exists(json, 'incomplete') ? undefined : json['incomplete'],
        'avg_efficiency': !exists(json, 'avg_efficiency') ? undefined : json['avg_efficiency'],
        'is_melco_created': !exists(json, 'is_melco_created') ? undefined : json['is_melco_created'],
    };
}

export function MelcoCalculatorModelsMachineResponseToJSON(value?: MelcoCalculatorModelsMachineResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'multihead': value.multihead,
        'heads': value.heads,
        'investment': value.investment,
        'amortization_months': value.amortization_months,
        'settings': MelcoCalculatorModelsMachineSettingToJSON(value.settings),
        'incomplete': value.incomplete,
        'avg_efficiency': value.avg_efficiency,
        'is_melco_created': value.is_melco_created,
    };
}


