import { useCallback, useState } from "react";
import { BaseAPI, Configuration } from "../../api";
import {
  useAuthenticatedAPIConfig,
  DefaultAPIConfigOptions,
} from "./useAPIConfig";
import {
  APIError,
  useErrorMessageMapping,
} from "../error/useErrorMessageMapping";
import { useSubmitResultHandler } from "../form/useSubmitResultHandler";
import { TranslatableMessageWithOptionalTitle } from "../form/useFormSubmit";
import { useErrorNotification } from "../error/useErrorNotification";

type APIActionOptions = {
  translationPrefix: string;
  onSuccess?: () => void;
  onError?: () => void;
  onSuccessOrError?: () => void;
  skipErrorHandling?: boolean;
  defaultApiConfigOptions?: DefaultAPIConfigOptions;
};

type HandleRequestReturn<R> = {
  successMessages: TranslatableMessageWithOptionalTitle[];
  data?: R;
};

export const useAPIAction = <R, A extends BaseAPI, P extends unknown[]>(
  APIClass: new (configuration: Configuration) => A,
  handleRequest: (
    api: A,
    ...args: P
  ) => HandleRequestReturn<R> | void | Promise<HandleRequestReturn<R> | void>,
  options: APIActionOptions
) => {
  const {
    translationPrefix,
    onSuccess,
    onError,
    onSuccessOrError,
    skipErrorHandling,
    defaultApiConfigOptions,
  } = options;
  const [isLoading, setIsLoading] = useState(false);
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const mapErrors = useErrorMessageMapping();
  const handleSubmitResult = useSubmitResultHandler(translationPrefix);
  const errorNotification = useErrorNotification();

  const action = useCallback(
    async (...args: P) => {
      try {
        setIsLoading(true);

        const api = new APIClass(
          authenticatedApiConfig(defaultApiConfigOptions)
        );

        const handleRequestResult = await handleRequest(api, ...args);

        if (handleRequestResult) {
          handleSubmitResult(handleRequestResult);
          if (onSuccess) {
            onSuccess();
          }

          return handleRequestResult.data;
        }
      } catch (error) {
        if (!skipErrorHandling) {
          const {
            feedbackMessage: { title, message },
          } = await mapErrors(error as APIError[]);

          errorNotification(title, message);
        }

        if (onError) {
          onError();
        }

        throw error; // re-throw error to make sure that the caller does not continue with execution
      } finally {
        setIsLoading(false);

        if (onSuccessOrError) {
          onSuccessOrError();
        }
      }
    },
    [
      APIClass,
      setIsLoading,
      handleRequest,
      handleSubmitResult,
      mapErrors,
      onSuccess,
      onError,
      onSuccessOrError,
      errorNotification,
      skipErrorHandling,
      authenticatedApiConfig,
      defaultApiConfigOptions,
    ]
  );

  return [action, isLoading] as const;
};
