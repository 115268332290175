import { useTranslation } from "react-i18next";
import { Button, SaveIcon } from "melco-ui";
import { useFormikContext } from "formik";
import { useIsFormChanged } from "../../hooks/form/useIsFormChanged";

export type SaveFormButtonProps = {
  disabled?: boolean;
  title?: string;
  disableDirtyTracking?: boolean;
  onClick?: () => void;
};

export const SaveFormButton: React.FC<SaveFormButtonProps> = ({
  disabled,
  title,
  disableDirtyTracking,
  onClick,
}) => {
  const { t } = useTranslation();
  const { isSubmitting, isValid, submitForm } = useFormikContext();
  const isFormChanged = useIsFormChanged();

  const isFormConsideredChanged = disableDirtyTracking ? true : isFormChanged;

  return (
    <Button
      key="save"
      type="primary"
      icon={<SaveIcon />}
      disabled={
        isSubmitting ||
        !isFormConsideredChanged ||
        (!isValid && !isFormConsideredChanged) ||
        disabled
      }
      loading={isSubmitting}
      onClick={onClick ? onClick : submitForm}
    >
      {title ?? t("global.button.save")}
    </Button>
  );
};
