import {
  DrawerFooterContent,
  MelcoCoreModelsUsersSubUserDetail,
} from "melco-shared-logic";
import { Alert, Button, FullWidthSpace, Row, Title } from "melco-ui";
import { useTranslation } from "react-i18next";
import { PasswordResetLoginData } from "./PasswordResetLoginData";

type AddedUserInfoProps = {
  addedUser: MelcoCoreModelsUsersSubUserDetail | undefined;
  closeAndReset: () => void;
};

export const AddedUserInfo: React.FC<AddedUserInfoProps> = ({
  addedUser,
  closeAndReset,
}) => {
  const { t } = useTranslation();

  const isEmail = addedUser?.is_email;

  return (
    <>
      {isEmail ? (
        <FullWidthSpace direction="vertical" size="middle">
          <Title level={4}>
            {t("users.actions.add.afterAddingEMailUser.title")}
          </Title>

          <Alert
            type="info"
            withBackground
            message={t("users.actions.add.afterAddingEMailUser.message")}
          />
        </FullWidthSpace>
      ) : (
        <FullWidthSpace direction="vertical" size="middle">
          <Title level={4}>{t("users.actions.add.success.default")}</Title>

          <PasswordResetLoginData
            passwordResetData={{
              user_name: addedUser?.user_name ?? "",
              new_password: addedUser?.password ?? "",
            }}
          />
        </FullWidthSpace>
      )}

      <DrawerFooterContent>
        <Row justify="end">
          <Button key="user-add-done" type="primary" onClick={closeAndReset}>
            {t("global.button.done")}
          </Button>
        </Row>
      </DrawerFooterContent>
    </>
  );
};
