import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { FlowPageTitle, FlowBoxTitle } from "melco-ui";
import ResetPasswordForm from "../../components/forgotpassword/ResetPasswordForm";
import { VerificationState } from "melco-shared-logic";

type ResetPasswordProps = {
  verificationState: VerificationState;
};

const ResetPassword: React.FC<ResetPasswordProps> = ({ verificationState }) => {
  const { t } = useTranslation();
  const { eMail } = verificationState;

  if (!eMail) {
    return <Navigate to="/forgotpassword" replace />;
  }

  return (
    <>
      <FlowPageTitle>{t("forgotpasswordresetpage.title")}</FlowPageTitle>

      <ResetPasswordForm verificationState={verificationState}>
        <FlowBoxTitle>{t("forgotpasswordresetpage.box_title")}</FlowBoxTitle>
      </ResetPasswordForm>
    </>
  );
};

export default ResetPassword;
