import {
  Drawer as DrawerType,
  MelcoCoreModelsDRMUserSerialNumber,
} from "melco-shared-logic";
import { Drawer } from "melco-ui";
import { useTranslation } from "react-i18next";
import { LicenseActivationReminderForm } from "./LicenseActivationReminderForm";

type AddLicenseDrawerProps = {
  license: MelcoCoreModelsDRMUserSerialNumber;
  drawer: DrawerType;
};

export const LicenseActivationReminderDrawer: React.FC<
  AddLicenseDrawerProps
> = ({ drawer, license }) => {
  const { t } = useTranslation();
  const { closeDrawer, isDrawerOpen } = drawer;

  return (
    <Drawer
      title={t("infrastructureLicenses.actions.activationReminder.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      visible={isDrawerOpen}
      destroyOnClose
    >
      <LicenseActivationReminderForm license={license} drawer={drawer} />
    </Drawer>
  );
};
