import { Modal } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AcceptServiceTermsButton from "./AcceptServiceTermsButton";
import DeclineServiceTermsButton from "./DeclineServiceTermsButton";
import ServiceTermContent from "./ServiceTermContent";
import { find } from "lodash-es";
import { useProducts } from "../../hooks/product/useProducts";

const ServiceTermModal: React.FC = () => {
  const { productCode } = useParams<{ productCode: string }>();
  const { t } = useTranslation();
  const { data: products } = useProducts();

  // fetch product
  const product = find(products ?? [], (p) => p.code === productCode);

  if (!product) {
    return null;
  }

  const wrapClassName = `modal-terms-${productCode}`;

  return (
    <Modal
      title={`${t("dashboard.apps.legal_policy.title")} ${product.name}`}
      visible
      centered
      closable={false}
      width="80vw"
      wrapClassName={wrapClassName}
      footer={[
        <DeclineServiceTermsButton key="back" />,
        <AcceptServiceTermsButton key="submit" product={product} />,
      ]}
    >
      <ServiceTermContent productCode={productCode!} />
    </Modal>
  );
};

export default ServiceTermModal;
