import { Alert, AlertList, AlertWrapper } from "melco-ui";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash-es/isEmpty";

export const DisplayFormErrors: React.FC = () => {
  const { t } = useTranslation();
  const { status } = useFormikContext();

  if (!status || isEmpty(status.errorMessages)) {
    return null;
  }

  return (
    <AlertWrapper>
      <Alert
        message={t("global.error.form.title")}
        type="error"
        withBackground
        description={
          !isEmpty(status.globalErrorMessages) && (
            <AlertList>
              {status.globalErrorMessages.map((error: string) => (
                <li key={error}>{t(error)}</li>
              ))}
            </AlertList>
          )
        }
      />
    </AlertWrapper>
  );
};
