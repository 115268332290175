import { Drawer } from "melco-ui";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useCreateAPIKey } from "../../hooks/settings/useCreateAPIKey";
import { useFormSubmit } from "melco-shared-logic";
import { DisplayCreateAPIKeyForm } from "./DisplayCreateAPIKeyForm";

type CreateAPIKeyForm = {
  name: string;
  product_code: string;
  scope_id: string;
};

type CreateAPIKeyDrawerProps = {
  isDrawerOpen: boolean;
  setDrawerOpen: (isDrawerOpen: boolean) => void;
};

export const CreateAPIKeyDrawer: React.FC<CreateAPIKeyDrawerProps> = ({
  isDrawerOpen,
  setDrawerOpen,
}) => {
  const { t } = useTranslation();

  const [createAPIKey, isLoading] = useCreateAPIKey();

  const initialValues: CreateAPIKeyForm = {
    name: "",
    product_code: "",
    scope_id: "",
  };

  const onSubmit = useFormSubmit<CreateAPIKeyForm>(
    async (values: CreateAPIKeyForm) => {
      const { name, product_code, scope_id } = values;

      await createAPIKey(name, product_code, scope_id);

      setDrawerOpen(false);

      return { successMessages: [{ message: "default" }] };
    },
    {
      translationPrefix: "api_list.actions.add",
      onSuccess: (_values, { resetForm }) => {
        resetForm({ values: initialValues });
      },
    }
  );

  return (
    <Drawer
      title={t("api_list.actions.add.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={() => setDrawerOpen(false)}
      visible={isDrawerOpen}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <DisplayCreateAPIKeyForm isLoading={isLoading} />
      </Formik>
    </Drawer>
  );
};
