import { useTranslation } from "react-i18next";
import { PageContentWrapper, PageTitle } from "melco-ui";
import { ChangeEMailForm } from "../../components/profile/ChangeEMailForm";
import { ChangePasswordForm } from "../../components/profile/ChangePasswordForm";

const AccountLogin: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContentWrapper narrow>
      <PageTitle>{t("accountloginpage.title")}</PageTitle>

      <ChangeEMailForm />

      <ChangePasswordForm />
    </PageContentWrapper>
  );
};

export default AccountLogin;
