import { Button, Modal } from "melco-ui";
import { useTranslation } from "react-i18next";
import ServiceTermContent from "./ServiceTermContent";

type AccountsServiceTermModalProps = {
  isShown: boolean;
  show: (show: boolean) => void;
};

const AccountsServiceTermModal: React.FC<AccountsServiceTermModalProps> = ({
  isShown,
  show,
}) => {
  const { t } = useTranslation();

  const cancel = () => show(false);

  const wrapClassName = "modal-terms-ACCOUNT";

  return (
    <Modal
      title={t("registrationpage.terms.modal.title")}
      visible={isShown}
      onCancel={cancel}
      centered
      closable
      width="80vw"
      wrapClassName={wrapClassName}
      footer={[
        <Button key="close" onClick={cancel}>
          {t("registrationpage.terms.modal.close")}
        </Button>,
      ]}
    >
      <ServiceTermContent productCode="ACCOUNT" />
    </Modal>
  );
};

export default AccountsServiceTermModal;
