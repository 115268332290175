import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";
import { Link } from "../typography/Link";

const { spacings } = DesignTokens;

export const FullSizeTableCellLink = styled(Link).attrs({ underline: true })`
  display: block;
  margin: -${spacings.middle}px -${spacings.middle}px; //As defined in rollupconfig table @table-padding-vertical and @table-padding-horizontal
  padding: ${spacings.middle}px ${spacings.middle}px; //As defined in rollupconfig table @table-padding-vertical and @table-padding-horizontal
`;
