import {
  MelcoCoreModelsUsersSubUserDetail,
  ViewContext,
} from "melco-shared-logic";
import { UserPermissions } from "./UserPermissions";

type SetPermissionsFormProps = {
  user: MelcoCoreModelsUsersSubUserDetail | undefined;
  afterPermissionsSaved: () => void;
};

export const SetPermissionsForm: React.FC<SetPermissionsFormProps> = ({
  user,
  afterPermissionsSaved,
}) => {
  if (!user) {
    return null;
  }

  return (
    <UserPermissions
      user={user}
      afterPermissionsSaved={afterPermissionsSaved}
      viewContext={ViewContext.Drawer}
    />
  );
};
