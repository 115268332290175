import {
  BulkActionContainerCol,
  Col,
  message,
  Table,
  TableActionBarRow,
  Text,
  ValueOrFallback,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  BulkActionCount,
  FormattedDate,
  MelcoCoreModelsApiKey,
  Search,
  useTableSelection,
} from "melco-shared-logic";
import { useAPIKeys } from "../../hooks/settings/useAPIKeys";
import { BulkActionDeleteAPIKeys } from "./BulkActionDeleteAPIKeys";

export const APIKeysList: React.FC = () => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
    refetch,
  } = useAPIKeys();

  const { t } = useTranslation();

  const { selectedRowKeys, setSelectedRowKeys, rowSelection } =
    useTableSelection<MelcoCoreModelsApiKey>(() => {});

  const columns = [
    {
      title: t("api_list.columns.name"),
      key: "name",
      dataIndex: "name",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsApiKey) => (
        <ValueOrFallback
          value={item.name}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("api_list.columns.api_key"),
      key: "api_key",
      dataIndex: "api_key",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsApiKey) => (
        <ValueOrFallback
          value={item.api_key}
          render={(value) => (
            <Text
              code
              copyable={{
                onCopy: () => {
                  message.success(t("api_list.actions.copy.success"));
                },
                tooltips: [
                  t("api_list.actions.copy.tooltip_copy"),
                  t("api_list.actions.copy.tooltip_copied"),
                ],
              }}
            >
              {value}
            </Text>
          )}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("api_list.columns.product_name"),
      key: "product_name",
      dataIndex: "product_name",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsApiKey) => (
        <ValueOrFallback
          value={item.product_name}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("api_list.columns.scope"),
      key: "scope",
      dataIndex: "scope",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsApiKey) => (
        <ValueOrFallback
          value={t(`api_list.scope.${item.scope}` as any, item.scope ?? "")}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("api_list.columns.created_date"),
      key: "created_date",
      dataIndex: "created_date",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsApiKey) => (
        <FormattedDate date={item.created_date} />
      ),
    },
  ];

  const onRow = () => ({});

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
        <BulkActionContainerCol>
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionDeleteAPIKeys
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            refetch={refetch}
          />
        </BulkActionContainerCol>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={onRow}
        noDataMessage={t("api_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
