import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Paragraph, FlowPageTitle, FlowBoxTitle } from "melco-ui";
import { VerifyCodeForm } from "../../components/forgotpassword/VerifyCodeForm";
import { VerificationState } from "melco-shared-logic";

type VerifyRegistrationProps = {
  verificationState: VerificationState;
};

const VerifyRegistration: React.FC<VerifyRegistrationProps> = ({
  verificationState,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { eMail, setEMail } = verificationState;

  useEffect(() => {
    if (!eMail) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has("eMail")) {
        setEMail(searchParams.get("eMail")!);
      } else {
        // no email has been set, redirect the user back to step 1
        navigate("/register");
      }
    }
  }, [navigate, setEMail, location, eMail]);

  return (
    <>
      <FlowPageTitle>{t("registrationverifypage.title")}</FlowPageTitle>

      <VerifyCodeForm verificationState={verificationState} process="register">
        <FlowBoxTitle>{t("registrationverifypage.box_title")}</FlowBoxTitle>

        <Paragraph>{t("registrationverifypage.box_text")}</Paragraph>
      </VerifyCodeForm>
    </>
  );
};

export default VerifyRegistration;
