import styled from "styled-components";
import { ElevationHelper, ElevationLevels } from "../../helper/ElevationHelper";

const { createBoxShadowCSSString } = ElevationHelper;

type ElevationProps = {
  left?: boolean;
  right?: boolean;
  bottom?: boolean;
  level: ElevationLevels;
};

export const Elevated = styled.div<ElevationProps>`
  min-height: 100%;
  box-shadow: ${({ left, right, bottom, level }) =>
    createBoxShadowCSSString({ left, right, bottom, level })};
`;
