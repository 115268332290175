import styled from "styled-components";
import { DesignTokens } from "melco-ui";
import { MelcoCoreModelsUserProductListItem } from "melco-shared-logic";
import LogoCollapsed from "../../assets/logo_collapsed.svg";
import { AppTrialInfo } from "./AppTrialInfo";

const { fonts, spacings, animations } = DesignTokens;

const LogoContent = styled.div`
  text-align: center;
  transition: all ${animations.durations.base}s ${animations.easing.out};
`;
const IconWrapper = styled.div``;

const IconImg = styled.img`
  width: ${spacings.xlarge}px;
  margin-bottom: ${spacings.small}px;
`;

const Name = styled.div`
  font-family: ${fonts.sansSerifMd};
  font-size: ${fonts.sizes.large}px;
  line-height: 1.4;
`;

type AppIconAndNameProps = {
  product: MelcoCoreModelsUserProductListItem;
};

const AppIconAndName: React.FC<AppIconAndNameProps> = ({ product }) => {
  const { name, svg_icon_url: svgIconUrl } = product;

  return (
    <LogoContent className="logo-content">
      <IconWrapper>
        <IconImg src={svgIconUrl ?? LogoCollapsed} />
      </IconWrapper>
      <Name>{name}</Name>

      <AppTrialInfo product={product} />
    </LogoContent>
  );
};

export default AppIconAndName;
