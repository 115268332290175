import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";
import { Button } from "../button/Button";
import { CloseIcon } from "../icons/icons";

const { spacings, fonts } = DesignTokens;

const PaneTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacings.xlarge}px;
`;

const Title = styled.div`
  font-family: ${fonts.sansSerif};
  font-size: ${fonts.sizes.large}px;
`;

const Subtitle = styled.div`
  font-size: ${fonts.sizes.small}px;
`;

type DetailPaneTitleProps = {
  title: React.ReactNode;
  subtitle?: string;
  onClose?: () => void;
};

export const DetailPaneTitle: React.FC<DetailPaneTitleProps> = ({
  title,
  subtitle,
  onClose,
}) => {
  return (
    <PaneTitleWrapper>
      <div>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </div>

      {onClose && <Button type="text" icon={<CloseIcon />} onClick={onClose} />}
    </PaneTitleWrapper>
  );
};
