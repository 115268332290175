import React from "react";
import styled from "styled-components";
import { DesignTokens } from "../../../styles/design-tokens";
import { Row } from "../../grid/Row";

const { spacings } = DesignTokens;

export const StyledRow = styled(Row)`
  min-height: ${2 * spacings.xlarge + spacings.middle}px;
  padding: ${spacings.xlarge}px ${spacings.xlarge}px ${spacings.middle}px
    ${spacings.xlarge}px;
`;

type TableActionBarRowProps = {
  children?: React.ReactNode;
};

export const TableActionBarRow: React.FC<TableActionBarRowProps> = ({
  children,
}) => (
  <StyledRow align="bottom" gutter={[spacings.middle, spacings.middle]}>
    {children}
  </StyledRow>
);
