import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import isString from "lodash-es/isString";

export const useTranslationWithFallback = (translationPrefix?: string) => {
  const { t, i18n } = useTranslation();

  const keyWithPrefix = useCallback(
    (key: string) =>
      translationPrefix ? [translationPrefix, key].join(".") : key,
    [translationPrefix]
  );

  return useCallback(
    (translationKeys: string[], fallback: string | { key: string }) => {
      for (let key of translationKeys) {
        if (i18n.exists(keyWithPrefix(key))) {
          return t(keyWithPrefix(key));
        }
      }

      return isString(fallback)
        ? fallback
        : t([keyWithPrefix(fallback.key), fallback.key], {
            defaultValue: fallback.key,
          });
    },
    [t, i18n, keyWithPrefix]
  );
};
