import {
  MelcoCoreModelsUserProductListItem,
  useAPIAction,
  UserAccountApi,
} from "melco-shared-logic";
import { useNavigate } from "react-router-dom";

export const useLaunchApp = (product: MelcoCoreModelsUserProductListItem) => {
  const navigate = useNavigate();

  return useAPIAction(
    UserAccountApi,
    async (api) => {
      const { code, app_startup_endpoint: appStartupEndpoint } = product;

      const response = await api.accountProductsStartUp({ productCode: code! });

      const { show_service_terms: showServiceTerms } = response;

      if (showServiceTerms === true) {
        navigate(`/dashboard/terms/${code}`);
        return;
      } else if (showServiceTerms === false) {
        if (appStartupEndpoint) {
          window.open(appStartupEndpoint, "_self");
          return;
        }
      }

      // this should not happen, as showServiceTerms is expected to be
      // either true or false
      throw new Error();
    },
    { translationPrefix: "global" }
  );
};
