import { useAPIQuery, UserAccountApi } from "melco-shared-logic";

const queryKeyForUser = (userId: string) => {
  return ["user", userId];
};

export const useUser = (userId: string | undefined) => {
  return useAPIQuery(
    UserAccountApi,
    queryKeyForUser(userId ?? ""),
    async (api) => {
      if (userId == null) {
        return;
      }

      return api.userAccountGetById({ id: userId });
    }
  );
};
