import styled from "styled-components";
import { DesignTokens } from "../../../styles/design-tokens";
import { ValueOrFallback } from "../../table/ValueOrFallback";

const { fonts } = DesignTokens;

const LabeledItemWrapper = styled.div``;

const Label = styled.div`
  font-family: ${fonts.sansSerifMd};
`;

export type LabeledItemProps = {
  label: string;
  content?: string | React.ReactNode;
  fallback?: string;
};

export const LabeledItem: React.FC<LabeledItemProps> = ({
  label,
  content,
  fallback,
}) => {
  return (
    <LabeledItemWrapper>
      <Label>{label}</Label>
      <ValueOrFallback value={content} fallback={fallback ?? ""} />
    </LabeledItemWrapper>
  );
};
