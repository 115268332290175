import { useField } from "formik";
import { UserProductAccessMap } from "../../components/administration/UserPermissions";

export const useManageUserProductAccess = () => {
  const [{ value: userProductAccess }] = useField<UserProductAccessMap>(
    "user_product_access"
  );

  return userProductAccess;
};
