import { MainContentArea, ContentHeader } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useBreadcrumb } from "../../hooks/navigation/useBreadcrumb";
import { HeaderButtonArea } from "melco-shared-logic";
import { BasicSettingsForm } from "../../components/settings/BasicSettingsForm";

export const BasicSettings: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("settings");

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("settings.basic.title")}
          breadcrumb={breadcrumb}
          extra={<HeaderButtonArea />}
        />
      }
      contentPadding
    >
      <BasicSettingsForm />
    </MainContentArea>
  );
};
