import { Drawer as DrawerType } from "melco-shared-logic";
import { Drawer } from "melco-ui";
import { useTranslation } from "react-i18next";
import { AddLicenseForm } from "./AddLicenseForm";

type AddLicenseDrawerProps = {
  drawer: DrawerType;
};

export const AddLicenseDrawer: React.FC<AddLicenseDrawerProps> = ({
  drawer,
}) => {
  const { t } = useTranslation();

  const { closeDrawer, isDrawerOpen } = drawer;

  return (
    <Drawer
      title={t("infrastructureLicenses.actions.add.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      visible={isDrawerOpen}
    >
      <AddLicenseForm drawer={drawer} />
    </Drawer>
  );
};
