import { Formik } from "formik";
import {
  useFormSubmit,
  Drawer as DrawerType,
  UserAccountApi,
  useAuthenticatedAPIConfig,
} from "melco-shared-logic";
import { useQueryClient } from "react-query";
import { object, string } from "yup";
import { DisplayAddLicenseForm } from "./DisplayAddLicenseForm";

type AddLicenseFormValueType = {
  serial_number: string;
};

const initialValues: AddLicenseFormValueType = {
  serial_number: "",
};

const AddLicenseFormSchema = object().shape({
  serial_number: string().required("global.error.required.default"),
});

type AddLicenseFormProps = {
  drawer: DrawerType;
};

export const AddLicenseForm: React.FC<AddLicenseFormProps> = ({ drawer }) => {
  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const queryClient = useQueryClient();

  const { closeDrawer } = drawer;

  const onSubmit = useFormSubmit<AddLicenseFormValueType>(
    async (values: AddLicenseFormValueType) => {
      const { serial_number } = values;

      const api = new UserAccountApi(authenticatedApiConfig());

      const result = await api.subscriptionActivate({
        serialNumber: serial_number,
      });

      if (!result.result) {
        throw new Error();
      }

      return { successMessages: [{ message: "default" }] };
    },
    {
      translationPrefix: "infrastructureLicenses.actions.add",
      onSuccess: (_, { resetForm }) => {
        queryClient.invalidateQueries(["licenses"]);
        resetForm({ values: initialValues });
        closeDrawer();
      },
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddLicenseFormSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <DisplayAddLicenseForm />
    </Formik>
  );
};
