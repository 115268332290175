import { useTranslation } from "react-i18next";
import { ContentHeader, MainContentArea } from "melco-ui";
import { useBreadcrumb } from "../../hooks/navigation/useBreadcrumb";
import { useGenericDrawer } from "melco-shared-logic";
import { AddUserDrawer } from "../../components/administration/AddUserDrawer";
import { AddUserButton } from "../../components/administration/AddUserButton";
import { UsersList } from "../../components/administration/UsersList";

export const Users: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumb("administration");

  const addUserDrawer = useGenericDrawer();
  const { openDrawer } = addUserDrawer;

  return (
    <MainContentArea
      header={
        <ContentHeader
          title={t("users.title")}
          breadcrumb={breadcrumb}
          extra={<AddUserButton onClick={openDrawer} />}
        />
      }
    >
      <UsersList />
      <AddUserDrawer drawer={addUserDrawer} />
    </MainContentArea>
  );
};
