import React from "react";
import styled from "styled-components";
import Menu, { MenuProps } from "antd/es/menu";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

const StyledDropdownMenu = styled(Menu)`
  .ant-dropdown-menu-item {
    padding: ${spacings.large / 2}px ${spacings.large}px;
  }
`;

type SecondaryNavigationMenuDropdownProps = {
  children?: React.ReactNode;
} & MenuProps;

export const SecondaryNavigationMenuDropdown: React.FC<
  SecondaryNavigationMenuDropdownProps
> = ({ children, ...rest }) => {
  return <StyledDropdownMenu {...rest}>{children}</StyledDropdownMenu>;
};
