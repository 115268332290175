/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCalculatorModelsMachineSetting,
    MelcoCalculatorModelsMachineSettingFromJSON,
    MelcoCalculatorModelsMachineSettingFromJSONTyped,
    MelcoCalculatorModelsMachineSettingToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCalculatorModelsMachineRequest
 */
export interface MelcoCalculatorModelsMachineRequest {
    /**
     * 
     * @type {string}
     * @memberof MelcoCalculatorModelsMachineRequest
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsMachineRequest
     */
    multihead?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsMachineRequest
     */
    heads?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsMachineRequest
     */
    investment?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsMachineRequest
     */
    amortization_months?: number;
    /**
     * 
     * @type {MelcoCalculatorModelsMachineSetting}
     * @memberof MelcoCalculatorModelsMachineRequest
     */
    settings?: MelcoCalculatorModelsMachineSetting;
}

export function MelcoCalculatorModelsMachineRequestFromJSON(json: any): MelcoCalculatorModelsMachineRequest {
    return MelcoCalculatorModelsMachineRequestFromJSONTyped(json, false);
}

export function MelcoCalculatorModelsMachineRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCalculatorModelsMachineRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'multihead': !exists(json, 'multihead') ? undefined : json['multihead'],
        'heads': !exists(json, 'heads') ? undefined : json['heads'],
        'investment': !exists(json, 'investment') ? undefined : json['investment'],
        'amortization_months': !exists(json, 'amortization_months') ? undefined : json['amortization_months'],
        'settings': !exists(json, 'settings') ? undefined : MelcoCalculatorModelsMachineSettingFromJSON(json['settings']),
    };
}

export function MelcoCalculatorModelsMachineRequestToJSON(value?: MelcoCalculatorModelsMachineRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'multihead': value.multihead,
        'heads': value.heads,
        'investment': value.investment,
        'amortization_months': value.amortization_months,
        'settings': MelcoCalculatorModelsMachineSettingToJSON(value.settings),
    };
}


