import { PageContentWrapper } from "melco-ui";
import { Outlet } from "react-router-dom";
import YourApps from "../../components/dashboard/YourApps";
import MoreApps from "../../components/dashboard/MoreApps";
import AddAccountNameModal from "../../components/dashboard/addAccountName/AddAccountNameModal";
import { useProducts } from "../../hooks/product/useProducts";
import { useMoreProducts } from "../../hooks/product/useMoreProducts";

export const Dashboard: React.FC = () => {
  const { data: products, isLoading: isLoadingProducts } = useProducts();
  const moreProducts = useMoreProducts(products);

  return (
    <>
      <PageContentWrapper>
        <>
          {!isLoadingProducts && <YourApps products={products ?? []} />}
          {moreProducts && <MoreApps products={moreProducts ?? []} />}
        </>
      </PageContentWrapper>
      <AddAccountNameModal />

      <Outlet />
    </>
  );
};
