import { useCallback } from "react";
import { useLocalStorage } from "react-use";
import { AuthenticationApi } from "../../api/apis/AuthenticationApi";
import { useBaseAPIConfig } from "../api/useAPIConfig";
import { useAuth } from "./useAuth";
import { useAppStartup } from "../product/useAppStartup";
import { MelcoAccountData, MELCO_ACCOUNT_KEY } from "./useTokenRestore";

export const useLogin = () => {
  const auth = useAuth();
  const [, setMelcoAccountData] =
    useLocalStorage<MelcoAccountData>(MELCO_ACCOUNT_KEY);
  const baseApiConfig = useBaseAPIConfig();
  //Make sure startup does not redirect if that request fails
  const appStartup = useAppStartup({
    skipRedirectOnError: true,
    skipTermsHandling: true,
  });

  return useCallback(
    async (eMail: string, password: string) => {
      const api = new AuthenticationApi(
        baseApiConfig({ includeCredentials: true })
      );

      const {
        token,
        user_id: userId,
        first_name: firstName,
        last_name: lastName,
        account_name: accoutName,
        parent_user_id: parentUserId,
        force_password_change: forcePasswordChange,
      } = await api.authenticationAuthenticateMelco({
        melcoCoreModelsSecurityMelcoAuth: {
          user_name: eMail,
          password,
          device_info: { name: "Browser" },
        },
      });

      if (token && userId) {
        const {
          success: startupSuccess,
          permissions,
          defaultLanguage,
        } = await appStartup(token);

        //Abort if startup failed
        if (!startupSuccess) {
          throw new Error();
        }

        setMelcoAccountData({ token, userId });

        auth.changeCurrentUser({
          eMail,
          token: token ?? "",
          userId: userId ?? "",
          firstName: firstName ?? "",
          lastName: lastName ?? "",
          accountName: accoutName ?? "",
          parentUserId: parentUserId ?? undefined,
          permissions,
          defaultLanguage,
        });

        if (forcePasswordChange) {
          return { forcePasswordChange: true };
        }
      } else {
        throw new Error();
      }

      return { forcePasswordChange: false };
    },
    [auth, setMelcoAccountData, appStartup, baseApiConfig]
  );
};
