import AntdTextArea from "antd/es/input/TextArea";
import { TextAreaProps as AntdTextAreaProps } from "antd/es/input/TextArea";
import styled from "styled-components";

const StyledTextArea = styled(AntdTextArea)`
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export type TextAreaProps = AntdTextAreaProps;

const TextArea = StyledTextArea;

export { TextArea };
