import { useField } from "formik";
import {
  DebugForm,
  DisplayFormErrors,
  SaveFormButton,
  SelectField,
  SwitchField,
  VerticalForm,
} from "melco-shared-logic";
import { Alert, FullWidthSpace } from "melco-ui";
import { useTranslation } from "react-i18next";

const ACTIVATION_REMINDER_EMAIL_SEPARATOR = ";";

export const DisplayLicenseActivationReminderForm: React.FC = () => {
  const { t } = useTranslation();
  const [enabledField] = useField("enabled");

  return (
    <VerticalForm>
      <DisplayFormErrors />

      <FullWidthSpace direction="vertical" size="middle">
        <Alert
          message={t("infrastructureLicenses.actions.activationReminder.note")}
          type="info"
          withBackground
          closable={false}
        />
        <div>
          <SwitchField
            name="enabled"
            label={t(
              "infrastructureLicenses.actions.activationReminder.form.label.enabled"
            )}
          />

          {/* FIXME */}
          <SelectField<any>
            mode="tags"
            name="email_list"
            open={false} //Make sure the dropdown is never shown because there are only user generated options possible
            tokenSeparators={[";", ","]}
            label={t(
              "infrastructureLicenses.actions.activationReminder.form.label.email_list"
            )}
            valueConverter={{
              toSelectValue: (value: string | undefined) => {
                if (!value) {
                  return; //Ignore empty values. SelectField will display empty tags otherwise.
                }
                return value.split(ACTIVATION_REMINDER_EMAIL_SEPARATOR);
              },
              toFormValue: (value: string[]) =>
                value.join(ACTIVATION_REMINDER_EMAIL_SEPARATOR),
            }}
            disabled={!enabledField.value}
            info={t(
              "infrastructureLicenses.actions.activationReminder.form.info.email_list"
            )}
          />

          <SaveFormButton />
        </div>
      </FullWidthSpace>

      {false && <DebugForm />}
    </VerticalForm>
  );
};
