import {
  isTrialExpired,
  isTrialProduct,
  MelcoCoreModelsUserProductListItem,
} from "melco-shared-logic";
import TrialExpiredModal from "./TrialExpiredModal";
import TrialActiveModal from "./TrialActiveModal";

type LaunchTrialAppModalProps = {
  product: MelcoCoreModelsUserProductListItem;
  open?: boolean;
  onLaunch: () => void;
  onCancel: () => void;
};

const LaunchTrialAppModal: React.FC<LaunchTrialAppModalProps> = ({
  product,
  open,
  onLaunch,
  onCancel,
}) => {
  if (!isTrialProduct(product)) {
    return null;
  }

  const trialExpired = isTrialExpired(product);

  return trialExpired ? (
    <TrialExpiredModal product={product} onOk={onCancel} open={open} />
  ) : (
    <TrialActiveModal
      product={product}
      onOk={onLaunch}
      onCancel={onCancel}
      open={open}
    />
  );
};

export default LaunchTrialAppModal;
