import React from "react";
import { Footer as AntdFooter } from "antd/es/layout/layout";
import styled from "styled-components";
import { Paragraph } from "../components/typography/Paragraph";
import { DesignTokens } from "../styles/design-tokens";

const { colors } = DesignTokens;

const FooterWrapper = styled(AntdFooter)`
  flex-grow: 0;
  text-align: center;
  border-top: 1px solid ${colors.gray.light};
  background-color: ${colors.gray.superLight};
`;

const NoMarginParagraph = styled(Paragraph)`
  && {
    margin-bottom: 0;
  }
`;

type FooterProps = {
  children: React.ReactNode;
};

const Footer: React.FC<FooterProps> = ({ children }) => (
  <FooterWrapper>
    <NoMarginParagraph size="small" alignment="center">
      {children}
    </NoMarginParagraph>
  </FooterWrapper>
);

export { Footer };
