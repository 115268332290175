import { Navigate, useLocation } from "react-router-dom";
import { TokenRestoreState } from "../../hooks/auth/useTokenRestore";
import { useCurrentUser } from "../../hooks/user/useCurrentUser";

type RequireAuthProps = {
  tokenRestoreState: TokenRestoreState;
  children?: React.ReactNode;
};

export const RequireAuth: React.FC<RequireAuthProps> = ({
  tokenRestoreState,
  children,
}) => {
  const location = useLocation();
  const currentUser = useCurrentUser();

  if (currentUser && children) {
    return <>{children}</>;
  }

  if (tokenRestoreState === TokenRestoreState.RestoreFailed) {
    return <Navigate to={"/login?redirectTo=" + location.pathname} replace />;
  }

  // TODO: Add loading indicator while trying to restore token
  return null;
};
