import MelcoAccountFlowLayout from "../../components/layout/MelcoAccountFlowLayout";

const Error404 = () => {
  return (
    <MelcoAccountFlowLayout>
      <p>404 Not Found</p>
    </MelcoAccountFlowLayout>
  );
};

export default Error404;
