import { Col, Table, TableActionBarRow, ValueOrFallback } from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  FormattedDate,
  MelcoCoreModelsUserSubscriptionListItem,
  Search,
  TableCellLink,
  useTableSelection,
} from "melco-shared-logic";
import { useLicenses } from "../../hooks/infrastructure/useLicenses";
import { useNavigate } from "react-router-dom";

const DETAIL_VIEW_PATH = "/infrastructure/licenses";

export const LicensesList: React.FC = () => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
  } = useLicenses();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { onRow } = useTableSelection<MelcoCoreModelsUserSubscriptionListItem>(
    (row) => {
      navigate([DETAIL_VIEW_PATH, row.id].join("/"));
    }
  );

  const columns = [
    {
      title: t("infrastructureLicenses.list.columns.display_name"),
      key: "display_name",
      dataIndex: "display_name",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsUserSubscriptionListItem) => (
        <ValueOrFallback
          value={item.display_name}
          render={(value) => (
            <TableCellLink to={[DETAIL_VIEW_PATH, item.id].join("/")}>
              {value}
            </TableCellLink>
          )}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("infrastructureLicenses.list.columns.product_name"),
      key: "product_name",
      dataIndex: "product_name",
      sorter: true,
    },
    {
      title: t("infrastructureLicenses.list.columns.product_version_number"),
      key: "product_version_number",
      dataIndex: "product_version_number",
      sorter: true,
    },
    {
      title: t("infrastructureLicenses.list.columns.product_level_name"),
      key: "product_level_name",
      dataIndex: "product_level_name",
      sorter: true,
    },
    {
      title: t("infrastructureLicenses.list.columns.assigned_user_name"),
      key: "assigned_user_name",
      dataIndex: "assigned_user_name",
      sorter: true,
    },
    {
      title: t("infrastructureLicenses.list.columns.activated_on"),
      key: "activated_on",
      dataIndex: "activated_on",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsUserSubscriptionListItem) => (
        <FormattedDate date={item.activated_on} />
      ),
    },
    {
      title: t("infrastructureLicenses.list.columns.updated_date"),
      key: "updated_date",
      dataIndex: "updated_date",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsUserSubscriptionListItem) => (
        <FormattedDate date={item.updated_date} />
      ),
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col xs={24} xl={17}>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        onRow={onRow}
        noDataMessage={t("infrastructureLicenses.list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
