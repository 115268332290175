import { Badge, BadgeProps } from "../badge/Badge";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { colors, spacings } = DesignTokens;

const StyledSecondaryNavigationMenuItemInner = styled.div`
  position: relative;
  background-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid ${colors.gray.middle};
`;

const StyledSecondaryNavigationMenuItemWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const StyledPrefix = styled.div`
  background-color: transparent;
  border-radius: 20px 0 0 20px;
  border: 1px solid ${colors.gray.middle};
  border-right: 0;
  min-width: 40px;
  max-width: 200px;
  height: 40px;
  padding: 0 ${spacings.xlarge}px 0 ${spacings.middle}px;
  margin: 0 -20px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: -4px;
  right: 0;
`;

type SecondaryNavigationMenuItemProps = {
  children?: React.ReactNode;
  badge?: Pick<BadgeProps, "status">["status"];
  prefix?: React.ReactNode;
};

export const SecondaryNavigationMenuItem: React.FC<
  SecondaryNavigationMenuItemProps
> = ({ badge, children, prefix, ...rest }) => {
  return (
    <StyledSecondaryNavigationMenuItemWrapper {...rest}>
      {prefix && <StyledPrefix>{prefix}</StyledPrefix>}
      <StyledSecondaryNavigationMenuItemInner>
        {children}
      </StyledSecondaryNavigationMenuItemInner>
      {badge && <StyledBadge status={badge} />}
    </StyledSecondaryNavigationMenuItemWrapper>
  );
};
