import React from "react";
import styled from "styled-components";
import { Col } from "../../grid/Col";
import { Space } from "../../space/Space";

const StyledBulkActionContainerCol = styled(Col)`
  text-align: right;
`;

type BulkActionContainerColProps = {
  children?: React.ReactNode;
};

export const BulkActionContainerCol: React.FC<BulkActionContainerColProps> = ({
  children,
}) => {
  return (
    <StyledBulkActionContainerCol flex="auto">
      <Space size="middle">{children}</Space>
    </StyledBulkActionContainerCol>
  );
};
