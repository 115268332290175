import { useCallback } from "react";
import { UserAccountApi } from "../../api";
import { parseAPIErrorResponse } from "../../helper/errorHandling/parseAPIErrorResponse";
import { redirectToAccount } from "../../helper/redirect/redirectHelper";
import {
  isTrialError,
  isTrialExpired,
  redirectAfterTrialError,
} from "../../helper/trial/trialHelper";
import { useBaseAPIConfig } from "../api/useAPIConfig";

type AppStartupOptions = {
  skipRedirectOnError?: boolean;
  skipTermsHandling?: boolean;
};

export const useAppStartup = (options?: AppStartupOptions) => {
  const { skipRedirectOnError = false, skipTermsHandling = false } =
    options ?? {};

  const baseApiConfig = useBaseAPIConfig();

  return useCallback(
    async (token: string, impersonationForId?: string) => {
      const api = new UserAccountApi(
        baseApiConfig({
          token,
          impersonationForId,
          skipRedirectToLoginIfUnauthorized: true,
        })
      );

      try {
        const response = await api.accountProductsStartUp({
          productCode: process.env.REACT_APP_PRODUCT_CODE!,
        });

        const { show_service_terms, trial } = response;

        if (show_service_terms && !skipTermsHandling) {
          redirectToAccount(
            `dashboard/terms/${process.env.REACT_APP_PRODUCT_CODE}`
          );

          return {
            success: false,
          };
        } else if (trial && isTrialExpired(trial)) {
          redirectAfterTrialError();

          return {
            success: false,
          };
        }

        return {
          success: true,
          permissions: response.permission_list ?? [],
          defaultLanguage: response.default_language ?? undefined,
        };
      } catch (error) {
        const apiErrors = await parseAPIErrorResponse(error);

        if (apiErrors) {
          if (isTrialError(apiErrors) && !skipRedirectOnError) {
            redirectAfterTrialError();

            return {
              success: false,
            };
          }
        }

        // just to be safe: in case there is any error, we don't want the user to being able to use the app. hence we rather redirect him back to the account app for now.
        if (!skipRedirectOnError) {
          redirectToAccount();
        }

        return {
          success: false,
        };
      }
    },
    [skipRedirectOnError, skipTermsHandling, baseApiConfig]
  );
};
