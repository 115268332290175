import { useCallback } from "react";
import { useLocalStorage } from "react-use";
import { AuthenticationApi } from "../../api";
import { MelcoAccountData, MELCO_ACCOUNT_KEY } from "./useTokenRestore";
import { useAuth } from "./useAuth";
import { useAPIAction } from "../api/useAPIAction";

type UseLogoutOptions = {
  dontResetUser?: boolean;
};

export const useLogout = () => {
  const auth = useAuth();
  const [, , removeMelcoAccountData] =
    useLocalStorage<MelcoAccountData>(MELCO_ACCOUNT_KEY);

  const [logoutOnServer, isLoading] = useAPIAction(
    AuthenticationApi,
    async (api) => {
      await api.authenticationLogoffMelco();
    },
    { translationPrefix: "global.logout" }
  );

  return [
    useCallback(
      async (options?: UseLogoutOptions) => {
        await logoutOnServer();

        removeMelcoAccountData();

        if (!options?.dontResetUser) {
          auth.changeCurrentUser(undefined);
        }
      },
      [auth, removeMelcoAccountData, logoutOnServer]
    ),
    isLoading,
  ] as const;
};
