import { SupportApi, useAPIAction, useConfirm } from "melco-shared-logic";
import {
  Alert,
  Button,
  FormSection,
  FullWidthSpace,
  Paragraph,
  StopIcon,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  SupportStatus,
  useSupportStatus,
} from "../../hooks/support/useSupportStatus";
import dayjs from "dayjs";

type DeactivateSupportProps = {
  supportStatus: SupportStatus;
  refetch: ReturnType<typeof useSupportStatus>["refetch"];
};

export const DeactivateSupport: React.FC<DeactivateSupportProps> = ({
  supportStatus,
  refetch,
}) => {
  const { t } = useTranslation();

  const [deactivateSupport, isLoading] = useAPIAction(
    SupportApi,
    async (api) => {
      await api.supportDeactivate();
      await refetch();
    },
    { translationPrefix: "global" }
  );

  const confirmDeactivateSupport = useConfirm(deactivateSupport as any, {
    translationPrefix: "accountsupportpage.access.deactivate",
  });

  const { activatedOn, minutes } = supportStatus;

  const accessUntilDate = dayjs(activatedOn ?? new Date()).add(
    minutes ?? 0,
    "minute"
  );

  return (
    <FormSection title={t("accountsupportpage.access.name")}>
      <Paragraph>{t("accountsupportpage.access.description")}</Paragraph>

      <FullWidthSpace direction="vertical" size="large">
        <Alert
          type="info"
          withBackground
          message={t("accountsupportpage.access.deactivate.note", {
            accessUntil: dayjs(accessUntilDate).format("L, LT"),
          })}
        />

        <Button
          type="primary"
          loading={isLoading}
          onClick={confirmDeactivateSupport}
          icon={<StopIcon />}
        >
          {t("accountsupportpage.access.deactivate.button")}
        </Button>
      </FullWidthSpace>
    </FormSection>
  );
};
