import { MelcoCoreModelsUsersServiceUser } from "melco-shared-logic";
import { CopyableTextWithLabel } from "melco-ui";
import { useTranslation } from "react-i18next";

type UserLoginProps = {
  user: MelcoCoreModelsUsersServiceUser;
};

export const UserLoginEmail: React.FC<UserLoginProps> = ({ user }) => {
  const { t } = useTranslation();

  const { email } = user;

  return (
    <CopyableTextWithLabel
      label={t("users.details.login.email.labels.email")}
      text={email ?? ""}
      onCopiedMessage={t("users.details.login.email.copy.success")}
      copyTooltip={t("users.details.login.email.copy.tooltip_copy")}
      copiedTooltip={t("users.details.login.email.copy.tooltip_copied")}
    />
  );
};
