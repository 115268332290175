import styled from "styled-components";
import { ColorHelper } from "../../../helper/ColorHelper";
import { DesignTokens } from "../../../styles/design-tokens";

const { getContrastToWhiteFromARGB, getAlphaFromARGB, argbToRGBA } =
  ColorHelper;
const { colors, spacings } = DesignTokens;

const StyledColorSwatch = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["size", "argb", "isLowContrastColor"].includes(prop),
})<StyledColorSwatchProps>`
  width: ${({ size }) => (size ? size : spacings.middle)}px;
  height: ${({ size }) => (size ? size : spacings.middle)}px;
  background: ${({ argb }) =>
    !argb &&
    `
    linear-gradient(to top left,
        rgba(255,0,0,0) 0%,
        rgba(255,0,0,0) calc(50% - 1px),
        rgba(255,0,0,1) 50%,
        rgba(255,0,0,0) calc(50% + 1px),
        rgba(255,0,0,0) 100%)
  `};
  display: inline-block;
  border-radius: 1px;
  vertical-align: text-bottom;
  border: ${({ isLowContrastColor }) =>
    isLowContrastColor ? `1px solid ${colors.gray.middle}` : "none"};
`;

type StyledColorSwatchProps = ColorSwatchProps & {
  isLowContrastColor?: boolean;
};

export type ColorSwatchProps = {
  argb?: string;
  size?: number;
};

const ColorSwatch = ({ argb, size }: ColorSwatchProps) => {
  const isLowContrastColor =
    getContrastToWhiteFromARGB(argb) <= 1.1 || getAlphaFromARGB(argb) <= 0.3;

  return (
    <StyledColorSwatch
      argb={argb}
      size={size}
      isLowContrastColor={isLowContrastColor}
      style={argb ? { backgroundColor: argbToRGBA(argb) } : {}}
    />
  );
};

export { ColorSwatch };
