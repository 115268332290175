import { Alert, Link } from "melco-ui";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useCurrentProductDetails } from "../../hooks/product/useCurrentProductDetails";
import { isTrialProduct } from "../../helper/trial/trialHelper";

dayjs.extend(relativeTime);

export const TrialAlert: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  const { data: product } = useCurrentProductDetails();

  if (!product || !isTrialProduct(product)) {
    return null;
  }

  const { info_url, expires_on } = product;

  return (
    <Alert
      type="info"
      withBackground
      message={
        <>
          {t("trial.alert.message", {
            fromNow: dayjs(expires_on).fromNow(),
          })}{" "}
          {info_url && (
            <Link underline href={info_url} target="_blank">
              {t("trial.alert.info")}
            </Link>
          )}
        </>
      }
      banner
      {...rest}
    />
  );
};
