import dayjs from "dayjs";

type FormattedDateProps = {
  date?: Date | null;
};

export const FormattedDate: React.FC<FormattedDateProps> = ({ date }) => {
  if (!date) {
    return null;
  }

  return <>{dayjs(date).format("L LTS")}</>;
};
