import React from "react";
import AntdSpin, { SpinProps as AntdSpinProps } from "antd/es/spin";

import { LoadingIcon } from "../icons/icons";

export type SpinProps = Omit<AntdSpinProps, "indicator">;

const Spin: React.FC<SpinProps> = (props) => {
  const antdLoadingIcon = <LoadingIcon spin />;
  return <AntdSpin indicator={antdLoadingIcon} {...props} />;
};

export { Spin };
