import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import { useCallback, useState } from "react";
import castArray from "lodash-es/castArray";
import isEmpty from "lodash-es/isEmpty";
import trim from "lodash-es/trim";
import isEqual from "lodash-es/isEqual";

const DEFAULT_PAGE_SIZE = 50;

export const useTable = <
  RecordType extends object = any,
  FiltersType extends object = any
>(
  paginationConfig?: TablePaginationConfig
) => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    ...paginationConfig,
  });
  const [sorter, setSorter] = useState<
    SorterResult<RecordType> | SorterResult<RecordType>[]
  >({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState<FiltersType | undefined>(undefined);

  const setTotal = (total: number | undefined) => {
    setPagination((currentPagination) => {
      return {
        ...currentPagination,
        total,
      };
    });
  };

  //TODO: Remove every searchQuery/searchquerychange with filters/filtersChange
  const searchQueryChange = useCallback(
    (query: string) => {
      if (trim(query) !== searchQuery) {
        setSearchQuery(trim(query));

        // go to page one when the search query changes
        if (pagination.current !== 1) {
          setPagination({
            ...pagination,
            current: 1,
          });
        }
      }
    },
    [searchQuery, setSearchQuery, pagination, setPagination]
  );

  const filtersChange = useCallback(
    (newFilters: FiltersType | undefined) => {
      if (!isEqual(filters, newFilters)) {
        setFilters(newFilters);
        // go to page one when the search query changes
        if (pagination.current !== 1) {
          setPagination({
            ...pagination,
            current: 1,
          });
        }
      }
    },
    [filters, setFilters, pagination, setPagination]
  );

  const onTableConfigChange = useCallback(
    (
      pagination: TablePaginationConfig,
      _filters: Record<string, FilterValue | null>,
      sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
      extra: TableCurrentDataSource<RecordType>
    ) => {
      if (extra.action === "sort" || extra.action === "filter") {
        // jump back to first page
        setPagination({
          ...pagination,
          current: 1,
        });
      } else {
        setPagination(pagination);
      }
      setSorter(sorter);
    },
    []
  );

  const sortBy = useCallback(() => {
    let sortBy = undefined;

    const sorters = castArray(sorter);
    if (!isEmpty(sorters)) {
      const firstSorter = sorters[0];
      if (firstSorter.order) {
        sortBy = `${firstSorter.columnKey}.${
          firstSorter.order === "ascend" ? "asc" : "desc"
        }`;
      }
    }

    return sortBy;
  }, [sorter]);

  return {
    onTableConfigChange,
    pagination,
    filters,
    filtersChange,
    sorter,
    setTotal,
    currentPage: pagination.current ?? 1,
    pageSize: pagination.pageSize ?? DEFAULT_PAGE_SIZE,
    sortBy,
    searchQuery,
    searchQueryChange,
  };
};
