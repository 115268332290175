import { useCallback } from "react";
import { useUserManagementAPI } from "../api/useUserManagementAPI";
import {
  MelcoCoreModelsUsersServiceUser,
  MelcoCoreModelsUsersUser,
  UserAccountApi,
  UserManagementApi,
} from "../../api";
import { useCurrentUser } from "./useCurrentUser";
import { useIsSubUser } from "./useIsSubUser";
import { useUserAccountAPI } from "../api/useUserAccountAPI";

export type UserDetails = {
  user_id: string;
  account_name: string;
  gender_code: string;
  first_name: string;
  last_name: string;
  company_name: string;
  phone: string;
  website: string;
  street1: string;
  postal_code: string;
  city: string;
  country_code: string;
  state_code: string;
};

type UserDetailsData = {
  user: UserDetails | undefined;
};

const initialValue = { user: undefined };

const userToUserDetailsData = (
  userId: string,
  user: MelcoCoreModelsUsersUser | MelcoCoreModelsUsersServiceUser
) => {
  const {
    gender_code,
    first_name,
    last_name,
    company_name,
    phone,
    website,
    address,
    account_name,
  } = user;

  return {
    user: {
      user_id: userId,
      account_name: account_name ?? "",
      gender_code: gender_code ?? "",
      first_name: first_name ?? "",
      last_name: last_name ?? "",
      company_name: company_name ?? "",
      phone: phone ?? "",
      website: website ?? "",
      street1: address?.street1 ?? "",
      postal_code: address?.postal_code ?? "",
      city: address?.city ?? "",
      country_code: address?.country_code ?? "",
      state_code: address?.state_code ?? "",
    },
  };
};

export const useUserDetails = () => {
  const currentUser = useCurrentUser();
  const isSubUser = useIsSubUser();

  // note: this is implemented a bit weird because make use of the loading and error
  // handling of the use...API hooks and we cannot call hooks conditionally

  const handleMasterUserRequest = useCallback(
    async (api: UserManagementApi) => {
      if (!currentUser || isSubUser) {
        return { user: undefined };
      }

      const user = await api.userGetUser({ id: currentUser.userId });

      return userToUserDetailsData(currentUser.userId, user);
    },
    [currentUser, isSubUser]
  );

  const handleSubUserRequest = useCallback(
    async (api: UserAccountApi) => {
      if (!currentUser || !isSubUser) {
        return { user: undefined };
      }

      const user = await api.userAccountGetById({ id: currentUser.userId });

      return userToUserDetailsData(currentUser.userId, user);
    },
    [currentUser, isSubUser]
  );

  const masterUserDetails = useUserManagementAPI<UserDetailsData>({
    initialValue,
    handleRequest: handleMasterUserRequest,
  });

  const subUserDetails = useUserAccountAPI<UserDetailsData>({
    initialValue,
    handleRequest: handleSubUserRequest,
  });

  return isSubUser ? subUserDetails : masterUserDetails;
};
