import { useAPIAction, UserAccountApi } from "melco-shared-logic";
import { Button } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const DeclineServiceTermsButton: React.FC = () => {
  const { productCode } = useParams<{ productCode: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [handleDecline, isLoading] = useAPIAction(
    UserAccountApi,
    async (api) => {
      await api.accountServiceTermsDecline({ productCode: productCode! });
      navigate("/dashboard");
    },
    { translationPrefix: "global" }
  );

  return (
    <Button loading={isLoading} onClick={handleDecline}>
      {t("dashboard.apps.legal_policy.cancel")}
    </Button>
  );
};

export default DeclineServiceTermsButton;
