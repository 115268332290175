import castArray from "lodash-es/castArray";

export class OldAPIError extends Error {
  readonly errorCodes: string[];
  readonly originalError: any;

  constructor(errorCodes: string | string[], originalError?: any) {
    const codesAsArray = castArray(errorCodes);

    super(codesAsArray.join(", "));

    this.errorCodes = codesAsArray;
    this.originalError = originalError;
  }
}
