import { Formik } from "formik";
import {
  useFormSubmit,
  Drawer as DrawerType,
  UserAccountApi,
  MelcoCoreModelsDRMUserSerialNumberReminder,
  MelcoCoreModelsDRMUserSerialNumber,
  useAuthenticatedAPIConfig,
} from "melco-shared-logic";
import { useQueryClient } from "react-query";
import { DisplayLicenseActivationReminderForm } from "./DisplayLicenseActivationReminderForm";
import { useLicenseActivationReminder } from "../../hooks/infrastructure/useLicenseActivationReminder";

type LicenseActivationReminderFormValueType = {
  email_list: string;
  enabled: boolean;
};

export const activationReminderToInitialValues = (
  activationReminder: MelcoCoreModelsDRMUserSerialNumberReminder
) => {
  const { enabled, email_list } = activationReminder;

  const initialValues: LicenseActivationReminderFormValueType = {
    email_list: email_list ?? "",
    enabled: enabled ?? false,
  };

  return initialValues;
};

type LicenseActivationReminderFormProps = {
  license: MelcoCoreModelsDRMUserSerialNumber;
  drawer: DrawerType;
};

export const LicenseActivationReminderForm: React.FC<
  LicenseActivationReminderFormProps
> = ({ drawer, license }) => {
  const { data: activationReminder, isLoading } = useLicenseActivationReminder(
    license.id!
  );
  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const queryClient = useQueryClient();

  const { closeDrawer } = drawer;

  const onSubmit = useFormSubmit<LicenseActivationReminderFormValueType>(
    async (values: LicenseActivationReminderFormValueType) => {
      const { enabled, email_list } = values;

      const api = new UserAccountApi(authenticatedApiConfig());

      await api.productLicensesPutReminder({
        userSubscriptionId: license.id!,
        melcoCoreModelsDRMUserSerialNumberReminder: {
          enabled,
          email_list,
        },
      });

      return { successMessages: [{ message: "default" }] };
    },
    {
      translationPrefix: "infrastructureLicenses.actions.activationReminder",
      onSuccess: () => {
        queryClient.invalidateQueries([
          "licenseActivationReminder",
          license.id,
        ]);
        closeDrawer();
      },
    }
  );

  if (isLoading || !activationReminder) {
    return null;
  }

  return (
    <Formik
      initialValues={activationReminderToInitialValues(activationReminder)}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <DisplayLicenseActivationReminderForm />
    </Formik>
  );
};
