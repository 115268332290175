import { Alert, DesignTokens } from "melco-ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const { spacings } = DesignTokens;

const StyledAlert = styled(Alert)`
  margin-bottom: ${spacings.middle}px;
`;

type ShowUsernameAlertProps = {
  isEmail: boolean;
};

export const ShowUsernameAlert: React.FC<ShowUsernameAlertProps> = ({
  isEmail,
}) => {
  const { t } = useTranslation();

  if (isEmail) {
    return null;
  }

  return (
    <StyledAlert
      type="warning"
      withBackground
      description={t("users.actions.add.form.hint.user_name")}
    />
  );
};
