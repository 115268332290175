import { useEffect } from "react";
import { useField } from "formik";
import { useUserDetails } from "melco-shared-logic";

export const useSetCountryOfCurrentUser = () => {
  const [{ value }, , { setValue }] = useField("country_code");

  const { user } = useUserDetails();

  useEffect(() => {
    if (user?.country_code && value !== user.country_code) {
      setValue(user.country_code);
    }
  }, [user?.country_code, value, setValue]);
};
