import { UserAccountApi, useAPIAction } from "melco-shared-logic";

export const useAssignLicense = (licenseId: string, userId: string) => {
  return useAPIAction(
    UserAccountApi,
    async (api) => {
      const data = await api.subscriptionAssign({
        userSubscriptionId: licenseId,
        melcoCoreModelsDRMUserSubscription: {
          new_user_reference_id: userId,
        },
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    { translationPrefix: "infrastructureLicenses.actions.assign" }
  );
};
