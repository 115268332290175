import {
  Culture,
  DisplayFormErrors,
  SelectField,
  VerticalForm,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";

type DisplayBasicSettingsFormProps = {
  cultures: Culture[];
};

export const DisplayBasicSettingsForm: React.FC<
  DisplayBasicSettingsFormProps
> = ({ cultures }) => {
  const { t } = useTranslation();

  return (
    <VerticalForm>
      <DisplayFormErrors />

      <SelectField
        name="default_language"
        label={t("settings.basic.form.label.default_language")}
        options={cultures.map((culture) => {
          return {
            label: culture.name,
            value: culture.code,
          };
        })}
        showSearch
        optionFilterProp="label"
        info={t("settings.basic.form.info.default_language")}
      />
    </VerticalForm>
  );
};
