import { useCallback } from "react";
import { UserAccountApi, useAPIAction } from "melco-shared-logic";

export const useDeleteUsers = () => {
  const [deleteUser] = useAPIAction(
    UserAccountApi,
    async (api, id: string) => {
      const data = await api.userAccountDelete({ id });

      return {
        successMessages: [{ message: "default" }],
        data,
      };
    },
    {
      translationPrefix: "users.actions.delete",
    }
  );

  return useCallback(
    async (userIds: string[]) => {
      const successfullyDeleted: string[] = [];

      for (const userId of userIds) {
        try {
          await deleteUser(userId);
          successfullyDeleted.push(userId);
        } catch (e) {
          // error handling is already done in useAPIAction
        }
      }

      return successfullyDeleted;
    },
    [deleteUser]
  );
};
