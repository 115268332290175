import isArray from "lodash-es/isArray";
import { isAPIResponse } from "../../hooks/shared";

export const parseAPIErrorResponse = async (error: any) => {
  // either the plain response object is thrown as an error, or it is wrapped by APIError (in which case we would need to access the originalError property)
  const apiError = error?.originalError ? error.originalError : error;

  if (isAPIResponse(apiError)) {
    const response: Response = apiError;
    try {
      const apiErrors = await response.clone().json();

      if (isArray(apiErrors)) {
        return apiErrors;
      }
    } catch {
      return;
    }
  }
};
