import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import snakeCase from "lodash-es/snakeCase";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Breadcrumb = keyof typeof breadcrumbToPath;

const breadcrumbToPath = {
  infrastructure: {
    path: "/infrastructure",
  },
  infrastructureLicenses: {
    path: "/infrastructure/licenses",
  },
  administration: {
    path: "/administration",
  },
  administrationUsers: {
    path: "/administration/users",
  },
  settings: {
    path: "/settings",
  },
  settingsBasic: {
    path: "/settings/basic",
  },
};

export const useBreadcrumb = (...breadcrumbs: Breadcrumb[]) => {
  const { t } = useTranslation();

  const routes = breadcrumbs.map((b) => {
    return {
      ...breadcrumbToPath[b],
      breadcrumbName: t(`main_menu.${snakeCase(b)}` as any),
    };
  });

  const itemRender: (route: Route) => React.ReactNode = (route: Route) => {
    return <Link to={route.path}>{route.breadcrumbName}</Link>;
  };

  return { routes, itemRender };
};
