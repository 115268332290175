import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

export const CanvasWorkingArea = styled.div`
  position: relative;

  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${spacings.xlarge}px;
  img {
    width: 100%;
    max-width: 440px;
  }
`;
