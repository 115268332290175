import { Button } from "melco-ui";
import { useTranslation } from "react-i18next";

type TrialExpiredModalOkButtonProps = {
  onClick: () => void;
};

const TrialExpiredModalOkButton: React.FC<TrialExpiredModalOkButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Button type="primary" onClick={onClick}>
      {t("trial.expired.cancel")}
    </Button>
  );
};

export default TrialExpiredModalOkButton;
