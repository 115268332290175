import styled from "styled-components";
import { Title } from "./Title";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

const PageTitle = styled(Title).attrs({ level: 3 })`
  && {
    margin-bottom: ${spacings.large}px;
  }
`;

export { PageTitle };
