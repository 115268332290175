import {
  MelcoCoreModelsProductListItem,
  useAPIAction,
  UserAccountApi,
} from "melco-shared-logic";
import { Button } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLaunchApp } from "../../hooks/product/useLaunchApp";

type AcceptServiceTermsButtonProps = {
  product: MelcoCoreModelsProductListItem;
};

const AcceptServiceTermsButton: React.FC<AcceptServiceTermsButtonProps> = ({
  product,
}) => {
  const { productCode } = useParams<{ productCode: string }>();
  const { t } = useTranslation();
  const [launchApp] = useLaunchApp(product);

  const [handleAcceptance, isLoading] = useAPIAction(
    UserAccountApi,
    async (api) => {
      await api.accountServiceTermsAccept({ productCode: productCode! });
      await launchApp();
    },
    { translationPrefix: "global" }
  );

  return (
    <Button type="primary" loading={isLoading} onClick={handleAcceptance}>
      {t("dashboard.apps.legal_policy.ok")}
    </Button>
  );
};

export default AcceptServiceTermsButton;
