import { useTranslation } from "react-i18next";
import {
  AppIcon,
  Badge,
  InfrastructureIcon,
  LockIcon,
  Menu,
  MenuItem,
  PhoneIcon,
  SettingIcon,
  SubMenu,
  ToolIcon,
  UserIcon,
} from "melco-ui";
import { useMainMenu, useRequirePermissions } from "melco-shared-logic";
import styled from "styled-components";
import { useSupportStatus } from "../../hooks/support/useSupportStatus";

const MenuItemWithOverflow = styled(MenuItem)`
  overflow: visible !important;

  & .ant-menu-title-content {
    overflow: visible !important;
  }
`;

const MenuItemWithBadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainMenu: React.FC = () => {
  const { t } = useTranslation();
  const { selectedKeys, onClick } = useMainMenu();

  const isAccessToInfrastructureAllowed = useRequirePermissions([
    "INFRASTRUCTURE",
    "MANAGE_LICENSES",
  ]);

  const isAccessToUserAdministrationAllowed = useRequirePermissions([
    "ADMINISTRATION",
    "MANAGE_USERS",
  ]);

  const isAccessToSupportAllowed = useRequirePermissions(["GRANT_ACCESS"]);

  const isAccessToAPIKeysAllowed = useRequirePermissions(["API_KEYS"]);

  const { data: supportStatus } = useSupportStatus();

  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      defaultOpenKeys={selectedKeys}
      onClick={onClick}
    >
      <MenuItem key="/dashboard" icon={<AppIcon />}>
        {t("main_menu.dashboard")}
      </MenuItem>

      {isAccessToInfrastructureAllowed && (
        <SubMenu
          key="/infrastructure"
          icon={<InfrastructureIcon />}
          title={t("main_menu.infrastructure")}
        >
          <MenuItem key="/infrastructure/licenses">
            {t("main_menu.infrastructure_licenses")}
          </MenuItem>
        </SubMenu>
      )}

      {isAccessToUserAdministrationAllowed && (
        <SubMenu
          key="/libraries"
          icon={<ToolIcon />}
          title={t("main_menu.administration")}
        >
          <MenuItem key="/administration/users">
            {t("main_menu.administration_users")}
          </MenuItem>
        </SubMenu>
      )}

      <SubMenu
        key="/settings"
        icon={<SettingIcon />}
        title={t("main_menu.settings")}
      >
        <MenuItem key="/settings/basic">
          {t("main_menu.settings_basic")}
        </MenuItem>

        {isAccessToAPIKeysAllowed && (
          <MenuItem key="/settings/api">{t("main_menu.settings_api")}</MenuItem>
        )}
      </SubMenu>

      <MenuItem key="/account/profile" icon={<UserIcon />}>
        {t("main_menu.profile")}
      </MenuItem>

      <MenuItem key="/account/login" icon={<LockIcon />}>
        {t("main_menu.login")}
      </MenuItem>

      {isAccessToSupportAllowed && (
        <MenuItemWithOverflow key="/account/support" icon={<PhoneIcon />}>
          <MenuItemWithBadgeWrapper>
            {t("main_menu.support")}

            {supportStatus?.isActive && (
              <Badge status="processing" data-cy-id="support-status-badge" />
            )}
          </MenuItemWithBadgeWrapper>
        </MenuItemWithOverflow>
      )}
    </Menu>
  );
};

export default MainMenu;
