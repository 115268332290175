import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { DisplayResetPasswordForm } from "./DisplayResetPasswordForm";
import {
  useBaseAPIConfig,
  useFlowFormSubmit,
  UserManagementApi,
  VerificationState,
} from "melco-shared-logic";

export type ResetPasswordFormValueType = {
  password: string;
  confirm_password: string;
};

const initialValues: ResetPasswordFormValueType = {
  password: "",
  confirm_password: "",
};

const ForgotPasswordFormSchema = Yup.object().shape({
  password: Yup.string().required("global.error.required.default"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required(),
});

type ResetPasswordFormProps = {
  verificationState: VerificationState;
  children?: React.ReactNode;
};

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  verificationState,
  children,
}) => {
  const navigate = useNavigate();
  const baseApiConfig = useBaseAPIConfig();
  const { eMail, code } = verificationState;

  const onSubmit = useFlowFormSubmit(
    async (
      values: ResetPasswordFormValueType,
      actions: FormikHelpers<ResetPasswordFormValueType>
    ) => {
      const { password } = values;

      // reset the error message when submitting
      actions.setStatus(undefined);

      const api = new UserManagementApi(baseApiConfig());

      const response = await api.userResetPassword({
        melcoCoreModelsUsersResetPassword: {
          code,
          new_password: password,
          user_name: eMail,
        },
      });

      if (response.result) {
        navigate("/?forgotpassword-success");
        return;
      } else {
        throw new Error();
      }
    },
    { translationPrefix: "forgotpasswordresetpage" }
  );

  if (!eMail || !code) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ForgotPasswordFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => <DisplayResetPasswordForm {...props} children={children} />}
    </Formik>
  );
};

export default ResetPasswordForm;
