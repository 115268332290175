import { ReactNode } from "react";
import { FormItem, Input, InputProps } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

type InputFieldProps = {
  name: string;
  label?: string;
  info?: ReactNode;
};

const InputField = (props: InputFieldProps & InputProps) => {
  const { t } = useTranslation();
  const { name, label, info, ...inputProps } = props;
  const [field, meta] = useField(name!);

  const hasFeedback = !!(meta.touched && meta.error);
  const help = meta.touched && meta.error && t(meta.error);
  const validateStatus = meta.touched && meta.error ? "error" : undefined;

  return (
    <FormItem
      help={hasFeedback ? help : undefined}
      validateStatus={validateStatus}
      label={label}
      info={info}
    >
      <Input {...inputProps} {...field} />
    </FormItem>
  );
};

export { InputField };
