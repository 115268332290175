import {
  useAuth,
  useAuthenticatedAPIConfig,
  useCurrentUser,
  useFormSubmit,
  UserAccountApi,
} from "melco-shared-logic";

export type BasicSettingsFormValueType = {
  default_language: string;
};

export const useBasicSettingsFormSubmit = () => {
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const auth = useAuth();
  const currentUser = useCurrentUser();

  const onSubmit = useFormSubmit<BasicSettingsFormValueType>(
    async (values: BasicSettingsFormValueType, { resetForm }) => {
      const { default_language } = values;

      const api = new UserAccountApi(authenticatedApiConfig());
      await api.userDefaultsUpdate({
        melcoCoreModelsUsersUserDefaults: {
          default_language,
        },
      });

      resetForm({ values });

      return { successMessages: [{ message: "default" }] };
    },
    {
      translationPrefix: "settings.basic",
      onSuccess: (values) => {
        if (currentUser) {
          auth.changeCurrentUser({
            ...currentUser,
            defaultLanguage: values.default_language,
          });
        }
      },
    }
  );

  return onSubmit;
};
