import { DesignTokens, Table, ValueOrFallback } from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  FormattedDate,
  MelcoCoreModelsDRMSerialNumberHistory,
} from "melco-shared-logic";
import { useLicenseHistory } from "../../hooks/infrastructure/useLicenseHistory";
import styled from "styled-components";

const { spacings } = DesignTokens;

const TableWrapper = styled.div`
  margin-left: -${spacings.xlarge}px;
  margin-right: -${spacings.xlarge}px;
`;

type LicensesHistoryListProps = {
  licenseId: string | undefined;
};

export const LicensesHistoryList: React.FC<LicensesHistoryListProps> = ({
  licenseId,
}) => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
  } = useLicenseHistory(licenseId);

  const { t } = useTranslation();

  const columns = [
    {
      title: t("infrastructureLicenses.history.columns.history_type"),
      key: "history_type",
      dataIndex: "history_type",
      render: (history_type: string) =>
        t(`infrastructureLicenses.history.history_type.${history_type}` as any),
    },
    {
      title: t("infrastructureLicenses.history.columns.user_name"),
      key: "user_name",
      dataIndex: "user_name",
      render: (user_name: string) => (
        <ValueOrFallback
          value={user_name}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("infrastructureLicenses.history.columns.computer_name"),
      key: "computer_name",
      dataIndex: "computer_name",
      render: (computer_name: string) => (
        <ValueOrFallback
          value={computer_name}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("infrastructureLicenses.history.columns.operating_system"),
      key: "operating_system",
      dataIndex: "operating_system",
      render: (operating_system: string) => (
        <ValueOrFallback
          value={operating_system}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("infrastructureLicenses.history.columns.version_number"),
      key: "version_number",
      dataIndex: "version_number",
      render: (version_number: string) => (
        <ValueOrFallback
          value={version_number}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("infrastructureLicenses.history.columns.created_date"),
      key: "created_date",
      dataIndex: "created_date",
      render: (_: string, item: MelcoCoreModelsDRMSerialNumberHistory) => (
        <FormattedDate date={item.created_date} />
      ),
    },
  ];

  return (
    <TableWrapper>
      <Table
        data-cy-id="license-history"
        showSorterTooltip={false}
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={false}
        rowKey="created_date"
        noDataMessage={t("infrastructureLicenses.history.empty")}
        errorComponent={errorComponent}
      />
    </TableWrapper>
  );
};
