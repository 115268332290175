import { ConfigProviderProps } from "melco-ui";
import { useAntdLocale } from "../translation/useAntdLocale";

export const useConfigProvider = (): ConfigProviderProps => {
  const locale = useAntdLocale();

  return {
    locale,
  };
};
