import { useTranslation } from "react-i18next";
import { FlowBoxButton, Paragraph, FlowBox, FlowBoxTitle } from "melco-ui";
import { Form, FormikProps } from "formik";
import { ForgotPasswordFormValueType } from "./ForgotPasswordForm";
import { InputField, DisplayFormErrors } from "melco-shared-logic";
import { DisplayFormStatusMessages } from "../form/DisplayFormStatusMessages";

export const DisplayForgotPasswordForm: React.FC<
  FormikProps<ForgotPasswordFormValueType>
> = ({ isSubmitting }) => {
  const { t } = useTranslation();

  return (
    <>
      <DisplayFormStatusMessages />

      <DisplayFormErrors />

      <FlowBox>
        <FlowBoxTitle>{t("forgotpasswordpage.box_title")}</FlowBoxTitle>

        <Paragraph>{t("forgotpasswordpage.box_text")}</Paragraph>

        <Form>
          <InputField
            name="username"
            size="large"
            placeholder={t("global.form.label.profile.email")}
            type="email"
            autoComplete="username"
          />

          <FlowBoxButton
            loading={isSubmitting}
            disabled={isSubmitting}
            htmlType="submit"
          >
            {t("forgotpasswordpage.form.submit")}
          </FlowBoxButton>
        </Form>
      </FlowBox>
    </>
  );
};
