import { useDefaultsAPI } from "../api/useDefaultsAPI";
import { SystemDefaultsApi } from "../../api";

export type Country = {
  name: string;
  code: string;
  doubleOptIn: boolean;
  defaultCurrencyCode: string;
};

type CountriesData = {
  countries: Country[];
};

const initialValue = { countries: [] };

const handleRequest = async (api: SystemDefaultsApi) => {
  const response = await api.defaultsGetCountryList();

  const countries = (response.list ?? []).map(
    ({ name, code, default_currency_code, double_opt_in }) => {
      return {
        name: name ?? "",
        code: code ?? "",
        doubleOptIn: double_opt_in ?? true,
        defaultCurrencyCode: default_currency_code ?? "",
      };
    }
  );

  return {
    countries,
  };
};

export const useCountries = () => {
  return useDefaultsAPI<CountriesData>({
    initialValue,
    handleRequest,
  });
};
