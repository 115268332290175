import { useField, useFormikContext } from "formik";
import { MelcoCoreModelsPermissionsListItem } from "melco-shared-logic";
import { useEffect } from "react";
import { PermissionsMap } from "./UserPermissions";

type DerivedPermissionProps = {
  productCode: string;
  permission: MelcoCoreModelsPermissionsListItem;
  childPermissions: MelcoCoreModelsPermissionsListItem[];
};

// derive parent permission by the child permission states
export const DerivedPermission: React.FC<DerivedPermissionProps> = ({
  productCode,
  permission,
  childPermissions,
}) => {
  const [{ value: permissions }] = useField<PermissionsMap>(
    `user_product_access.${productCode}.permissions`
  );

  const { setFieldValue } = useFormikContext();

  const { code } = permission;

  const childPermissionsActiveStates = childPermissions.map(
    (cp) => Object.values(permissions).find((p) => p.code === cp.code)?.active
  );

  useEffect(() => {
    const checkedCount = childPermissionsActiveStates.filter(
      (state) => state
    ).length;

    setFieldValue(
      `user_product_access.${productCode}.permissions.${code}.active`,
      checkedCount > 0
    );
  }, [childPermissionsActiveStates.join(), setFieldValue]);

  return null;
};
