import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ApplicationLayout, Divider, Space } from "melco-ui";
import AccountMainNavigation from "../navigation/AccountMainNavigation";
import AccountSecondaryNavigation from "../navigation/AccountSecondaryNavigation";
import AccountsServiceTermModal from "../serviceterms/AccountsServiceTermModal";

type MelcoAccountApplicationLayoutProps = {
  children?: React.ReactNode;
};

const MelcoAccountApplicationLayout: React.FC<
  MelcoAccountApplicationLayoutProps
> = ({ children }) => {
  const [isServiceTermsModalShown, setServiceTermsModalShown] = useState(false);

  const { t } = useTranslation();

  const showDefaultServiceTerms = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      setServiceTermsModalShown(true);
    },
    [setServiceTermsModalShown]
  );

  const showPrivacyAndCookiePolicy = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      // show privacy policy
      (window as any).sp?.showPrivacyBanner();
    },
    []
  );

  return (
    <>
      <ApplicationLayout
        mainNavigation={<AccountMainNavigation />}
        footer={
          <Space size="small">
            {t("footer.copyright", {
              copyrightYear: new Date().getFullYear(),
            })}

            <Divider type="vertical" />

            <Link to="#" onClick={showDefaultServiceTerms}>
              {t("footer.license_agreement")}
            </Link>

            <AccountsServiceTermModal
              isShown={isServiceTermsModalShown}
              show={setServiceTermsModalShown}
            />

            <Divider type="vertical" />

            <Link to="#" onClick={showPrivacyAndCookiePolicy}>
              {t("footer.privacy_cookie_policy")}
            </Link>
          </Space>
        }
      >
        {children}
        <AccountSecondaryNavigation />
      </ApplicationLayout>
    </>
  );
};

export default MelcoAccountApplicationLayout;
