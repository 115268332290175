import { LinkProps, useHref, useLinkClickHandler } from "react-router-dom";
import { FullSizeTableCellLink } from "melco-ui";

export const TableCellLink: React.FC<LinkProps> = ({
  type,
  onClick,
  replace = false,
  state,
  target,
  to,
  ...rest
}) => {
  const href = useHref(to);
  const handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
  });

  return (
    <FullSizeTableCellLink
      {...rest}
      href={href}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.stopPropagation(); // Make sure the onrow click will not be fired

        onClick?.(event);

        if (!event.defaultPrevented) {
          handleClick(event);
        }
      }}
      target={target}
    />
  );
};
