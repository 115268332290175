import React from "react";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";
import { DragHandleIcon } from "../icons/icons";
import { ElevationHelper } from "../../helper/ElevationHelper";

const { colors, borders, spacings } = DesignTokens;
const { createBoxShadowCSSString } = ElevationHelper;

const DraggableItemWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  cursor: pointer;

  margin: ${spacings.xsmall}px;

  background-color: ${colors.gray.superLight};
  box-shadow: ${createBoxShadowCSSString({ level: 1 })};
  box-sizing: border-box;
  border-radius: ${borders.radius.normal}px;
  border: 1px solid ${colors.border.middle};
`;

const DraggableContent = styled.div`
  border-right: 1px solid ${colors.border.middle};
  padding: ${spacings.xsmall}px;
  line-height: normal;
`;

const StyledDragHandleIcon = styled(DragHandleIcon)`
  padding: ${spacings.xsmall}px;

  color: ${colors.main.disabled};

  ${DraggableItemWrapper}:hover &,
  ${DraggableItemWrapper}:active & {
    color: ${colors.main.positive};
  }
`;

const DraggableItem = React.forwardRef<HTMLDivElement, any>(
  ({ children, ...rest }, ref) => (
    <DraggableItemWrapper ref={ref} {...rest}>
      <DraggableContent>{children}</DraggableContent>
      <StyledDragHandleIcon />
    </DraggableItemWrapper>
  )
);

export { DraggableItem };
