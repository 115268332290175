import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Alert } from "melco-ui";

const LoginFormStatusMessage: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  if (location.search.includes("forgotpassword-success")) {
    return (
      <Alert type="success" message={t("loginpage.success.passwordreset")} />
    );
  } else if (location.search.includes("registration-success")) {
    return (
      <Alert
        type="success"
        message={t("loginpage.success.registrationverified")}
      />
    );
  } else if (location.search.includes("logout-success")) {
    return <Alert type="success" message={t("loginpage.success.logout")} />;
  } else if (location.search.includes("expired")) {
    return <Alert type="error" message={t("loginpage.error.expired")} />;
  }

  return null;
};

export default LoginFormStatusMessage;
