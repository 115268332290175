import { SelectField } from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { useRoles } from "../../hooks/product/useRoles";

export const RoleSelect: React.FC = () => {
  const { t } = useTranslation();
  const { data: roles, isLoading } = useRoles();

  const roleOptions = (roles ?? []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return (
    <SelectField
      name="role.id"
      label={t("users.details.permissions.labels.role")}
      options={roleOptions}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      disabled={isLoading}
    />
  );
};
