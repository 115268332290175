import { FlowLayout, HeaderLogo } from "melco-ui";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.svg";

type MelcoAccountFlowLayoutProps = {
  children?: React.ReactNode;
};

const MelcoAccountFlowLayout: React.FC<MelcoAccountFlowLayoutProps> = ({
  children,
}) => {
  return (
    <FlowLayout
      header={
        <Link to="/">
          <HeaderLogo src={logo} alt="Logo" data-cy="header-logo" />
        </Link>
      }
    >
      {children}
    </FlowLayout>
  );
};

export default MelcoAccountFlowLayout;
