import { Button, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";

type AddLicenseButtonProps = {
  onClick: () => void;
};

export const AddLicenseButton: React.FC<AddLicenseButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      key="add-license"
      type="primary"
      icon={<PlusIcon />}
      onClick={onClick}
    >
      {t("infrastructureLicenses.actions.add.button")}
    </Button>
  );
};
