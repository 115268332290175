import { MainNavigation } from "melco-ui";

import Logo from "../../assets/logo.svg";
import LogoCollapsed from "../../assets/logo_collapsed.svg";
import MainMenu from "./MainMenu";

const AccountMainNavigation: React.FC = () => {
  return (
    <MainNavigation
      appName="Account"
      logo={Logo}
      logoCollapsed={LogoCollapsed}
      mainMenu={<MainMenu />}
    />
  );
};

export default AccountMainNavigation;
