import { APIOptionsWithoutAPIClass, useAPI } from "./useAPI";
import { UserManagementApi } from "../../api";

export const useUserManagementAPI = <T>({
  initialValue,
  handleRequest,
}: APIOptionsWithoutAPIClass<T, UserManagementApi>) => {
  return useAPI<T, UserManagementApi>({
    initialValue,
    APIClass: UserManagementApi,
    handleRequest,
  });
};
