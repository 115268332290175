import { Formik } from "formik";
import * as Yup from "yup";
import {
  CurrentUser,
  UserManagementApi,
  useAuth,
  useAuthenticatedAPIConfig,
  useFormSubmit,
  useUserDetails,
} from "melco-shared-logic";
import { Modal } from "melco-ui";
import SkipAddAccountNameButton from "./SkipAddAccountNameButton";
import AddAccountNameButton from "./AddAccountNameButton";
import { useTranslation } from "react-i18next";
import { DisplayAddAccountNameForm } from "./DisplayAddAccountNameForm";
import { useState } from "react";
import { transformProfileFormValues } from "../../profile/ProfileForm";

export type AddAccountNameFormValueType = {
  account_name: string;
};

type DisplayAddAccountNameModalProps = {
  currentUser: CurrentUser;
};

const AddAccountNameFormSchema = Yup.object().shape({
  account_name: Yup.string().required("global.error.required.default"),
});

export const DisplayAddAccountNameModal: React.FC<
  DisplayAddAccountNameModalProps
> = ({ currentUser }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { t } = useTranslation();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const { user, isLoading } = useUserDetails();
  const auth = useAuth();

  const onSubmit = useFormSubmit(
    async (values: AddAccountNameFormValueType) => {
      const { account_name } = values;

      const api = new UserManagementApi(authenticatedApiConfig());

      //Use the original user object and only update account_name
      const editValues = transformProfileFormValues({
        ...user!,
        account_name,
      });

      await api.userEdit({
        id: user!.user_id,
        melcoCoreModelsUsersUser: editValues,
      });

      return {
        successMessages: [
          {
            message: "saved",
          },
        ],
      };
    },
    {
      translationPrefix: "dashboard.add_account_name",
      onSuccess: (values) => {
        auth.changeCurrentUser({
          ...currentUser,
          accountName: values.account_name,
        });
        setIsOpen(false);
      },
    }
  );

  const wrapClassName = `add-account-name`;

  if (isLoading || !user) {
    return null;
  }

  const initialValues: AddAccountNameFormValueType = {
    account_name: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={AddAccountNameFormSchema}
    >
      <Modal
        title={t("dashboard.add_account_name.title")}
        open={isOpen}
        destroyOnClose
        centered
        closable={false}
        wrapClassName={wrapClassName}
        footer={[
          <SkipAddAccountNameButton onClick={() => setIsOpen(false)} />,
          <AddAccountNameButton />,
        ]}
      >
        <DisplayAddAccountNameForm />
      </Modal>
    </Formik>
  );
};
