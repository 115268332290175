import {
  MelcoCoreModelsUserProductListItem,
  useAPIAction,
  UserAccountApi,
} from "melco-shared-logic";

export const useRequestDemo = (product: MelcoCoreModelsUserProductListItem) => {
  return useAPIAction(
    UserAccountApi,
    async (api) => {
      const { code } = product;

      const response = await api.accountProductsRequestDemo({
        productCode: code!,
      });

      if (!response.result) {
        throw new Error();
      }
    },
    { translationPrefix: "dashboard.request_demo" }
  );
};
