import {
  MelcoCoreModelsDRMSerialNumberHistory,
  useAPIQuery,
  UserAccountApi,
  useTable,
} from "melco-shared-logic";
import { useTableErrorComponent } from "../table/useTableErrorComponent";
import { orderBy } from "lodash-es";

const activatedOrDeactivated = (
  item: MelcoCoreModelsDRMSerialNumberHistory
) => {
  const { history_type } = item;

  const activated = history_type === 6; // VIRTUAL_LOGIN
  const deactivated = history_type === 7; // VIRTUAL_LOGOUT

  return activated || deactivated;
};

export const useLicenseHistory = (licenseId: string | undefined) => {
  const { onTableConfigChange, setTotal } =
    useTable<MelcoCoreModelsDRMSerialNumberHistory>();

  const query = useAPIQuery(
    UserAccountApi,
    ["licenseHistory", licenseId],
    async (api) => {
      if (licenseId == null) {
        return [];
      }

      const { list } = await api.subscriptionGetHistory({
        userSubscriptionId: licenseId,
      });

      const historyItems = list.filter(activatedOrDeactivated);

      setTotal(historyItems.length);

      return orderBy(historyItems, ["created_date"], ["desc"]);
    },
    {
      queryOptions: {
        keepPreviousData: true,
      },
    }
  );

  const errorComponent = useTableErrorComponent(query.error, query.refetch);

  return {
    onTableConfigChange,
    setTotal,
    errorComponent,
    ...query,
  };
};
