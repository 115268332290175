import { useTranslation } from "react-i18next";
import { Button, FormRow, FormSection, Paragraph, SaveIcon } from "melco-ui";
import { FormikProps } from "formik";
import {
  InputField,
  VerticalForm,
  DisplayFormErrors,
  useIsFormChanged,
} from "melco-shared-logic";
import { ChangePasswordFormValueType } from "./ChangePasswordForm";

export const DisplayChangePasswordForm: React.FC<
  FormikProps<ChangePasswordFormValueType>
> = ({ isSubmitting }) => {
  const { t } = useTranslation();
  const isFormChanged = useIsFormChanged();

  return (
    <VerticalForm>
      <FormSection title={t("accountloginpage.tab_2.name")}>
        <DisplayFormErrors />

        <InputField
          name="current_password"
          size="large"
          label={t("global.form.label.profile.current_password")}
          type="password"
          autoComplete="current-password"
        />

        <InputField
          name="new_password"
          size="large"
          label={t("global.form.label.profile.new_password")}
          type="password"
          autoComplete="new-password"
        />

        <InputField
          name="new_password_confirmation"
          size="large"
          label={t("global.form.label.profile.confirm_new_password")}
          type="password"
          autoComplete="new-password"
        />

        <Paragraph size="small" type="secondary">
          {t("global.hint.password_requirements")}
        </Paragraph>

        <FormRow justify="end">
          <Button
            type="primary"
            htmlType="submit"
            icon={<SaveIcon />}
            loading={isSubmitting}
            disabled={isSubmitting || !isFormChanged}
          >
            {t("accountloginpage.tab_2.submit")}
          </Button>
        </FormRow>
      </FormSection>
    </VerticalForm>
  );
};
