import { useCallback } from "react";
import { useDefaultsAPI } from "../api/useDefaultsAPI";
import { SystemDefaultsApi } from "../../api";

export type State = {
  name: string;
  code: string;
};

type StateData = {
  states: State[];
};

const initialValue = { states: [] };

export const useStates = (countryCode: string) => {
  const handleRequest = useCallback(
    async (api: SystemDefaultsApi) => {
      const response = await api.defaultsGetStateList({
        countryList: countryCode,
      });

      const states = (response.list ?? []).map(({ code, name }) => {
        return {
          name: name ?? "",
          code: code ?? "",
        };
      });

      return {
        states,
      };
    },
    [countryCode]
  );

  return useDefaultsAPI<StateData>({
    initialValue,
    handleRequest,
  });
};
