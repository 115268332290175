import { useTranslation } from "react-i18next";
import { SelectField } from "melco-shared-logic";
import { useField } from "formik";
import sortBy from "lodash-es/sortBy";
import { useAPIKeyScopes } from "../../hooks/product/useAPIKeyScopes";

export const SelectAPIKeyScopeField: React.FC = () => {
  const { t } = useTranslation();

  const [{ value: productCode }] = useField("product_code");

  const { data: scopes, isLoading } = useAPIKeyScopes(productCode);

  const unsortedOptions = (scopes ?? []).map(({ code, scope_id, name }) => {
    return {
      label: t(`api_list.scope.${name}` as string, name ?? code ?? ""),
      value: scope_id ?? "",
    };
  });

  if (!productCode) {
    return null;
  }

  const options = sortBy(unsortedOptions, ["label"], ["asc"]);

  return (
    <SelectField
      name="scope_id"
      label={t("api_list.actions.add.form.label.scope_id")}
      options={options}
      optionFilterProp="label"
      showSearch
      loading={isLoading}
    />
  );
};
