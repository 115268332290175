import { APIOptionsWithoutAPIClass, useAPI } from "./useAPI";
import { SystemManagementApi } from "../../api";

export const useSystemManagementAPI = <T>({
  initialValue,
  handleRequest,
}: APIOptionsWithoutAPIClass<T, SystemManagementApi>) => {
  return useAPI<T, SystemManagementApi>({
    initialValue,
    APIClass: SystemManagementApi,
    handleRequest,
  });
};
