import {
  SystemManagementApi,
  useSystemManagementAPI,
} from "melco-shared-logic";
import { useCallback } from "react";
import sanitizeHtml from "sanitize-html";

export type ServiceTermType = "html" | "url";

export type ServiceTerm = {
  tags: string;
  htmlContent: string;
  type: ServiceTermType;
};

type ServiceTermData = {
  serviceTerms: ServiceTerm[];
};

const sanitized = (input?: string | null) => {
  if (!input) {
    return "";
  }

  return sanitizeHtml(input, {
    allowedTags: [
      "address",
      "article",
      "aside",
      "footer",
      "header",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "blockquote",
      "dd",
      "div",
      "dl",
      "dt",
      "hr",
      "li",
      "ol",
      "p",
      "pre",
      "ul",
      "a",
      "abbr",
      "b",
      "bdi",
      "bdo",
      "br",
      "cite",
      "em",
      "i",
      "mark",
      "q",
      "rb",
      "rp",
      "rt",
      "rtc",
      "small",
      "span",
      "strong",
      "sub",
      "sup",
      "time",
      "u",
      "var",
      "wbr",
      "caption",
      "col",
      "colgroup",
      "table",
      "tbody",
      "td",
      "tfoot",
      "th",
      "thead",
      "tr",
    ],
    allowedAttributes: {
      a: ["href", "name", "target"],
    },
    allowedSchemes: ["http", "https", "mailto"],
  });
};

const initialValue = { serviceTerms: [] };

export const useServiceTerm = (productCode: string) => {
  const handleRequest = useCallback(
    async (api: SystemManagementApi) => {
      const response = await api.productsDocument({
        id: "serviceterms",
        productCode,
        lang: "en",
      });

      const serviceTerms = (response.list ?? []).map((doc) => {
        const { content, tags, type } = doc;
        const htmlContent = sanitized(content);

        return {
          tags: tags ?? "",
          htmlContent,
          type: (type ?? "html") as ServiceTermType,
        };
      });

      return {
        serviceTerms,
      };
    },
    [productCode]
  );

  return useSystemManagementAPI<ServiceTermData>({
    initialValue,
    handleRequest,
  });
};
