import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { DisplayRegisterForm } from "./DisplayRegisterForm";
import {
  useBaseAPIConfig,
  useFlowFormSubmit,
  UserManagementApi,
  VerificationState,
} from "melco-shared-logic";
import { EmailAvailabilityState } from "./EmailFieldWithAvailabilityCheck";

export type RegisterFormValueType = {
  first_name: string;
  last_name: string;
  country_code: string;
  user_name: string;
  password: string;
  confirm_password: string;
  isEmailAvailable: EmailAvailabilityState;
};

const initialValues: RegisterFormValueType = {
  first_name: "",
  last_name: "",
  country_code: "",
  user_name: "",
  password: "",
  confirm_password: "",
  isEmailAvailable: EmailAvailabilityState.Unknown,
};

const RegisterFormSchema = Yup.object().shape({
  user_name: Yup.string()
    .email("global.error.shouldbe.email")
    .required("global.error.required.default")
    .test(
      "isNotTaken",
      "registrationpage.error.user_name_exists",
      (_, context) =>
        context.parent.isEmailAvailable !== EmailAvailabilityState.Taken
    ),
  first_name: Yup.string().required("global.error.required.default"),
  last_name: Yup.string().required("global.error.required.default"),
  country_code: Yup.string().required("global.error.required.default"),
  password: Yup.string().required("global.error.required.default"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "global.error.match.password")
    .required("global.error.required.default"),
});

type RegisterFormProps = {
  verificationState: VerificationState;
};

export const RegisterForm: React.FC<RegisterFormProps> = ({
  verificationState,
}) => {
  const baseApiConfig = useBaseAPIConfig();
  const navigate = useNavigate();
  const { setEMail } = verificationState;

  const onSubmit = useFlowFormSubmit(
    async (values: RegisterFormValueType) => {
      const { user_name, first_name, last_name, country_code, password } =
        values;

      const api = new UserManagementApi(baseApiConfig());

      const success = await api.userRegister({
        melcoCoreModelsUsersUser: {
          user_name,
          email: user_name,
          password,
          first_name,
          last_name,
          address: {
            country_code,
          },
          service_terms: true, // implicit consent by registering
        },
      });

      if (success) {
        setEMail(user_name);
        navigate("/register/verify");
      } else {
        throw new Error();
      }
    },
    { translationPrefix: "registrationpage" }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegisterFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => <DisplayRegisterForm {...props} />}
    </Formik>
  );
};
