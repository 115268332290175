const spacings = {
  xlarge: 32,
  large: 24,
  middle: 16,
  small: 8,
  xsmall: 4,
};

const borders = {
  width: {
    normal: 1,
  },
  radius: {
    normal: 1,
  },
};

const fonts = {
  sizes: {
    xlarge: 18,
    large: 16,
    base: 14,
    small: 12,
    superSmall: 10,
    headings: {
      xxxxlarge: 49,
      xxxlarge: 38,
      xxlarge: 30,
      xlarge: 24,
      large: 20,
      base: 16,
    },
  },
  sansSerif: "Helvetica Neue LT W05_55 Roman",
  sansSerifLt: "Helvetica Neue LT W05_45 Light",
  sansSerifMd: "Helvetica Neue LT W05_65 Medium",
  sansSerifBd: "Helvetica Neue LT W01_75 Bold",
};

const colors = {
  main: {
    negative: "#FFFFFF",
    positive: "#1F1F1F",
    primary: "#EB0000",
    disabled: "#BFBFBF",
    secondary: "#8C8C8C",
  },
  gray: {
    light: "#F0F0F0",
    superLight: "#FAFAFA",
    middle: "#D9D9D9",
    dark: "#1F1F1F",
  },
  feedback: {
    success: "#60C325",
    warning: "#FAAD14",
    info: "#00A8EB",
    error: "#EB0000",
  },
  state: {
    draft: "#BDBDBD",
    unpublished: "#FF9900",
    published: "#009E0F",
  },
  border: {
    dark: "#1F1F1F",
    middle: "#D9D9D9",
    light: "#F0F0F0",
    superLight: "#FAFAFA",
    active: "#EB0000",
  },
  link: {
    default: "#1F1F1F",
    hover: "rgba(31, 31, 31, 0.65)",
  },
};

const elevations = {
  level1: {
    zIndex: 1,
    shadow: "0 2px 20px -15px rgba(0, 0, 0, 0.5)",
  },
  level2: {
    zIndex: 2,
    shadow: "0 2px 20px -10px rgba(0, 0, 0, 0.8)",
  },
  sided: {
    left: {
      level1: {
        zIndex: 1,
        shadow: "-6px 0 12px -12px rgba(0,0,0,0.25)",
      },
      level2: {
        zIndex: 2,
        shadow: "-11px 0 12px -12px rgba(0,0,0,0.3)",
      },
    },
    right: {
      level1: {
        zIndex: 1,
        shadow: "6px 0 12px -12px rgba(0,0,0,0.25)",
      },
      level2: {
        zIndex: 2,
        shadow: "11px 0 12px -12px rgba(0,0,0,0.3)",
      },
    },
    bottom: {
      level1: {
        zIndex: 1,
        shadow: "0 8px 12px -12px rgba(0,0,0,0.2)",
      },
      level2: {
        zIndex: 2,
        shadow: "0 12px 12px -12px rgba(0,0,0,0.35)",
      },
    },
  },
};

const animations = {
  durations: {
    slow: 0.3,
    base: 0.2,
    fast: 0.1,
  },
  easing: {
    in: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
    out: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    inOut: "cubic-bezier(0.645, 0.045, 0.355, 1)",
  },
};

const sizes = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

const dimensions = {
  sider: 216,
};

const DesignTokens = {
  spacings,
  borders,
  fonts,
  colors,
  elevations,
  animations,
  sizes,
  dimensions,
};

export { DesignTokens };
