import {
  DebugForm,
  DisplayFieldErrors,
  DisplayFormErrors,
  DrawerFooterContent,
  SaveFormButton,
  VerticalForm,
  ViewContext,
} from "melco-shared-logic";
import { FormRow, FormSection, Row } from "melco-ui";
import { useTranslation } from "react-i18next";
import { ManageUserProductAccessOrShowAdminHint } from "./ManageUserProductAccessOrShowAdminHint";
import { RoleSelect } from "./RoleSelect";

type DisplayUserPermissionsFormProps = {
  viewContext?: ViewContext;
};

export const DisplayUserPermissionsForm: React.FC<
  DisplayUserPermissionsFormProps
> = ({ viewContext }) => {
  const { t } = useTranslation();

  return (
    <VerticalForm>
      <div data-cy-id="permissions">
        <DisplayFormErrors />

        <FormSection title={t("users.details.permissions.role")}>
          <DisplayFieldErrors name="role" />

          <RoleSelect />
        </FormSection>

        <FormSection title={t("users.details.permissions.title")}>
          <DisplayFieldErrors name="user_product_access" />

          <ManageUserProductAccessOrShowAdminHint />
        </FormSection>

        {viewContext === ViewContext.Drawer ? (
          <DrawerFooterContent>
            <Row justify="end">
              <SaveFormButton
                title={t("users.actions.add.setPermissions")}
                disableDirtyTracking
              />
            </Row>
          </DrawerFooterContent>
        ) : (
          <FormRow justify="end">
            <SaveFormButton />
          </FormRow>
        )}

        {false && <DebugForm />}
      </div>
    </VerticalForm>
  );
};
