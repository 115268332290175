import antdNotification from "antd/es/notification";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

const notification = antdNotification;

const NotificationList = styled.ul`
  padding: 0 0 0 ${spacings.middle}px;
  margin: 0;
`;

export { notification, NotificationList };
