import { useTranslation } from "react-i18next";
import { Button, FormRow, FormSection, Paragraph, PlayIcon } from "melco-ui";
import { FormikProps } from "formik";
import {
  VerticalForm,
  DisplayFormErrors,
  SelectField,
} from "melco-shared-logic";
import { ActivateSupportFormValueType } from "./ActivateSupportForm";

export const DisplayActivateSupportForm: React.FC<
  FormikProps<ActivateSupportFormValueType>
> = ({ isSubmitting }) => {
  const { t } = useTranslation();

  const durationOptions = [
    {
      label: "1 day",
      value: 24 * 60,
    },
    {
      label: "3 days",
      value: 3 * 24 * 60,
    },
    {
      label: "1 week",
      value: 7 * 24 * 60,
    },
    {
      label: "1 month",
      value: 31 * 24 * 60,
    },
    {
      label: "1 year",
      value: 365 * 24 * 60,
    },
  ];

  return (
    <VerticalForm>
      <DisplayFormErrors />

      <FormSection title={t("accountsupportpage.access.name")}>
        <Paragraph>{t("accountsupportpage.access.description")}</Paragraph>

        <SelectField
          name="minutes"
          label={t("accountsupportpage.access.label.minutes")}
          placeholder={t("accountsupportpage.access.label.minutes")}
          options={durationOptions}
        />

        <FormRow justify="end">
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlayIcon />}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t("accountsupportpage.access.submit")}
          </Button>
        </FormRow>
      </FormSection>
    </VerticalForm>
  );
};
