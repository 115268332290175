import isEmpty from "lodash-es/isEmpty";
import {
  MelcoCoreModelsPermissionsListItem,
  SwitchField,
} from "melco-shared-logic";

type ManagePermissionProps = {
  productCode: string;
  permission: MelcoCoreModelsPermissionsListItem;
};

export const ManagePermission: React.FC<ManagePermissionProps> = ({
  productCode,
  permission,
}) => {
  const { name, code, parent_code } = permission;

  const isRootPermission = isEmpty(parent_code);

  return (
    <SwitchField
      name={`user_product_access.${productCode}.permissions.${
        code ?? ""
      }.active`}
      label={isRootPermission ? undefined : name ?? ""}
      data-cy-id={`permissions-product-permission-${productCode}-${code}`}
    />
  );
};
