import { useAPIAction, UserAccountApi } from "melco-shared-logic";
import { useQueryClient } from "react-query";

export const useCreateAPIKey = () => {
  const queryClient = useQueryClient();

  return useAPIAction(
    UserAccountApi,
    async (api, name: string, productCode: string, scopeId: string) => {
      const data = await api.accountApiKeysCreate({
        melcoCoreModelsCreateApiKey: {
          name,
          product_code: productCode,
          scope_id: scopeId,
        },
      });

      queryClient.invalidateQueries("apiKeys");

      return {
        successMessages: [],
        data,
      };
    },
    {
      translationPrefix: "api_list.actions.add",
      skipErrorHandling: true,
    }
  );
};
