import { Link, SecondaryNavigationMenuItem } from "melco-ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledHelpLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HelpLink: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SecondaryNavigationMenuItem>
      <StyledHelpLink
        href={t("help.link")}
        data-cy-id="help-link"
        target="_blank"
      >
        ?
      </StyledHelpLink>
    </SecondaryNavigationMenuItem>
  );
};
