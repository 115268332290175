import { useCurrentUser, useIsSubUser } from "melco-shared-logic";
import { DisplayAddAccountNameModal } from "./DisplayAddAccountNameModal";

const AddAccountNameModal: React.FC = () => {
  const currentUser = useCurrentUser();
  const isSubUser = useIsSubUser();

  //A SubUser cant update the account name
  if (isSubUser) {
    return null;
  }

  if (!currentUser || currentUser.accountName) {
    return null;
  }

  return <DisplayAddAccountNameModal currentUser={currentUser} />;
};

export default AddAccountNameModal;
