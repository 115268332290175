import React from "react";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

const SecondaryNavigationWrapper = styled.div`
  position: absolute;
  top: ${spacings.xlarge / 2}px;
  right: ${spacings.xlarge - 5}px;
  display: flex;
  align-items: center;

  > * {
    margin-left: 10px;
  }
`;

type SecondaryNavigationProps = {
  children?: React.ReactNode;
};

export const SecondaryNavigation: React.FC<SecondaryNavigationProps> = ({
  children,
}) => {
  return (
    <SecondaryNavigationWrapper data-cy="floating-navigation">
      {children}
    </SecondaryNavigationWrapper>
  );
};
