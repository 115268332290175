import AntdInput, {
  InputProps as AntdInputProps,
  InputRef as AntdInputRef,
} from "antd/es/input";
import styled from "styled-components";

const StyledInput = styled(AntdInput)`
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export type InputProps = AntdInputProps;
export type InputRef = AntdInputRef;

// FIXME: temporary workaround until type CompoundComponent is exported from Ant design
const Input: React.FC<AntdInputProps & React.RefAttributes<InputRef>> =
  StyledInput;

const InputGroup = AntdInput.Group;

export { Input, InputGroup };
