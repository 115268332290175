import {
  DisplayFormErrors,
  InputField,
  VerticalForm,
} from "melco-shared-logic";
import { Alert, FullWidthSpace } from "melco-ui";
import { useTranslation } from "react-i18next";

export const DisplayAddAccountNameForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <VerticalForm>
      <DisplayFormErrors />
      <FullWidthSpace direction="vertical" size="large">
        <Alert
          type="warning"
          withBackground
          message={t("dashboard.add_account_name.warning")}
        />
        <InputField
          name="account_name"
          label={t("dashboard.add_account_name.form.account_name.label")}
          placeholder={t(
            "dashboard.add_account_name.form.account_name.placeholder"
          )}
        />
      </FullWidthSpace>
    </VerticalForm>
  );
};
