import { Paragraph } from "melco-ui";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import castArray from "lodash-es/castArray";
import isString from "lodash-es/isString";
import isArray from "lodash-es/isArray";
import isObject from "lodash-es/isObject";

type DisplayFieldErrorsProps = {
  name: string;
  aggregateErrors?: boolean;
};

export const DisplayFieldErrors: React.FC<DisplayFieldErrorsProps> = ({
  name,
  aggregateErrors,
}) => {
  const { t } = useTranslation();
  const [, { error }] = useField(name);

  if (!error) {
    return null;
  }

  let errors: string[] = [];

  if (!isString(error) && !aggregateErrors) {
    // if the error is not a string, the field most likely contains
    // a field error object for errors of it's children. in this case we
    // don't want to show the error on the parent component and trust
    // that the errors will be presented on the children
    return null;
  } else if (aggregateErrors) {
    const handleError = (e: any) => {
      if (isString(e)) {
        errors.push(e);
      } else if (isArray(e)) {
        // e.g.
        // [{
        //   "value": "value is required for static field in filename rules list"
        // }]
        for (const thisError of e) {
          handleError(thisError);
        }
      } else if (isObject(e)) {
        // e.g.
        // {
        //   "value": "value is required for static field in filename rules list"
        // }
        errors.push(...Object.values(e));
      }
    };

    handleError(error);
  } else {
    errors = castArray(error);
  }

  return (
    <Paragraph type="danger">
      {errors.map((e: string) => (
        <>
          {t(e)}
          <br />
        </>
      ))}
    </Paragraph>
  );
};
