import { useFormikContext } from "formik";
import {
  DebugForm,
  DisplayFormErrors,
  InputField,
  useIsFormChanged,
  VerticalForm,
} from "melco-shared-logic";
import { Button, FullWidthSpace, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";

export const DisplayAddLicenseForm: React.FC = () => {
  const { t } = useTranslation();

  const { handleSubmit, isSubmitting, isValid } = useFormikContext();
  const isFormChanged = useIsFormChanged();

  return (
    <VerticalForm>
      <DisplayFormErrors />

      <FullWidthSpace direction="vertical" size="middle">
        <InputField
          name="serial_number"
          label={t(
            "infrastructureLicenses.actions.add.form.label.serial_number"
          )}
        />

        <Button
          key="add-license-button"
          type="primary"
          icon={<PlusIcon />}
          disabled={
            isSubmitting || !isFormChanged || (!isValid && !isFormChanged)
          }
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        >
          {t("infrastructureLicenses.actions.add.form.button")}
        </Button>
      </FullWidthSpace>

      {false && <DebugForm />}
    </VerticalForm>
  );
};
