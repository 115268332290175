import React from "react";
import AntdSpace, { SpaceProps as AntdSpaceProps } from "antd/es/space";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

export type SpaceProps = Omit<
  AntdSpaceProps,
  "size" | "style" | "className" | "prefixCls"
> & {
  size?: "xsmall" | "small" | "middle" | "large" | "xlarge";
};

const Space: React.FC<SpaceProps> = ({ size, ...rest }) => {
  return <AntdSpace size={spacings[size ? size : "xlarge"]} {...rest} />;
};

export { Space };
