import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import last from "lodash-es/last";

const selectedKeysForLocation = (locationPath: string) => {
  const activeKeys = locationPath ? [locationPath] : [];

  const selectedKeys = activeKeys.flatMap((key) =>
    key.split("/").reduce((previous: string[], current, index) => {
      // ignore first element (as all paths start with /)
      if (index === 0) {
        return [];
      }

      const lastPathElement = last(previous);
      const currentPath = `${lastPathElement ?? ""}/${current}`;

      return [...previous, currentPath];
    }, [])
  );

  return selectedKeys;
};

export const useMainMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    selectedKeysForLocation(location.pathname)
  );

  const onClick = useCallback(
    ({ key }: { key: React.Key }) => {
      navigate(key.toString());
    },
    [navigate]
  );

  useEffect(() => {
    // update active key when URL changes
    const selectedKeys = selectedKeysForLocation(location.pathname);

    setSelectedKeys(selectedKeys);
  }, [location.pathname]);

  return { onClick, selectedKeys };
};
