import { Formik } from "formik";
import {
  MelcoCoreModelsUsersServiceUser,
  useAuthenticatedAPIConfig,
  useFormSubmit,
  UserAccountApi,
} from "melco-shared-logic";
import { useQueryClient } from "react-query";
import { DisplayUserProfileForm } from "./DisplayUserProfileForm";

export type UserProfileFormValueType = {
  gender_code: string;
  first_name: string;
  last_name: string;
  company_name: string;
  phone: string;
  website: string;
  street1: string;
  city: string;
  postal_code: string;
  state_code: string;
  country_code: string;
};

export const userToInitialValues = (user: MelcoCoreModelsUsersServiceUser) => {
  const {
    gender_code,
    first_name,
    last_name,
    company_name,
    phone,
    website,
    address,
  } = user;
  const { street1, city, postal_code, state_code, country_code } =
    address ?? {};

  const initialValues: UserProfileFormValueType = {
    gender_code: gender_code ?? "",
    first_name: first_name ?? "",
    last_name: last_name ?? "",
    company_name: company_name ?? "",
    phone: phone ?? "",
    website: website ?? "",
    street1: street1 ?? "",
    city: city ?? "",
    postal_code: postal_code ?? "",
    state_code: state_code ?? "",
    country_code: country_code ?? "",
  };

  return initialValues;
};

type UserProfileProps = {
  user: MelcoCoreModelsUsersServiceUser;
};

export const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const queryClient = useQueryClient();

  const initialValues = userToInitialValues(user);

  const onSubmit = useFormSubmit(
    async (values: UserProfileFormValueType) => {
      const {
        gender_code,
        first_name,
        last_name,
        company_name,
        phone,
        website,
        street1,
        city,
        postal_code,
        state_code,
        country_code,
      } = values;

      const api = new UserAccountApi(authenticatedApiConfig());

      await api.userAccountEdit({
        id: user.id!,
        melcoCoreModelsUsersEditSubUser: {
          gender_code,
          first_name,
          last_name,
          company_name,
          phone,
          website,
          address: {
            street1,
            city,
            postal_code,
            state_code,
            country_code,
          },
        },
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
      };
    },
    {
      translationPrefix: "users.details.profile",
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      },
    }
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <DisplayUserProfileForm />
    </Formik>
  );
};
