import { useField } from "formik";
import { useIsAdminRoleId } from "./useIsAdminRoleId";

export const useIsAdminRole = () => {
  const [{ value: roleId }] = useField<string | undefined>("role.id");

  const isAdminRoleId = useIsAdminRoleId();

  return isAdminRoleId(roleId);
};
