import { useRequirePermissions } from "../../hooks/permissions/useRequirePermissions";

type RequireAuthProps = {
  permissions: string[];
  children?: React.ReactNode;
  otherwise?: React.ReactNode;
};

export const RequirePermissions: React.FC<RequireAuthProps> = ({
  permissions,
  children,
  otherwise,
}) => {
  const isAllowed = useRequirePermissions(permissions);

  if (isAllowed) {
    return <>{children}</>;
  }

  return otherwise ? <>{otherwise}</> : null;
};
