import { useDefaultsAPI } from "../api/useDefaultsAPI";
import { SystemDefaultsApi } from "../../api";

export type Gender = {
  name: string;
  code: string;
};

type GendersData = {
  genders: Gender[];
};

const initialValue = { genders: [] };

const handleRequest = async (api: SystemDefaultsApi) => {
  const response = await api.defaultsGetGenderList();

  const genders = (response.list ?? []).map(({ code, text }) => {
    return {
      name: text ?? "",
      code: code ?? "",
    };
  });

  return {
    genders,
  };
};

export const useGenders = () => {
  return useDefaultsAPI<GendersData>({
    initialValue,
    handleRequest,
  });
};
