import { forwardRef } from "react";
import styled from "styled-components";
import { Input } from "melco-ui";
import last from "lodash-es/last";
import { InputRef } from "antd";

const StyledInput = styled(Input)`
  text-align: center;
  padding: 0;
  font-size: 27px;
  width: 34px;
  height: 39px;

  @media screen and (min-width: 992px) {
    width: 45px;
    height: 52px;
    font-size: 36px;
  }
`;

type SingleDigitInputProps = {
  value?: string;
  onDigitEntered: (digit: string | undefined) => void;
  onPaste: (code: string) => void;
  // ref: RefObject<InputRef>;
  onBackspace: () => void;
};

const SingleDigitInput = forwardRef<InputRef, SingleDigitInputProps>(
  ({ value, onDigitEntered, onPaste, onBackspace }, ref) => {
    return (
      <StyledInput
        ref={ref}
        placeholder="0"
        value={value ?? ""}
        onPaste={(e) => {
          onPaste(e.clipboardData.getData("Text"));
          e.preventDefault();
        }}
        onKeyDown={(e) => {
          if (!value && e.key === "Backspace") {
            onBackspace();
          }
        }}
        onChange={(e) => {
          const lastChar = last(e.target.value.split(""));
          if (lastChar && /^[0-9]$/.test(lastChar)) {
            onDigitEntered(lastChar);
          } else {
            onDigitEntered(undefined);
          }
        }}
      />
    );
  }
);

export default SingleDigitInput;
