import { Button, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";

type AddUserButtonProps = {
  onClick: () => void;
};

export const AddUserButton: React.FC<AddUserButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button key="add-user" type="primary" icon={<PlusIcon />} onClick={onClick}>
      {t("users.actions.add.button")}
    </Button>
  );
};
