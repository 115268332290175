import { Button, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";

type CreateAPIKeyButtonProps = {
  setDrawerOpen: (isDrawerOpen: boolean) => void;
};

export const CreateAPIKeyButton: React.FC<CreateAPIKeyButtonProps> = ({
  setDrawerOpen,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      key="create-api-key"
      type="primary"
      icon={<PlusIcon />}
      onClick={() => {
        setDrawerOpen(true);
      }}
    >
      {t("api_list.actions.add.button")}
    </Button>
  );
};
