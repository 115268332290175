import { Spin } from "melco-ui";

type LoadingIndicatorProps = {
  isShown: boolean;
  children?: React.ReactNode;
};

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  isShown,
  children,
}) => {
  if (isShown) {
    return <Spin>{children}</Spin>;
  }

  return <>{children}</>;
};

export default LoadingIndicator;
