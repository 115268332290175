import {
  Drawer as DrawerType,
  MelcoCoreModelsDRMUserSerialNumber,
} from "melco-shared-logic";
import {
  Col,
  LabeledItem,
  Row,
  DesignTokens,
  Button,
  EditIcon,
  PlusIcon,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash-es/isEmpty";

const { spacings } = DesignTokens;

type AssignUserProps = {
  license: MelcoCoreModelsDRMUserSerialNumber;
  drawer: DrawerType;
};

export const AssignUser: React.FC<AssignUserProps> = ({ license, drawer }) => {
  const { t } = useTranslation();

  const hasAssignedUser =
    license.assigned_user_name && !isEmpty(license.assigned_user_name);
  const buttonIcon = hasAssignedUser ? <EditIcon /> : <PlusIcon />;
  const buttonTitle = hasAssignedUser
    ? ""
    : t("infrastructureLicenses.actions.assign.button");

  return (
    <LabeledItem
      label={t("infrastructureLicenses.form.label.assigned_user_name")}
      content={
        <Row gutter={[spacings.small, spacings.small]} justify="space-between">
          {hasAssignedUser && <Col>{license.assigned_user_name ?? ""}</Col>}
          <Col>
            <Button
              size="small"
              onClick={drawer.openDrawer}
              icon={buttonIcon}
              data-cy-id="license-assign-user"
            >
              {buttonTitle}
            </Button>
          </Col>
        </Row>
      }
      fallback={t("global.fallback.none")}
    />
  );
};
