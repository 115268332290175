import { MelcoCoreModelsPermissionsListItem } from "../../api";
import { useAuth } from "../auth/useAuth";

export type CurrentUser = {
  eMail: string;
  token: string;
  userId: string;
  firstName: string;
  lastName: string;
  accountName: string;
  parentUserId?: string;
  impersonationForId?: string;
  permissions?: MelcoCoreModelsPermissionsListItem[];
  defaultLanguage?: string;
};

export const useCurrentUser = () => {
  const auth = useAuth();
  return auth?.currentUser;
};
