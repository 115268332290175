import React from "react";
import styled from "styled-components";
import { DesignTokens } from "../../../styles/design-tokens";
import { Button, ButtonProps } from "../../button/Button";

const { spacings } = DesignTokens;

const StyledFlowBoxButton = styled(Button)`
  margin-top: ${spacings.middle}px;
`;

export type FlowBoxButtonProps = ButtonProps;

const FlowBoxButton: React.FC<FlowBoxButtonProps> = ({
  type = "primary",
  htmlType = "button",
  size = "large",
  ...rest
}) => (
  <StyledFlowBoxButton
    type={type}
    htmlType={htmlType}
    size={size}
    block
    isHero
    {...rest}
  />
);

export { FlowBoxButton };
