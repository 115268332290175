import React, { FocusEvent } from "react";
import AntdInput, { SearchProps as AntdSearchProps } from "antd/es/input";
import styled from "styled-components";

const StyledSearchInput = styled(AntdInput.Search)`
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface MelcoSearchInputProps {
  onSearchInputChange: (query: string) => void;
}

export type SearchInputProps = MelcoSearchInputProps & AntdSearchProps;

const SearchInput: React.FC<SearchInputProps> = ({
  onSearchInputChange,
  ...rest
}) => {
  return (
    <StyledSearchInput
      onSearch={(searchQuery: string) => {
        onSearchInputChange(searchQuery);
      }}
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        onSearchInputChange(event.target.value);
      }}
      allowClear
      {...rest}
    />
  );
};

export { SearchInput };
