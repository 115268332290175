import { Modal, Space } from "melco-ui";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import TrialModalInfoButton from "./TrialModalInfoButton";
import {
  isTrialExpired,
  isTrialProduct,
  MelcoCoreModelsUserProductListItem,
} from "melco-shared-logic";
import TrialActiveModalCancelButton from "./TrialActiveModalCancelButton";
import TrialActiveModalOkButton from "./TrialActiveModalOkButton";

dayjs.extend(relativeTime);

type TrialActiveModalProps = {
  product: MelcoCoreModelsUserProductListItem;
  open?: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const TrialActiveModal: React.FC<TrialActiveModalProps> = ({
  product,
  open,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation();

  const { code, expires_on, info_url } = product;

  if (!isTrialProduct(product)) {
    return null;
  }

  if (isTrialExpired(product)) {
    return null;
  }

  const wrapClassName = `modal-trial-active-${code}`;

  return (
    <Modal
      data-cy-id="modal-trial-active"
      title={t("trial.active.title")}
      open={open}
      centered
      closable={false}
      destroyOnClose
      wrapClassName={wrapClassName}
      footer={[
        <TrialActiveModalCancelButton key="cancel" onClick={onCancel} />,
        <TrialActiveModalOkButton key="ok" onClick={onOk} />,
      ]}
    >
      <Space direction="vertical" size="small">
        {t("trial.active.message", { fromNow: dayjs(expires_on).fromNow() })}
        {info_url && <TrialModalInfoButton info_url={info_url} />}
      </Space>
    </Modal>
  );
};

export default TrialActiveModal;
