import React from "react";
import styled, { css } from "styled-components";
import { DesignTokens } from "../styles/design-tokens";

const { spacings } = DesignTokens;

const MainContentAreaWrapper = styled.div<{
  hasNoHeader: boolean;
}>`
  flex-grow: 1;

  ${({ hasNoHeader }) =>
    hasNoHeader &&
    css`
      padding-top: 64px;
    `}
`;

const ContentWrapper = styled.div<{
  contentPadding?: boolean;
}>`
  && {
    padding: ${({ contentPadding }) =>
      contentPadding ? spacings.xlarge : 0}px;
  }
`;

type MainContentAreaProps = {
  children: React.ReactNode;
  header?: React.ReactNode;
  contentPadding?: boolean;
};

const MainContentArea: React.FC<MainContentAreaProps> = ({
  children,
  header,
  contentPadding,
}) => (
  <MainContentAreaWrapper hasNoHeader={!header}>
    <>
      {header}
      <ContentWrapper contentPadding={contentPadding}>
        {children}
      </ContentWrapper>
    </>
  </MainContentAreaWrapper>
);

export { MainContentArea };
