import { useAPIQuery, UserAccountApi } from "melco-shared-logic";

export const useLicenseActivationReminder = (licenseId: string | undefined) => {
  return useAPIQuery(
    UserAccountApi,
    ["licenseActivationReminder", licenseId],
    async (api) => {
      if (licenseId == null) {
        return;
      }

      return api.productLicensesGetReminder({ userSubscriptionId: licenseId });
    }
  );
};
