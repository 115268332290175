import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export const formatRelativeFromNow = (date?: Date | null) => {
  if (!date) {
    return;
  }

  const differenceInDays = dayjs().diff(date, "day");

  //If difference, either positive or negative, is bigger than 6, we return a formatted string
  if (Math.abs(differenceInDays) > 6) {
    return dayjs(date).format("L");
  }

  return dayjs(date).fromNow();
};
