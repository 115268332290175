import { useFormikContext } from "formik";
import {
  DebugForm,
  DisplayFormErrors,
  DrawerFooterContent,
  InputField,
  SwitchField,
  VerticalForm,
} from "melco-shared-logic";
import { Button, Col, DesignTokens, Row, SaveIcon, Title } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useIsEmail } from "../../hooks/administration/useIsEmail";
import { useSetCountryOfCurrentUser } from "../../hooks/administration/useSetCountryOfCurrentUser";
import CountrySelect from "../form/CountrySelect";
import { EmailFieldWithAvailabilityCheck } from "../register/EmailFieldWithAvailabilityCheck";
import { ShowUsernameAlert } from "./ShowUsernameAlert";

const { spacings } = DesignTokens;

export const DisplayAddUserForm: React.FC = () => {
  const { t } = useTranslation();

  const { handleSubmit, isSubmitting } = useFormikContext();

  const isEmail = useIsEmail();

  useSetCountryOfCurrentUser();

  return (
    <VerticalForm>
      <DisplayFormErrors />

      <Row gutter={[spacings.middle, 0]}>
        <Col xs={24} md={12}>
          <InputField
            name="first_name"
            label={t("users.actions.add.form.label.first_name")}
          />
        </Col>
        <Col xs={24} md={12}>
          <InputField
            name="last_name"
            label={t("users.actions.add.form.label.last_name")}
          />
        </Col>
      </Row>

      <CountrySelect
        name="country_code"
        label={t("users.actions.add.form.label.country_code")}
        disabled
      />

      <Title level={4}>{t("users.actions.add.form.title.login")}</Title>

      <SwitchField
        name="is_email"
        label={t("users.actions.add.form.label.is_email")}
      />

      <ShowUsernameAlert isEmail={isEmail} />

      {isEmail ? (
        <EmailFieldWithAvailabilityCheck
          name="user_name"
          label={t(`users.actions.add.form.label.email`)}
        />
      ) : (
        <InputField
          name="user_name"
          label={t(`users.actions.add.form.label.user_name`)}
        />
      )}

      <DrawerFooterContent>
        <Row justify="end">
          <Button
            key="add-user-button"
            type="primary"
            icon={<SaveIcon />}
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={() => handleSubmit()}
          >
            {t("users.actions.add.continue")}
          </Button>
        </Row>
      </DrawerFooterContent>

      {false && <DebugForm />}
    </VerticalForm>
  );
};
