import React from "react";
import AntdModal, { ModalProps as AntdModalProps } from "antd/es/modal";
import modalConfirm from "antd/es/modal/confirm";
import styled from "styled-components";

const StyledModal = styled(AntdModal)<ModalProps>`
  .ant-modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }
`;

export type ModalProps = AntdModalProps;

const Modal: React.FC<ModalProps> = (props) => <StyledModal {...props} />;

export { Modal, modalConfirm };
