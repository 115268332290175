import { useTranslation } from "react-i18next";
import { SelectField, useGenders } from "melco-shared-logic";

const GenderSelect: React.FC = () => {
  const { t } = useTranslation();
  const { genders, isLoading } = useGenders();

  // TODO: Make sure that the placeholder is shown

  return (
    <SelectField
      name="gender_code"
      label={t("global.form.label.profile.gender")}
      placeholder={t("global.form.label.profile.gender")}
      options={genders.map((g) => {
        return {
          label: g.name,
          value: g.code,
        };
      })}
      loading={isLoading}
      disabled={isLoading}
    />
  );
};

export default GenderSelect;
