import { Paragraph, Title } from "melco-ui";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash-es/isEmpty";
import { MelcoCoreModelsProductListItem } from "melco-shared-logic";
import styled from "styled-components";
import DemoProducts from "./DemoProducts";

const NoMarginTitle = styled(Title)`
  && {
    margin: 0;
  }
`;

type MoreAppsProps = {
  products: MelcoCoreModelsProductListItem[];
};

const MoreApps: React.FC<MoreAppsProps> = ({ products }) => {
  const { t } = useTranslation();

  if (isEmpty(products)) {
    return null;
  }

  return (
    <>
      <NoMarginTitle level={4}>{t("dashboard.more.title")}</NoMarginTitle>
      <Paragraph>{t("dashboard.more.text")}</Paragraph>
      <DemoProducts products={products} />
    </>
  );
};

export default MoreApps;
