import { useTranslation } from "react-i18next";

type BulkActionCountProps = {
  selectedCount: number;
};

export const BulkActionCount: React.FC<BulkActionCountProps> = ({
  selectedCount,
}) => {
  const { t } = useTranslation();

  if (selectedCount <= 0) {
    return null;
  }

  return (
    <>
      <strong>{selectedCount}</strong>{" "}
      {t("bulkActions.selectedRows", {
        ns: "melco-common",
        count: selectedCount,
      })}
    </>
  );
};
