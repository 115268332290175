import { Formik } from "formik";
import {
  useAuthenticatedAPIConfig,
  useFlowFormSubmit,
  UserAccountApi,
} from "melco-shared-logic";
import { message } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { object, ref, string } from "yup";
import { DisplayUpdatePasswordForm } from "./DisplayUpdatePasswordForm";

type UpdatePasswordFormValueType = {
  new_password: string;
  confirm_password: string;
};

const UpdatePasswordFormSchema = object().shape({
  new_password: string().required("global.error.required.default"),
  confirm_password: string()
    .oneOf([ref("new_password")], "global.error.match.password")
    .required("global.error.required.default"),
});

export const UpdatePasswordForm: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const initialValues: UpdatePasswordFormValueType = {
    new_password: "",
    confirm_password: "",
  };

  const onSubmit = useFlowFormSubmit(
    async (values: UpdatePasswordFormValueType) => {
      const { new_password } = values;

      const api = new UserAccountApi(authenticatedApiConfig());

      await api.accountServiceTermsAccept({
        productCode: process.env.REACT_APP_PRODUCT_CODE!,
      });

      const response = await api.userAccountForcePasswordChange({
        melcoCoreModelsUsersForcePasswordChange: {
          new_password,
        },
      });

      if (response.result) {
        const searchParams = new URLSearchParams(location.search);

        if (searchParams.has("redirectTo")) {
          navigate(searchParams.get("redirectTo")!);
        } else {
          navigate("/dashboard");
        }
      } else {
        throw new Error();
      }
    },
    {
      translationPrefix: "updatepasswordpage",
      onSuccess: () => {
        message.success(t("updatepasswordpage.success"));
      },
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UpdatePasswordFormSchema}
      onSubmit={onSubmit}
    >
      <DisplayUpdatePasswordForm />
    </Formik>
  );
};
