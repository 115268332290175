import { useTranslation } from "react-i18next";
import { FlowBoxButton, DesignTokens, FlowBox, Paragraph } from "melco-ui";
import { InputField, DisplayFormErrors } from "melco-shared-logic";
import { Form, FormikProps } from "formik";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LoginFormValueType } from "./LoginForm";
import LoginFormStatusMessage from "./LoginFormStatusMessage";

const { spacings } = DesignTokens;

const ForgotPasswordParagraph = styled(Paragraph)`
  & {
    margin-top: -${spacings.small}px;
    margin-bottom: ${spacings.xlarge}px;
  }
`;

export const DisplayLoginForm: React.FC<FormikProps<LoginFormValueType>> = ({
  isSubmitting,
}) => {
  const { t } = useTranslation();

  return (
    <Form>
      <LoginFormStatusMessage />

      <DisplayFormErrors />

      <FlowBox>
        <InputField
          name="user_name"
          size="large"
          placeholder={t("global.form.label.profile.email_or_username")}
          autoComplete="username"
        />
        <InputField
          name="password"
          size="large"
          placeholder={t("global.form.label.profile.password")}
          type="password"
          autoComplete="current-password"
        />

        <ForgotPasswordParagraph
          size="small"
          alignment="right"
          type="secondary"
        >
          {t("loginpage.forgotpassword.text")}{" "}
          <Link to="/forgotpassword">{t("loginpage.forgotpassword.link")}</Link>
        </ForgotPasswordParagraph>

        <FlowBoxButton
          loading={isSubmitting}
          disabled={isSubmitting}
          htmlType="submit"
        >
          {t("loginpage.form.submit")}
        </FlowBoxButton>
      </FlowBox>
    </Form>
  );
};
