import AntdSelect, {
  SelectProps as AntdSelectProps,
  SelectValue as AntdSelectValue,
} from "antd/es/select";

const Option = AntdSelect.Option;

type SelectProps<VT> = AntdSelectProps<VT>;
type SelectValue = AntdSelectValue;

export type { SelectProps, SelectValue };

export { AntdSelect as Select, Option };
