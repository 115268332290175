import React from "react";
import { Col } from "../grid/Col";
import { Row } from "../grid/Row";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

const GRID_COLS = 24;

export type PageContentWrapperProps = {
  narrow?: boolean;
  children?: React.ReactNode;
};

export const PageContentWrapper: React.FC<PageContentWrapperProps> = ({
  narrow,
  children,
}) => {
  return (
    <Row justify="center" gutter={[spacings.large, spacings.large]}>
      <Col
        xs={1 * GRID_COLS}
        sm={1 * GRID_COLS}
        md={1 * GRID_COLS}
        lg={1 * GRID_COLS}
        xl={narrow ? 0.5 * GRID_COLS : 0.75 * GRID_COLS}
      >
        {children}
      </Col>
    </Row>
  );
};
