import { Alert } from "melco-ui";
import { useTranslation } from "react-i18next";

export const SimpleAppPermissionsHint: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Alert
      type="info"
      withBackground
      showIcon
      description={t("users.details.permissions.hint.simplePermissionsApp")}
    />
  );
};
