/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoCalculatorModelsMachineSetting,
    MelcoCalculatorModelsMachineSettingFromJSON,
    MelcoCalculatorModelsMachineSettingFromJSONTyped,
    MelcoCalculatorModelsMachineSettingToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoCalculatorModelsMachineUpdateRequest
 */
export interface MelcoCalculatorModelsMachineUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MelcoCalculatorModelsMachineUpdateRequest
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsMachineUpdateRequest
     */
    multihead?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsMachineUpdateRequest
     */
    heads?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsMachineUpdateRequest
     */
    investment?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoCalculatorModelsMachineUpdateRequest
     */
    amortization_months?: number;
    /**
     * 
     * @type {MelcoCalculatorModelsMachineSetting}
     * @memberof MelcoCalculatorModelsMachineUpdateRequest
     */
    settings?: MelcoCalculatorModelsMachineSetting;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoCalculatorModelsMachineUpdateRequest
     */
    recalculate_all_jobs?: boolean;
}

export function MelcoCalculatorModelsMachineUpdateRequestFromJSON(json: any): MelcoCalculatorModelsMachineUpdateRequest {
    return MelcoCalculatorModelsMachineUpdateRequestFromJSONTyped(json, false);
}

export function MelcoCalculatorModelsMachineUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoCalculatorModelsMachineUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'multihead': !exists(json, 'multihead') ? undefined : json['multihead'],
        'heads': !exists(json, 'heads') ? undefined : json['heads'],
        'investment': !exists(json, 'investment') ? undefined : json['investment'],
        'amortization_months': !exists(json, 'amortization_months') ? undefined : json['amortization_months'],
        'settings': !exists(json, 'settings') ? undefined : MelcoCalculatorModelsMachineSettingFromJSON(json['settings']),
        'recalculate_all_jobs': !exists(json, 'recalculate_all_jobs') ? undefined : json['recalculate_all_jobs'],
    };
}

export function MelcoCalculatorModelsMachineUpdateRequestToJSON(value?: MelcoCalculatorModelsMachineUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'multihead': value.multihead,
        'heads': value.heads,
        'investment': value.investment,
        'amortization_months': value.amortization_months,
        'settings': MelcoCalculatorModelsMachineSettingToJSON(value.settings),
        'recalculate_all_jobs': value.recalculate_all_jobs,
    };
}


