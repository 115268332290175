//en locale does not to be imported
import "moment/locale/de";
import "moment/locale/it";
import "moment/locale/fr";
import "moment/locale/sv";
import "moment/locale/nl";
import "moment/locale/nn";
import "moment/locale/pt";
import "moment/locale/es";

import { useApplicationLanguage } from "./useApplicationLanguage";

import moment from "moment";
import { useEffect } from "react";
import { useUpdate } from "react-use";

export const useSetMomentLocale = () => {
  const language = useApplicationLanguage();
  const update = useUpdate();

  useEffect(() => {
    moment.locale(language);

    // Use callback of useUpdate to force a rerender.
    // moment is not react aware and will not rerender formatted dates
    update();
  }, [language, update]);
};
