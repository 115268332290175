import { MouseEventHandler, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, DesignTokens, message, Space } from "melco-ui";
import { MelcoCoreModelsUserProductListItem } from "melco-shared-logic";
import styled from "styled-components";
import isEmpty from "lodash-es/isEmpty";
import AppIconAndName from "./AppIconAndName";
import { useHoverDirty } from "react-use";
import { useRequestDemo } from "../../hooks/product/useRequestDemo";

const { colors, spacings } = DesignTokens;

const APP_ICON_WIDTH = 420;
const APP_ICON_BORDER_RADIUS = 25;

const AppIconWrapper = styled.div`
  display: inline-block;
  width: ${APP_ICON_WIDTH}px;
  margin: ${spacings.middle}px;
  background-color: ${colors.gray.light};
  border-radius: ${APP_ICON_BORDER_RADIUS}px;
`;

const AppIconContent = styled.div`
  padding: ${spacings.xlarge}px;
`;

const DescriptionAndButtonRow = styled(Space)`
  margin-top: ${spacings.small}px;
  width: 100%;
`;

const Description = styled.div`
  text-align: center;
`;

const ButtonRow = styled.div`
  text-align: center;
`;

type DemoProductProps = {
  product: MelcoCoreModelsUserProductListItem;
};

const DemoProduct: React.FC<DemoProductProps> = ({ product }) => {
  const { t } = useTranslation();
  const [requestDemo, isRequesting] = useRequestDemo(product);

  const containerRef = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(containerRef);

  const onClick: MouseEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    try {
      await requestDemo();
      message.success(t("dashboard.request_demo.success"));
    } catch (e) {}
  };
  const { code, description, info_url, is_demo_available } = product;

  const isRequestDemoPossible = is_demo_available;

  return (
    <AppIconWrapper data-cy={`product-${code}`} ref={containerRef}>
      <AppIconContent>
        <AppIconAndName product={product} />
        <DescriptionAndButtonRow direction="vertical" size="large">
          <Description>{description}</Description>

          <ButtonRow>
            <Space size="middle">
              {!isEmpty(info_url) && (
                <Button href={info_url!} target="_blank">
                  {t("dashboard.apps.information")}
                </Button>
              )}

              {isRequestDemoPossible && (
                <Button
                  type={isHovering ? "primary" : undefined}
                  onClick={onClick}
                  loading={isRequesting}
                >
                  {t("dashboard.apps.request_demo")}
                </Button>
              )}
            </Space>
          </ButtonRow>
        </DescriptionAndButtonRow>
      </AppIconContent>
    </AppIconWrapper>
  );
};

export default DemoProduct;
