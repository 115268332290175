/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoCalculatorModelsCalculationDetail,
    MelcoCalculatorModelsCalculationDetailFromJSON,
    MelcoCalculatorModelsCalculationDetailToJSON,
    MelcoCalculatorModelsChangeActiveMachine,
    MelcoCalculatorModelsChangeActiveMachineFromJSON,
    MelcoCalculatorModelsChangeActiveMachineToJSON,
    MelcoCalculatorModelsJob,
    MelcoCalculatorModelsJobFromJSON,
    MelcoCalculatorModelsJobToJSON,
    MelcoCalculatorModelsJobDefault,
    MelcoCalculatorModelsJobDefaultFromJSON,
    MelcoCalculatorModelsJobDefaultToJSON,
    MelcoCalculatorModelsJobMachineSetting,
    MelcoCalculatorModelsJobMachineSettingFromJSON,
    MelcoCalculatorModelsJobMachineSettingToJSON,
    MelcoCalculatorModelsJobRequest,
    MelcoCalculatorModelsJobRequestFromJSON,
    MelcoCalculatorModelsJobRequestToJSON,
    MelcoCalculatorModelsJobResponse,
    MelcoCalculatorModelsJobResponseFromJSON,
    MelcoCalculatorModelsJobResponseToJSON,
    MelcoCalculatorModelsMachineRequest,
    MelcoCalculatorModelsMachineRequestFromJSON,
    MelcoCalculatorModelsMachineRequestToJSON,
    MelcoCalculatorModelsMachineResponse,
    MelcoCalculatorModelsMachineResponseFromJSON,
    MelcoCalculatorModelsMachineResponseToJSON,
    MelcoCalculatorModelsMachineSetting,
    MelcoCalculatorModelsMachineSettingFromJSON,
    MelcoCalculatorModelsMachineSettingToJSON,
    MelcoCalculatorModelsMachineUpdateRequest,
    MelcoCalculatorModelsMachineUpdateRequestFromJSON,
    MelcoCalculatorModelsMachineUpdateRequestToJSON,
    MelcoCalculatorModelsSettingRequest,
    MelcoCalculatorModelsSettingRequestFromJSON,
    MelcoCalculatorModelsSettingRequestToJSON,
    MelcoCalculatorModelsSettingResponse,
    MelcoCalculatorModelsSettingResponseFromJSON,
    MelcoCalculatorModelsSettingResponseToJSON,
    MelcoCoreModelsBoolResponse,
    MelcoCoreModelsBoolResponseFromJSON,
    MelcoCoreModelsBoolResponseToJSON,
    MelcoCoreModelsError,
    MelcoCoreModelsErrorFromJSON,
    MelcoCoreModelsErrorToJSON,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsCalculation,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsCalculationFromJSON,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsCalculationToJSON,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsJobListItem,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsJobListItemFromJSON,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsJobListItemToJSON,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineNotification,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineNotificationFromJSON,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineNotificationToJSON,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineResponse,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineResponseFromJSON,
    MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineResponseToJSON,
} from '../models';

export interface ProductionCalculatorApiJobsApplyGlobalSettingsRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsChangeActiveRequest {
    jobId: string | null;
    melcoCalculatorModelsChangeActiveMachine?: MelcoCalculatorModelsChangeActiveMachine;
}

export interface ProductionCalculatorApiJobsCreateRequest {
    melcoCalculatorModelsJobRequest?: MelcoCalculatorModelsJobRequest;
}

export interface ProductionCalculatorApiJobsDeleteRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsDuplicateRequest {
    name?: string | null;
    jobId?: string | null;
}

export interface ProductionCalculatorApiJobsEditRequest {
    jobId: string | null;
    melcoCalculatorModelsJobRequest?: MelcoCalculatorModelsJobRequest;
}

export interface ProductionCalculatorApiJobsGetActiveMachineRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsGetCalculationDetailsRequest {
    jobId: string | null;
    calculationId: string | null;
}

export interface ProductionCalculatorApiJobsGetCalculationListRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsGetJobRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsGetJobMachineSettingsRequest {
    jobId: string | null;
}

export interface ProductionCalculatorApiJobsGetListRequest {
    searchText?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    offset?: number;
}

export interface ProductionCalculatorApiJobsJobExportRequest {
    jobId: string | null;
    culture?: string | null;
}

export interface ProductionCalculatorApiJobsUpdateDefaultsRequest {
    melcoCalculatorModelsJobDefault?: MelcoCalculatorModelsJobDefault;
}

export interface ProductionCalculatorApiJobsUpdateMachineSettingsRequest {
    jobId: string | null;
    melcoCalculatorModelsJobMachineSetting?: MelcoCalculatorModelsJobMachineSetting;
}

export interface ProductionCalculatorApiMachinesCreateRequest {
    melcoCalculatorModelsMachineRequest?: MelcoCalculatorModelsMachineRequest;
}

export interface ProductionCalculatorApiMachinesDeleteRequest {
    machineId: string | null;
}

export interface ProductionCalculatorApiMachinesDuplicateRequest {
    name?: string | null;
    machineId?: string | null;
}

export interface ProductionCalculatorApiMachinesGetRequest {
    machineId: string | null;
}

export interface ProductionCalculatorApiMachinesUpdateRequest {
    machineId: string | null;
    melcoCalculatorModelsMachineUpdateRequest?: MelcoCalculatorModelsMachineUpdateRequest;
}

export interface ProductionCalculatorApiSettingsUpdateRequest {
    melcoCalculatorModelsSettingRequest?: MelcoCalculatorModelsSettingRequest;
}

/**
 * 
 */
export class ProductionCalculatorApi extends runtime.BaseAPI {

    /**
     * apply default settings to job
     * apply default settings to job
     */
    async jobsApplyGlobalSettingsRaw(requestParameters: ProductionCalculatorApiJobsApplyGlobalSettingsRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsApplyGlobalSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/applyglobalsettings`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * apply default settings to job
     * apply default settings to job
     */
    async jobsApplyGlobalSettings(requestParameters: ProductionCalculatorApiJobsApplyGlobalSettingsRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.jobsApplyGlobalSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * change used machine
     * change used machine
     */
    async jobsChangeActiveRaw(requestParameters: ProductionCalculatorApiJobsChangeActiveRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsJob>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsChangeActive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/machine/changeactive`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCalculatorModelsChangeActiveMachineToJSON(requestParameters.melcoCalculatorModelsChangeActiveMachine),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsJobFromJSON(jsonValue));
    }

    /**
     * change used machine
     * change used machine
     */
    async jobsChangeActive(requestParameters: ProductionCalculatorApiJobsChangeActiveRequest): Promise<MelcoCalculatorModelsJob> {
        const response = await this.jobsChangeActiveRaw(requestParameters);
        return await response.value();
    }

    /**
     * create new job
     * create new job
     */
    async jobsCreateRaw(requestParameters: ProductionCalculatorApiJobsCreateRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsJobResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/jobs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCalculatorModelsJobRequestToJSON(requestParameters.melcoCalculatorModelsJobRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsJobResponseFromJSON(jsonValue));
    }

    /**
     * create new job
     * create new job
     */
    async jobsCreate(requestParameters: ProductionCalculatorApiJobsCreateRequest): Promise<MelcoCalculatorModelsJobResponse> {
        const response = await this.jobsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete job
     */
    async jobsDeleteRaw(requestParameters: ProductionCalculatorApiJobsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete job
     */
    async jobsDelete(requestParameters: ProductionCalculatorApiJobsDeleteRequest): Promise<void> {
        await this.jobsDeleteRaw(requestParameters);
    }

    /**
     * check for duplicate job existence
     * job duplicate check
     */
    async jobsDuplicateRaw(requestParameters: ProductionCalculatorApiJobsDuplicateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.jobId !== undefined) {
            queryParameters['jobId'] = requestParameters.jobId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/duplicate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * check for duplicate job existence
     * job duplicate check
     */
    async jobsDuplicate(requestParameters: ProductionCalculatorApiJobsDuplicateRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.jobsDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing job
     * update existing job
     */
    async jobsEditRaw(requestParameters: ProductionCalculatorApiJobsEditRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsJobResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCalculatorModelsJobRequestToJSON(requestParameters.melcoCalculatorModelsJobRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsJobResponseFromJSON(jsonValue));
    }

    /**
     * update existing job
     * update existing job
     */
    async jobsEdit(requestParameters: ProductionCalculatorApiJobsEditRequest): Promise<MelcoCalculatorModelsJobResponse> {
        const response = await this.jobsEditRaw(requestParameters);
        return await response.value();
    }

    /**
     * get active machine
     * get active machine
     */
    async jobsGetActiveMachineRaw(requestParameters: ProductionCalculatorApiJobsGetActiveMachineRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsMachineResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsGetActiveMachine.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/machine`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsMachineResponseFromJSON(jsonValue));
    }

    /**
     * get active machine
     * get active machine
     */
    async jobsGetActiveMachine(requestParameters: ProductionCalculatorApiJobsGetActiveMachineRequest): Promise<MelcoCalculatorModelsMachineResponse> {
        const response = await this.jobsGetActiveMachineRaw(requestParameters);
        return await response.value();
    }

    /**
     * get calculation details
     * get job calculation details
     */
    async jobsGetCalculationDetailsRaw(requestParameters: ProductionCalculatorApiJobsGetCalculationDetailsRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsCalculationDetail>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsGetCalculationDetails.');
        }

        if (requestParameters.calculationId === null || requestParameters.calculationId === undefined) {
            throw new runtime.RequiredError('calculationId','Required parameter requestParameters.calculationId was null or undefined when calling jobsGetCalculationDetails.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/calculations/{calculationId}/details`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))).replace(`{${"calculationId"}}`, encodeURIComponent(String(requestParameters.calculationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsCalculationDetailFromJSON(jsonValue));
    }

    /**
     * get calculation details
     * get job calculation details
     */
    async jobsGetCalculationDetails(requestParameters: ProductionCalculatorApiJobsGetCalculationDetailsRequest): Promise<MelcoCalculatorModelsCalculationDetail> {
        const response = await this.jobsGetCalculationDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get job calculation list
     * get job calculation list
     */
    async jobsGetCalculationListRaw(requestParameters: ProductionCalculatorApiJobsGetCalculationListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCalculatorModelsCalculation>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsGetCalculationList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/calculations`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCalculatorModelsCalculationFromJSON(jsonValue));
    }

    /**
     * get job calculation list
     * get job calculation list
     */
    async jobsGetCalculationList(requestParameters: ProductionCalculatorApiJobsGetCalculationListRequest): Promise<MelcoCoreModelsGenericList1MelcoCalculatorModelsCalculation> {
        const response = await this.jobsGetCalculationListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get job details
     * get job
     */
    async jobsGetJobRaw(requestParameters: ProductionCalculatorApiJobsGetJobRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsJobResponse>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsGetJob.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsJobResponseFromJSON(jsonValue));
    }

    /**
     * get job details
     * get job
     */
    async jobsGetJob(requestParameters: ProductionCalculatorApiJobsGetJobRequest): Promise<MelcoCalculatorModelsJobResponse> {
        const response = await this.jobsGetJobRaw(requestParameters);
        return await response.value();
    }

    /**
     * get job defaults
     * get job defaults
     */
    async jobsGetJobDefaultsRaw(): Promise<runtime.ApiResponse<MelcoCalculatorModelsJobDefault>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/defaults`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsJobDefaultFromJSON(jsonValue));
    }

    /**
     * get job defaults
     * get job defaults
     */
    async jobsGetJobDefaults(): Promise<MelcoCalculatorModelsJobDefault> {
        const response = await this.jobsGetJobDefaultsRaw();
        return await response.value();
    }

    /**
     * get used/active machine
     * get used/active machine settings
     */
    async jobsGetJobMachineSettingsRaw(requestParameters: ProductionCalculatorApiJobsGetJobMachineSettingsRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsMachineSetting>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsGetJobMachineSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/machine/settings`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsMachineSettingFromJSON(jsonValue));
    }

    /**
     * get used/active machine
     * get used/active machine settings
     */
    async jobsGetJobMachineSettings(requestParameters: ProductionCalculatorApiJobsGetJobMachineSettingsRequest): Promise<MelcoCalculatorModelsMachineSetting> {
        const response = await this.jobsGetJobMachineSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of jobs
     * get jobs list
     */
    async jobsGetListRaw(requestParameters: ProductionCalculatorApiJobsGetListRequest): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCalculatorModelsJobListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['searchText'] = requestParameters.searchText;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCalculatorModelsJobListItemFromJSON(jsonValue));
    }

    /**
     * get list of jobs
     * get jobs list
     */
    async jobsGetList(requestParameters: ProductionCalculatorApiJobsGetListRequest): Promise<MelcoCoreModelsGenericList1MelcoCalculatorModelsJobListItem> {
        const response = await this.jobsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * export csv/excel
     * export job
     */
    async jobsJobExportRaw(requestParameters: ProductionCalculatorApiJobsJobExportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsJobExport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.culture !== undefined) {
            queryParameters['culture'] = requestParameters.culture;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/export`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * export csv/excel
     * export job
     */
    async jobsJobExport(requestParameters: ProductionCalculatorApiJobsJobExportRequest): Promise<void> {
        await this.jobsJobExportRaw(requestParameters);
    }

    /**
     * update job defaults
     * update job defaults
     */
    async jobsUpdateDefaultsRaw(requestParameters: ProductionCalculatorApiJobsUpdateDefaultsRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsJobDefault>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/jobs/defaults`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCalculatorModelsJobDefaultToJSON(requestParameters.melcoCalculatorModelsJobDefault),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsJobDefaultFromJSON(jsonValue));
    }

    /**
     * update job defaults
     * update job defaults
     */
    async jobsUpdateDefaults(requestParameters: ProductionCalculatorApiJobsUpdateDefaultsRequest): Promise<MelcoCalculatorModelsJobDefault> {
        const response = await this.jobsUpdateDefaultsRaw(requestParameters);
        return await response.value();
    }

    /**
     * update machine(s) settings for this job
     * update machine(s) settings for this job
     */
    async jobsUpdateMachineSettingsRaw(requestParameters: ProductionCalculatorApiJobsUpdateMachineSettingsRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsMachineSetting>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling jobsUpdateMachineSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/jobs/{jobId}/machine/settings`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCalculatorModelsJobMachineSettingToJSON(requestParameters.melcoCalculatorModelsJobMachineSetting),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsMachineSettingFromJSON(jsonValue));
    }

    /**
     * update machine(s) settings for this job
     * update machine(s) settings for this job
     */
    async jobsUpdateMachineSettings(requestParameters: ProductionCalculatorApiJobsUpdateMachineSettingsRequest): Promise<MelcoCalculatorModelsMachineSetting> {
        const response = await this.jobsUpdateMachineSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * create new machine
     * create new machine
     */
    async machinesCreateRaw(requestParameters: ProductionCalculatorApiMachinesCreateRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsMachineResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/machines`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCalculatorModelsMachineRequestToJSON(requestParameters.melcoCalculatorModelsMachineRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsMachineResponseFromJSON(jsonValue));
    }

    /**
     * create new machine
     * create new machine
     */
    async machinesCreate(requestParameters: ProductionCalculatorApiMachinesCreateRequest): Promise<MelcoCalculatorModelsMachineResponse> {
        const response = await this.machinesCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete machine
     * delete machine
     */
    async machinesDeleteRaw(requestParameters: ProductionCalculatorApiMachinesDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete machine
     * delete machine
     */
    async machinesDelete(requestParameters: ProductionCalculatorApiMachinesDeleteRequest): Promise<void> {
        await this.machinesDeleteRaw(requestParameters);
    }

    /**
     * check for duplicate machine existence
     * machine duplicate check
     */
    async machinesDuplicateRaw(requestParameters: ProductionCalculatorApiMachinesDuplicateRequest): Promise<runtime.ApiResponse<MelcoCoreModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/machines/duplicate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * check for duplicate machine existence
     * machine duplicate check
     */
    async machinesDuplicate(requestParameters: ProductionCalculatorApiMachinesDuplicateRequest): Promise<MelcoCoreModelsBoolResponse> {
        const response = await this.machinesDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get machine object
     * get machine by machineId
     */
    async machinesGetRaw(requestParameters: ProductionCalculatorApiMachinesGetRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsMachineResponse>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsMachineResponseFromJSON(jsonValue));
    }

    /**
     * get machine object
     * get machine by machineId
     */
    async machinesGet(requestParameters: ProductionCalculatorApiMachinesGetRequest): Promise<MelcoCalculatorModelsMachineResponse> {
        const response = await this.machinesGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * get machines list
     * get machines list
     */
    async machinesGetListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/machines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineResponseFromJSON(jsonValue));
    }

    /**
     * get machines list
     * get machines list
     */
    async machinesGetList(): Promise<MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineResponse> {
        const response = await this.machinesGetListRaw();
        return await response.value();
    }

    /**
     * get list of default machine prices to auto suggest user
     * get default machine settings list
     */
    async machinesGetMachineListRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/defaults/machines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineResponseFromJSON(jsonValue));
    }

    /**
     * get list of default machine prices to auto suggest user
     * get default machine settings list
     */
    async machinesGetMachineList(): Promise<MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineResponse> {
        const response = await this.machinesGetMachineListRaw();
        return await response.value();
    }

    /**
     * get notification list
     * get notification list
     */
    async machinesGetNotificationsRaw(): Promise<runtime.ApiResponse<MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineNotification>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/machines/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineNotificationFromJSON(jsonValue));
    }

    /**
     * get notification list
     * get notification list
     */
    async machinesGetNotifications(): Promise<MelcoCoreModelsGenericList1MelcoCalculatorModelsMachineNotification> {
        const response = await this.machinesGetNotificationsRaw();
        return await response.value();
    }

    /**
     * update machine
     * update machine
     */
    async machinesUpdateRaw(requestParameters: ProductionCalculatorApiMachinesUpdateRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsMachineResponse>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machinesUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCalculatorModelsMachineUpdateRequestToJSON(requestParameters.melcoCalculatorModelsMachineUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsMachineResponseFromJSON(jsonValue));
    }

    /**
     * update machine
     * update machine
     */
    async machinesUpdate(requestParameters: ProductionCalculatorApiMachinesUpdateRequest): Promise<MelcoCalculatorModelsMachineResponse> {
        const response = await this.machinesUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get production calculator account settings
     * get account settings
     */
    async productionCalculatorSettingsGetRaw(): Promise<runtime.ApiResponse<MelcoCalculatorModelsSettingResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/calculator/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsSettingResponseFromJSON(jsonValue));
    }

    /**
     * get production calculator account settings
     * get account settings
     */
    async productionCalculatorSettingsGet(): Promise<MelcoCalculatorModelsSettingResponse> {
        const response = await this.productionCalculatorSettingsGetRaw();
        return await response.value();
    }

    /**
     * update user account production calculator settings
     * update user settings
     */
    async settingsUpdateRaw(requestParameters: ProductionCalculatorApiSettingsUpdateRequest): Promise<runtime.ApiResponse<MelcoCalculatorModelsSettingResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/calculator/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoCalculatorModelsSettingRequestToJSON(requestParameters.melcoCalculatorModelsSettingRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoCalculatorModelsSettingResponseFromJSON(jsonValue));
    }

    /**
     * update user account production calculator settings
     * update user settings
     */
    async settingsUpdate(requestParameters: ProductionCalculatorApiSettingsUpdateRequest): Promise<MelcoCalculatorModelsSettingResponse> {
        const response = await this.settingsUpdateRaw(requestParameters);
        return await response.value();
    }

}
