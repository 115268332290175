import { Formik } from "formik";
import * as Yup from "yup";
import { DisplayProfileForm } from "./DisplayProfileForm";
import {
  useUserDetails,
  useFormSubmit,
  UserDetails,
  UserManagementApi,
  useAuth,
  useCurrentUser,
  MelcoCoreModelsUsersUser,
  useIsSubUser,
  UserAccountApi,
  useAuthenticatedAPIConfig,
} from "melco-shared-logic";

export type ProfileFormValueType = UserDetails;

// Client side validation is unfortunately not defined...
const ProfileFormSchema = Yup.object().shape({});

export const transformProfileFormValues = (
  values: ProfileFormValueType
): MelcoCoreModelsUsersUser => {
  const {
    gender_code,
    first_name,
    last_name,
    company_name,
    phone,
    website,
    street1,
    postal_code,
    city,
    country_code,
    state_code,
    account_name,
  } = values;
  return {
    account_name,
    gender_code,
    first_name,
    last_name,
    company_name,
    phone,
    website,
    address: {
      street1,
      postal_code,
      city,
      country_code,
      state_code,
    },
  };
};

export const ProfileForm: React.FC = () => {
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const { user, isLoading } = useUserDetails();
  const auth = useAuth();
  const currentUser = useCurrentUser();
  const isSubUser = useIsSubUser();

  const onSubmit = useFormSubmit(
    async (values: ProfileFormValueType) => {
      const editValues = transformProfileFormValues(values);

      if (isSubUser) {
        const api = new UserAccountApi(authenticatedApiConfig());

        await api.userAccountEdit({
          id: user!.user_id,
          melcoCoreModelsUsersEditSubUser: editValues,
        });
      } else {
        const api = new UserManagementApi(authenticatedApiConfig());

        await api.userEdit({
          id: user!.user_id,
          melcoCoreModelsUsersUser: editValues,
        });
      }

      return {
        successMessages: [
          {
            message: "saved",
          },
        ],
      };
    },
    {
      translationPrefix: "accountprofilepage",
      onSuccess: (values) => {
        if (currentUser) {
          auth.changeCurrentUser({
            ...currentUser,
            firstName: values.first_name,
            lastName: values.last_name,
            accountName: values.account_name,
          });
        }
      },
    }
  );

  if (isLoading || !user) {
    return null;
  }

  const initialValues = user;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ProfileFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => <DisplayProfileForm {...props} />}
    </Formik>
  );
};
