import React from "react";
import styled from "styled-components";
import { DesignTokens } from "../../../styles/design-tokens";
import { Title, TitleProps } from "../../typography/Title";

const { fonts, sizes, spacings } = DesignTokens;

const StyledPageTitle = styled(Title)<TitleProps>`
  && {
    font-size: ${fonts.sizes.headings.xxlarge}px;
    font-family: ${fonts.sansSerifLt};
    font-weight: 300;
    line-height: 58px;
    margin-top: ${spacings.middle}px;
    margin-bottom: ${spacings.xlarge}px;

    /* Visual alignment of font with box */
    margin-left: -2px;

    @media screen and (min-width: ${sizes.lg}px) {
      font-size: ${fonts.sizes.headings.xxxxlarge}px;
    }
  }
`;

export type PageTitleProps = Omit<TitleProps, "level">;

const FlowPageTitle: React.FC<PageTitleProps> = (props) => (
  <StyledPageTitle {...props} />
);

export { FlowPageTitle };
