import { Space, Alert, FullWidthSpace, CopyableTextWithLabel } from "melco-ui";
import { useTranslation } from "react-i18next";
import { PasswordResetData } from "../../hooks/administration/useResetPassword";

type PasswordResetLoginDataProps = {
  passwordResetData: PasswordResetData | undefined;
};

export const PasswordResetLoginData: React.FC<PasswordResetLoginDataProps> = ({
  passwordResetData,
}) => {
  const { t } = useTranslation();

  if (!passwordResetData) {
    return null;
  }

  return (
    <FullWidthSpace direction="vertical" size="large">
      <Alert
        type="info"
        withBackground
        message={t("users.details.login.username.resetPassword.message")}
      />

      <Space direction="vertical" size="middle">
        <CopyableTextWithLabel
          label={t("users.details.login.username.labels.username")}
          text={passwordResetData.user_name}
          onCopiedMessage={t(
            "users.details.login.username.copyUsername.success"
          )}
          copyTooltip={t(
            "users.details.login.username.copyUsername.tooltip_copy"
          )}
          copiedTooltip={t(
            "users.details.login.username.copyUsername.tooltip_copied"
          )}
        />

        <CopyableTextWithLabel
          label={t("users.details.login.username.labels.password")}
          text={passwordResetData.new_password}
          onCopiedMessage={t(
            "users.details.login.username.copyPassword.success"
          )}
          copyTooltip={t(
            "users.details.login.username.copyPassword.tooltip_copy"
          )}
          copiedTooltip={t(
            "users.details.login.username.copyPassword.tooltip_copied"
          )}
        />
      </Space>
    </FullWidthSpace>
  );
};
