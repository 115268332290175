import { Button, MailIcon, Menu, MoreIcon } from "melco-ui";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { Drawer as DrawerType } from "melco-shared-logic";

type LicenseMoreActionButtonProps = {
  activationReminderDrawer: DrawerType;
};

export const LicenseMoreActionButton: React.FC<
  LicenseMoreActionButtonProps
> = ({ activationReminderDrawer }) => {
  const { t } = useTranslation();

  const MoreActions = (
    <Menu>
      <Menu.Item
        key="more-actions-delete"
        onClick={activationReminderDrawer.openDrawer}
        icon={<MailIcon />}
      >
        {t("infrastructureLicenses.actions.activationReminder.button")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={MoreActions}
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      <Button
        key="license-more-actions"
        icon={<MoreIcon />}
        data-cy-id="license-more-actions"
      />
    </Dropdown>
  );
};
